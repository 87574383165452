<template>
  <section class="navbar d-md-none sticky-top fullscreen mb-2 mb-md-0">
    <div class="h-100 w-100 px-offset py-3 d-flex bg-white z-200">
      <button @click.stop="openMenu" ref="openMenu" class="col-2 text-left btn px-0 py-1 m-0">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </button>
      <router-link class="col-8 h-100" :to="{ name: 'home' }">
        <img class="logo h-100 w-100" src="/assets/images/logos/sepri_group.svg" />
      </router-link>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      openMenu: 'settings/openMenu',
    })
  },
  computed: {
    isLoggedIn() {
      return this.$auth.ready() && this.$auth.check()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@bootstrap-functions";
@import "~@bootstrap-variables";
@import "~@bootstrap-mixins";
@import "~@vars";

.navbar {
  height: 4.1875rem;
  background: $white;
  z-index: 100;
}

.logo {
  object-fit: contain;
}
</style>