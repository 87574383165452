<template>
  <div>
    <b-form v-if="!loading">
      <b-row>
        <b-col cols="12" sm="12">
          <b-card>
            <b-card-header>
              Pedido de Contacto
            </b-card-header>
            <b-card-body>
              <b-tabs no-nav-style nav-class="border-right border-light h-100" nav-wrapper-class="p-0" class="mx-0"
                active-nav-item-class="font-weight-bolder border-right text-secondary border-secondary"
                content-class="py-5 w-100 bg-white" vertical>
                <div class="w-100">

                  <b-form-group label="Nome*" :description="veeErrors.first('name')">
                    <b-input 
                      class="form-control"
                      v-model="item.name" 
                      v-validate="validations.name" 
                      :data-vv-as="$t('contacts.form.name')"
                      name="name" 
                      type="text" 
                      required
                      :placeholder="`*${$t('contacts.form.name')}`" />
                  </b-form-group>

                  <b-form-group label="Email*" :description="veeErrors.first('email')">
                    <b-input 
                      class="form-control"
                      v-model="item.email" 
                      v-validate="validations.email" 
                      name="email" 
                      type="email" 
                      :data-vv-as="$t('contacts.form.email')"
                      required
                      :placeholder="`*${$t('contacts.form.email')}`" />
                  </b-form-group>

                  <b-form-group label="Assunto*" :description="veeErrors.first('subject')">
                    <b-input 
                      class="form-control"
                      v-model="item.subject" 
                      v-validate="validations.subject" 
                      :data-vv-as="$t('contacts.form.subject')"
                      name="subject" 
                      type="text" 
                      required
                      :placeholder="`*${$t('contacts.form.subject')}`" />
                  </b-form-group>

                  <b-form-group label="Mensagem*" :description="veeErrors.first('message')">
                    <b-form-textarea 
                      name="message"
                      type="text"
                      class="form-control"
                      v-model="item.message" 
                      v-validate="validations.message" 
                      :data-vv-as="$t('contacts.form.message')" 
                      :placeholder="`*${$t('contacts.form.message')}`"
                      no-resize
                      rows="4"
                      required >
                    </b-form-textarea>
                  </b-form-group>

                  <p class="fs-10 text-grey-1 text-right">*{{ $t('contacts.form.required') }}</p>
                  
                  <b-form-group label="Anexo">
                    <div v-if="hasMedia" class="w-80 mb-3">
                      <a :href="item.file" target="_blank">Download</a>
                      <img class="img-thumbnail" :src="item.file">
                    </div>
                    <image-uploader 
                      class="btn-choose-file"
                      :maxWidth="800" 
                      :quality="1" 
                      :autoRotate=true 
                      outputFormat="blob" 
                      :preview=true
                      capture="environment" 
                      accept="image/x-png, image/gif, image/jpeg, application/pdf" 
                      @input="setImage" />
                  </b-form-group>

                  <b-form-group :description="veeErrors.first('communications')">
                    <input 
                      type="checkbox" 
                      class="fs-12 text-grey-2"
                      name="agree" 
                      v-model="item.communications"
                      v-validate="validations.communications" 
                      true-value="1" 
                      false-value="0"
                      :required="true" />
                    <label 
                      for="checkbox" 
                      class="fs-14 text-grey-2">Aceita receber comunicações do Grupo Sepri
                    </label>
                  </b-form-group>
                  <p v-if="veeErrors.first('communications')"><span class="text-primary fs-12 d-flex justify-content-start">{{ $t('contacts.form.agree_missing') }}</span>
                  
            </p>

                </div>


              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12" sm="12" class="justify-content-end d-flex">
          <b-button @click.prevent.stop="submit" variant="primary" class="mt-3">
            Submeter
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <loading />
    </div>
  </div>
</template>

<script>
import loading from '@/components/backoffice/common/loading'
import datePicker from '@/components/backoffice/common/datepicker'
import model from '@/models/contact'
import { actions } from '@/mixins/edit'
import api from '@/api/contact'

import { mapGetters } from 'vuex'


export default {
  mixins: [actions],
  components: {
    loading,
    datePicker,
  },
  data: () => ({
    api,
    model: JSON.parse(JSON.stringify(model.empty)),
    rules: model.rules,
    validations: {
      name: {
        required: true,
        alpha_spaces: true
      },
      email: {
        required: true,
        email: true
      },
      subject: {
        required: true
      },
      message: {
        required: true
      },
      file: {
        required: false
      },
      communications: {
        required: true
      }
    }
  }),
  computed: {
    ...mapGetters({
      item: 'form/fields'
    }),
    hasMedia() {
      return this.item && this.item.file && this.item.file != '' && !(this.item.file instanceof Blob)
    },
    fileName(){
      return this.item.media[0].name;
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.$route.params.id) {
        await Promise.all([this.getItem()])
      } else {
        this.initForm(this.model)
      }
      this.loading = false
    },
    setImage(file) {
      this.item.file = file
    },
  }
}
</script>