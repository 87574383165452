<template>
  <header>
    <nav class="navbar navbar-expand-md shadow d-none d-md-block mb-3">
      <div class="d-flex justify-content-end">
        <ul class="nav navbar-nav navbar-right">
          <li v-if="!loggedIn" class="nav-item">
            <router-link :to="{ name: 'register' }" class="nav-link">
              {{ this.$t('strings.REGISTER') }}
            </router-link>
          </li>
          <li v-if="!loggedIn" class="nav-item">
            <router-link :to="{ name: 'login' }" class="nav-link">
              {{ this.$t('strings.LOGIN') }}
            </router-link>
          </li>
          <li v-if="loggedIn" class="nav-item">
            <a href="#" class="nav-link" @click.prevent="$auth.logout()">
              Sair
              <font-awesome-icon :icon="logout" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  export default {
    data: () => ({
      logout: faSignOutAlt,
      user: faUserCircle,
    }),
    components: {
      FontAwesomeIcon
    },
    computed: {
      loggedIn () {
        return this.$auth.check()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@vars";

  .navbar {
    z-index: 999;
    background-color: $white;
    margin: 0 -0.9375rem;
    height: 3rem;

    @media (max-width: 767px) {
      margin: 0;
    }
  }

  .nav-link {
      color: $sidebar;
    }

  .border-left {
    border-left: 1px solid $sidebar;
  }
</style>