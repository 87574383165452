<template>
  <div v-if="!isLoading">
    
    <div class="d-none d-lg-block">
      <div class="row vh-body">
        <div class="col px-0">
          <b-carousel
            ref="carouselBanner"
            class="carousel-hc-banner"
            :interval="3000"
            v-model="currentBannerIndex"
            @sliding-start="onSlideStart"
            indicators
          >
            <b-carousel-slide
              v-for="(banner, index) in banners"
              :key="index"
              :img-src="banner.file"
              :img-alt="banner.title | localize"
              :img-title="banner.title | localize"
            >
              <div class="row h-100 align-items-center justify-content-between">
                <div class="col-5 pl-30 text-left">
                  <p
                    class="fs-40 text-primary font-weight-bold line-height-normal"
                  >{{ banner.title | localize }}</p>
                  <p class="fs-18 text-secondary">{{ banner.summary | localize }}</p>
                  <a
                    v-if="banner.url"
                    :href="banner.url"
                    class="fs-12 text-primary font-weight-bold text-decoration-none"
                  >
                    {{ $t('healthcare_consulting.know_more') }}
                    <i
                      class="anticon icon-arrowright fs-12 ml-3"
                    ></i>
                  </a>
                </div>
                <div v-if="showNext" class="col-2 bg-white p-5 h-50">
                  <div class="fs-24 text-blue-4 d-flex">
                    <span class="font-weight-bold">{{ index + 2 }}</span>
                    <span class="font-weight-light">&nbsp; / {{ banners.length }}</span>
                  </div>
                  <img
                    :src="nextBanner.file"
                    class="img-banner-preview-size"
                    :alt="nextBanner.title | localize"
                    :title="nextBanner.title | localize"
                  />
                  <p
                    class="fs-14 text-grey-2 font-weight-bold pt-2 text-left max-lines-2"
                  >{{ nextBanner.title | localize }}</p>
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
    
    <div class="d-lg-none">
      <div class="row px-offset">
        <div class="col-12 p-0">
          <div class="row">
            <div class="col-12">
              <b-carousel
                ref="carouselHCBannerMobile"
                class="carousel-hc-banner"
                :interval="3000"
                indicators
                v-model="currentBannerIndexMobile"
              >
                <b-carousel-slide
                  v-for="banner in  bannersMobile"
                  :key="banner.id"
                  :img-src="banner.file"
                  :img-alt="banner.file | localize"
                  :img-title="banner.file | localize"
                />
              </b-carousel>
            </div>
          </div>
          <div class="row px-offset m-0 z-100 position height">
            <div class="col-12 bg-white text-center">
              <div class="row justify-content-around align-items-center bg-white">
                <div class="col-12 py-5 text-center">
                  <p
                    class="text-primary fs-20 font-weight-bold"
                  >{{ currentBanner.title | localize }}</p>
                  <p class="text-grey-1 fs-16">{{ currentBanner.summary | localize }}</p>
                  <a
                    :href="currentBanner.url"
                    class="fs-14 text-primary font-weight-bold text-decoration-none"
                  >
                    {{ $t('healthcare_consulting.know_more') }}
                    <i
                      class="anticon icon-arrowright fs-14 ml-3"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import apiBanners from '@/api/banners'
import { mapGetters } from 'vuex'

export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    apiBanners,
    banners: [],
    isLoading: true,
    nextBanner: {},
    showNext: false,
    currentBannerIndex: 0,
    currentBannerIndexMobile: 0
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.apiBanners.all(this.filtersBanner)
      if (response && response.data && response.data.list) {
          this.banners = response.data.list
          this.isLoading = false
          if(this.banners.length > 1) {
            this.showNext = true
            this.nextBanner = this.banners[1]
          }
          else {
            this.showNext = false
          }
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    background(file) {
      return `background-image: url(${file})`
    },
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
    onSlideStart(currentBannerIndex) {
      if( currentBannerIndex+1 < this.banners.length) {
        this.nextBanner = this.banners[currentBannerIndex+1]
        this.showNext = true
      } else {
        this.showNext = false
      }
    },
  },
  computed: {
    filtersBanner() {
      return  {
        page: this.page.id,
      }
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    bannersMobile() {
      return this.banners.filter(banner => banner.url)
    },
    currentBanner() {
      return this.bannersMobile[this.currentBannerIndexMobile]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.bottom-0 {
  bottom: 0%;
}

.img-banner-preview-size {
    max-height: 8rem;
    width: 100%;
}

.position {
  transform: translateY(-20px);
}

.height {
  height: 17.1875rem;
  @include media-breakpoint-up(sm) {  
    height: 11.25rem;
  }
} 

</style>

<style lang="scss">
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.carousel-hc-banner .carousel-indicators {
  bottom: 0%;
}

.carousel-hc-banner .carousel-indicators li {
  background-color: $grey-1;
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;

  @include media-breakpoint-down(sm) {
      height: 0.4375rem;
      width: 0.4375rem;
  }
}

.carousel-hc-banner .carousel-indicators .active {
  background-color: $primary;
}

.carousel-hc-banner .carousel-inner .carousel-item > img{
  height: calc(100vh - 10rem);

  @include media-breakpoint-down(sm) {
      height: 18.75rem;
  }

  @include media-breakpoint-only(md) {
      height: $h-body-md;
  }
}

.carousel-hc-banner .carousel-inner .carousel-item .carousel-caption {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
}
</style>