<template>
  <div class="w-100">
    <b-form-group label="Título">
      <b-input
        v-model="item.title[locale]"
        name="title"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
    <b-form-group label="Resumo">
      <b-input
        v-model="item.summary[locale]"
        name="summary"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
    <b-form-group label="Slug">
      <b-input
        v-model="item.slug[locale]"
        name="slug"
        type="text"
        required
        placeholder="lorem-ipsum-set-dolor"
      />
    </b-form-group>
    <b-form-group label="Texto">
      <editor
        index="body"
        :locale="locale"
      />
    </b-form-group>
    <b-form-group label="Título Motor Busca">
      <b-input
        v-model="item.meta_title[locale]"
        name="meta_title"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
    <b-form-group label="Descrição Motor Busca">
      <b-input
        v-model="item.meta_description[locale]"
        name="meta_description"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
    <b-form-group label="Palavras Chave Motor Busca">
      <b-input
        v-model="item.meta_keywords[locale]"
        name="meta_keywords"
        type="text"
        required
        placeholder="lorem, ipsum, set, dolor"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import editor from '@/components/backoffice/common/editor'

export default {
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  components: {
    editor
  },
  computed: {
    ...mapGetters({
      item: 'form/fields'
    })
  }
}
</script>