<template>
  <b-modal
    id="modal-spontaneous"
    scrollable
    hide-footer
    centered
    :title="$t('careers.spontaneous_application')"
  >
    <div v-if="formSuccessMessage">
      <b-alert show variant="success" class="fs-12 text-paragraph-grey d-flex">
        <span class="anticon icon-check d-flex align-items-center fs-22 mr-4" />
        {{ $t('careers.form.success') }}
      </b-alert>
      <p class="fs-12 text-grey-2 font-weight-light" v-html="$t('careers.form.rgpd')"></p>
    </div>
    <div v-if="formErrorMessage">
      <b-alert class="fs-12 d-flex" show variant="warning">
        <span class="anticon icon-warning d-flex align-items-center fs-22 mr-4" />
        {{ $t('careers.form.unknown') }}
      </b-alert>
    </div>
    <b-form class="form" v-if="!formSent">
      <b-form-group id="input-name" :description="veeErrors.first('name')">
        <b-form-input
          name="name"
          v-model="form.name"
          type="text"
          :data-vv-as="$t('careers.form.name')"
          v-validate="validations.name"
          :placeholder="`*${$t('careers.form.name')}`"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-phone" :description="veeErrors.first('phone')">
        <b-form-input
          name="phone"
          v-model="form.phone"
          type="text"
          :data-vv-as="$t('careers.form.phone')"
          v-validate="validations.phone"
          :placeholder="`*${$t('careers.form.phone')}`"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-email" :description="veeErrors.first('email')">
        <b-form-input
          name="email"
          v-model="form.email"
          type="text"
          :data-vv-as="$t('careers.form.email')"
          v-validate="validations.email"
          :placeholder="`*${$t('careers.form.email')}`"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-place" :description="veeErrors.first('place')">
        <b-form-input
          name="place"
          v-model="form.place"
          type="text"
          :data-vv-as="$t('careers.form.place')"
          v-validate="validations.place"
          :placeholder="`*${$t('careers.form.place')}`"
        ></b-form-input>
      </b-form-group>
      <b-form-group :description="veeErrors.first('file')">
        <input
          id="input-file"
          type="file"
          @change="fileUploaded('file')"
          ref="fileInput"
          name="file"
          class="btn-choose-file"
          :data-vv-as="$t('careers.form.cv')"
          v-validate="validations.file"
          accept="image/x-png, image/gif, image/jpeg, application/pdf"
        />
        <label for="input-file" class="d-flex align-items-center justify-content-between">
          <span class="label fs-12 text-form-grey">
            <span v-if="fileChosen">{{ form.file.name }}</span>
            <span v-else>{{ $t('careers.form.cv_file') }}</span>
          </span>
          <div class="cursor-pointer border rounded px-5 py-2 fs-12 shadow-sm align-items-center justify-content-center">
            <span class="">{{ $t('careers.form.choose_file') }}</span>
          </div>
        </label>
      </b-form-group>
       <b-form-group :description="veeErrors.first('letter')">
        <input
          id="input-letter"
          type="file"
          @change="fileUploaded('letter')"
          ref="letterInput"
          name="letter"
          class="btn-choose-file"
          v-validate="validations.letter"
          accept="image/x-png, image/gif, image/jpeg, application/pdf"
        />
        <label for="input-letter" class="d-flex align-items-center justify-content-between">
          <span class="label fs-12 text-form-grey">
            <span v-if="letterChosen">{{ form.letter.name }}</span>
            <span v-else>{{ $t('careers.form.motivation_letter') }}</span>
          </span>
          <div class="cursor-pointer border rounded px-5 py-2 fs-12 shadow-sm align-items-center justify-content-center">
            <span class="">{{ $t('careers.form.choose_file') }}</span>
          </div>
        </label>
      </b-form-group>
      <b-form-group id="input-message" :description="veeErrors.first('message')">
        <b-form-textarea
          name="message"
          v-model="form.message"
          type="text"
          :data-vv-as="$t('careers.form.message')"
          v-validate="validations.message"
          :placeholder="`*${$t('careers.form.message')}`"
          no-resize
          rows="4"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group class="d-flex" id="input-agree">
        <input
          type="checkbox"
          class="fs-12 text-grey-2"
          id="agree"
          name="agree"
          v-model="formAgree"
          v-validate="validations.agree"
        />
        <span class="fs-14 text-grey-2">{{ $t('careers.form.newsletter') }}</span>
      </b-form-group>
      <p v-if="veeErrors.first('agree')">
        <span class="text-primary fs-12 d-flex justify-content-start">{{ $t('careers.form.agree_missing') }}</span>
      </p>
      <b-button
        class="text-uppercase px-5 text-grey-2 bg-white border border-grey-4 shadow-sm font-weight-normal"
        @click.stop="submit()"
      >{{ $t('careers.apply') }}</b-button>
    </b-form>
  </b-modal>
</template>
<script>
import localize from '@/filters/localize'
import api from '@/api/forms'

export default {
  data: () => ({
    api,
    formSuccessMessage: false,
    formErrorMessage: false,
    isLoading: false,
    formAgree: false,
    form: {
      name: null,
      phone: null,
      email: null,
      place: null,
      file: null,
      job: null,
      message: null
    },
    validations: {
      name: {
        required: true,
        alpha_spaces: true
      },
      phone: {
        required: true,
        numeric: true
      },
      email: {
        required: true,
        email: true
      },
      place: {
        required: true
      },
      file: {
        required: true
      },
      message: {
        required: true
      },
      agree: {
        required: true
      }
    }
  }),
  filters: {
    localize
  },
  methods: {
    translate(value) {
      return this.$options.filters.localize(value)
    },
    async submit() {
      const valid = await this.$validator.validate()
      if (valid) {
        this.isLoading = true
        const response = await this.api.job(this.formData)
        if (response && response.data) {
          this.isLoading = false
          this.formSuccessMessage = true
        } else if (response && response.response) {
          this.isLoading = false
          this.formErrorMessage = true
        }
      }
    },
    fileUploaded(type) {
      if (type === 'letter') {
        this.form.letter = this.$refs.letterInput.files[0]
      } else {
        this.form.file = this.$refs.fileInput.files[0]
      }
    },
  },
  computed: {
    formData() {
      var data = new FormData()
      Object.entries(this.form).forEach(([key, value]) =>
        data.append(
          key,
          typeof value === 'object' && ['file', 'letter'].indexOf(key) === -1
            ? JSON.stringify(value)
            : value
        )
      )
      return data
    },
    letterChosen() {
      return this.form.letter !== null && this.form.letter !== undefined
    },
    fileChosen() {
      return this.form.file !== null && this.form.file !== undefined
    },
    jobTitle() {
      return this.job ? this.translate(this.job) : ''
    },
    hasJob() {
      return this.job ? 'apply_title' : 'spontaneous_application'
    },
    formSent() {
      return this.isLoading || this.formSuccessMessage || this.formErrorMessage
    },
  }
};
</script>
<style lang="scss">
@import '~@vars';

.modal-backdrop {
  opacity: 0.5;
}
.modal-title {
  color: $primary;
}
</style>

<style lang="scss" scoped>

.btn-choose-file {
  display: none;
}

</style>