<template>
  <div v-if="!isLoading">
    <div class="d-none d-md-block">
      <div class="row vh-body">
        <div class="col position-relative">
          <div
            class="row h-80 justify-content-center align-items-center bg-cover"
            :style="background(banner.file)"
          ></div>
          <div
            class="row h-30 w-100 position-absolute bottom-0 justify-content-around align-items-center"
          >
            <div class="col-md-10 col-lg-8 h-100 bg-white text-center"> 
              <div class="row h-100 justify-content-around align-items-center bg-white">
                <div
                  v-for="(item, key) in $t('work_safety_health.header_list')"
                  :key="`item ${key}`"
                  class="col-3 text-center cursor-pointer"
                  @click.stop="setSelectedItem(item)"
                >
                  <img
                    :src="sourceItemImage(item)"
                    class="img-fluid"
                    :alt="item.image_alt"
                    :title="item.image_title"
                  />
                  <p
                    class="text-uppercase fs-18 font-weight-bold item"
                    :class="{ active: isSelectedItem(item) }"
                  >{{ item.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-around">
        <div class="col-8">
          <div class="row justify-content-around">
            <div class="col text-center px-10">
              <p class="text-grey-1 fs-14">{{ selectedItem.summary }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none">
      <div class="row px-offset">
        <div class="col-12 p-0">
          <div class="row justify-content-center bg-mobile" :style="background(banner.file)">
            <div class="col py-15 text-center">
              <p class="text-white fs-30 font-weight-bold mb-8">{{ banner.title | localize }}</p>
              <p class="text-white fs-16">{{ banner.summary | localize }}</p>
            </div>
          </div>
          <div class="row px-offset z-100 position">
            <div class="col-12 bg-white text-center">
              <div class="row justify-content-around align-items-center bg-white">
                <div
                  v-for="(item, key) in $t('work_safety_health.header_list')"
                  :key="`item ${key}`"
                  class="col-12 py-5 text-center"
                >
                  <img
                    :src="sourceItemImageMobile(item)"
                    class="img-fluid"
                    :alt="item.image_alt"
                    :title="item.image_title"
                  />
                  <p class="text-grey-2 text-uppercase fs-20 mt-4 font-weight-bold">{{ item.title }}</p>
                  <p class="text-grey-1 fs-16">{{ item.summary }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiBanners from '@/api/banners'

export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    apiBanners,
    banner: {},
    isLoading: true,
    selectedItem: '',
  }),
  created() {
    this.getBanners()
  },
  methods: {
    async getBanners() {
      const response = await this.apiBanners.all(this.filtersBanners)
      if (response && response.data && response.data.list) {
        if (response.data.list.length > 0) {
          this.banner = response.data.list[0]
          this.selectedItem = this.$t('work_safety_health.header_list[0]')
          this.isLoading = false
        }
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    background(file) {
      return `background-image: linear-gradient(rgba(2,119,80,0.5),rgba(2,119,80,0.5)), url(${file})`
    },
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    setSelectedItem(item) {
      this.selectedItem = item
    },
    isSelectedItem(item) {
      return this.itemActive(item) ? 'active' : ''
    },
    sourceItemImage(item) {
      if(this.itemActive(item)) {
        return `assets/images/work-safety-health/header-icons/${item.image_id}-green.png`
      } else {
        return `assets/images/work-safety-health/header-icons/${item.image_id}-grey.png`
      }
    },
    sourceItemImageMobile(item) {
      return `assets/images/work-safety-health/header-icons/${item.image_id}-green.png`
    },
    itemActive(item) {
      return item === this.selectedItem
    },
  },
  computed: {
    filtersBanners() {
      return  {
        take: 1,
        skip: 0,
        page: this.page.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.item {
  color: $grey-1;
  &.active {
    color: $grey-2;
  }
}

.bottom-0 {
  bottom: 0%;
}

.position {
  transform: translateY(-20px);
}

.bg-mobile {
  background-position: center;
  background-size: cover;
}
</style>