<template>
  <div id="mobile" class="row flex-column d-md-none px-offset pt-10">
    <div class="col-12 d-flex flex-column px-0">
      <img
        src="assets/images/logos/sepri_group.svg"
        class="img-fluid object-fit-contain w-35 mb-5 mx-auto"
        :alt="$t('footer.image_alt')"
        :title="$t('footer.image_title')"
      />
      
      <div id="footer_accordion" class="accordion" role="tablist">
        
        <b-card no-body class="mb-1 border-0 bg-transparent">
          <b-card-header header-tag="header" class="fs-20 bg-transparent border-0" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-1
              class="fs-20 text-uppercase font-weight-bold bg-transparent border-top border-bottom-0 border-right-0 border-left-0 py-4 shadow-none"
              variant="info"
            >SEPRI</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <ul class="p-0 text-center fs-16">
                  <router-link
                    v-for="page in headerPages"
                    :key="page.id"
                    :to="{ name: page.route, params: { lang: $i18n.locale, path: translate(page.url) } }"
                    class="d-flex justify-content-center fs-20 py-1 text-secondary"
                  >{{ page.title | localize }}</router-link>
                </ul>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        
        <b-card no-body class="mb-1 border-0 bg-transparent">
          <b-card-header header-tag="header" class="fs-20 bg-transparent border-0" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle.accordion-2
              class="fs-20 text-uppercase font-weight-bold bg-transparent border-top border-bottom-0 border-right-0 border-left-0 py-4 shadow-none"
              variant="info"
            >{{ $t('footer.informations') }}</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <ul class="p-0 text-center fs-16">
                  <template v-for="page in footerPages">
                    <a
                      :key="page.id"
                      v-if="isClientArea(page)"
                      class="d-flex justify-content-center fs-20 py-1 text-secondary text-decoration-none"
                      href="https://sepri.safemed.pt"
                    >{{ $t('header.client_area') }}</a>
                    <p
                      :key="page.id"
                      v-else-if="isQualityPolicy(page)"
                      @click.stop="getQualityPolicy(page)"
                      class="d-flex justify-content-center fs-20 py-1 text-secondary text-decoration-none"
                    >{{ page.title | localize }}</p>
                    <router-link
                      :key="page.id"
                      v-else
                      :to="{ name: page.route, params: { lang: $i18n.locale, path: translate(page.url) } }"
                      class="d-flex justify-content-center fs-20 py-1 text-secondary text-decoration-none"
                    >{{ page.title | localize }}</router-link>
                  </template>
                </ul>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      
    </div>
  </div>
</template>

<script>
import copyright from '@/components/frontoffice/footer/copyright'

export default {
  components: {
    copyright
  },
  props: {
    pages: {
      required: true,
      type: Array
    }
  },
  methods: {
    translate(value) {
      return this.$options.filters.localize(value)
    },
    isQualityPolicy(page) {
      return page.route === 'quality-policy'
    },
    isClientArea(page) {
      return page.route === 'client-area'
    },
    getQualityPolicy(page) {
      const url =  page.file[0].url
      window.open(url, '_blank')
    }
  },
  computed: {
    headerPages() {
      return this.pages.filter(page => page.is_header && !page.is_solution).sort((a, b) => a.order - b.order)
    },
    footerPages() {
      return this.pages.filter(page => page.is_footer && !page.is_header)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: $secondary;
}

</style>