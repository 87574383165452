import { locales } from '@/constants/settings'

const localizedFieldFormat = locales.reduce((sum, value) => ({ ...sum, [value]: null }), {})

const emptyModelFormat = item =>
  item.translatable
    ? localizedFieldFormat
    : item.type === 'boolean' ? false : ''

const rules = fields => fields.reduce((sum, value) => ({ ...sum, [value.field]: value.rules }), {})
const empty = (fields) => fields.reduce((sum, value) => ({ ...sum, [value.field]: emptyModelFormat(value) }), {})

export default {
  rules,
  empty
}
