import subscribersList from '@/views/backoffice/subscribers/list'
import subscribersEdit from '@/views/backoffice/subscribers/edit'

export default [
  {
    path: 'subscribers/create',
    name: 'admin-subscribers-create',
    component: subscribersEdit
  },
  {
    path: 'subscribers',
    name: 'admin-subscribers-list',
    component: subscribersList
  },
  {
    path: 'subscribers/:id',
    name: 'admin-subscribers-edit',
    component: subscribersEdit
  }
]