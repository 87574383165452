<template>
  <div class="w-100">
    <b-form-group label="Cargo Autor">
      <b-input
        v-model="item.job[locale]"
        name="job"
        type="text"
        required
        placeholder="Lorem"
      />
    </b-form-group>
    <b-form-group label="Empresa Autor">
      <b-input
        v-model="item.company"
        name="company"
        type="text"
        required
        placeholder="Lorem"
      />
    </b-form-group>
    <b-form-group label="Mensagem">
      <b-input
        v-model="item.message[locale]"
        name="message"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      item: 'form/fields'
    })
  }
}
</script>