<template>
  <div class="container-fluid bg-image bg-cover">
    <mobile id="mobile" :pages="pages" />
    <div id="desktop" class="row flex-column px-offset pt-md-15">
      <div class="col">
        <div class="row justify-content-between pb-md-10">
          <div class="col-2 d-none d-md-block">
            <img
              src="assets/images/logos/sepri_group.svg"
              class="img-fluid logo-size"
              :alt="$t('logo_sepri_image_alt')"
              :title="$t('logo_sepri_image_title')"
            />
          </div>
          <div class="col-5 d-none d-md-block position-relative">
            <pages :pages="pages" />
          </div>
          <div class="col-md-5 px-down-md-0">
            <div class="row">
              <div class="col-lg-12 col-md-12 px-down-md-0 mb-md-10 border-top border-md-0 py-4 py-md-0">
                <newsletter value="desktop" />
              </div>
              <div class="col-md-12 px-down-md-0 pb-md-5 border-top border-md-0">
                <div class="d-flex fs-md-14 fs-18 text-uppercase text-secondary text-decoration-none py-4 py-md-0 justify-content-center justify-content-md-start">
                  <span class="font-weight-bold text-secondary follow">{{ $t('footer.follow_us') }}</span>
                  <div class="line mx-3 mt-md-2 mt-3"></div>
                  <a
                    class="text-decoration-none"
                    href="https://www.linkedin.com/company/sepri-group"
                  >
                    <img
                      src="assets/images/linkedin.svg"
                      class="icon-image"
                      :alt="$t('footer.linkedin_image_alt')"
                      :title="$t('footer.linkedin_image_title')"
                    />
                  </a>
                  <a class="text-decoration-none ml-2" href="https://www.facebook.com/grupo.sepri">
                    <img
                      src="assets/images/facebook.svg"
                      class="icon-image"
                      :alt="$t('footer.facebook_image_alt')"
                      :title="$t('footer.facebook_image_title')"
                    />
                  </a>
                  <a
                    class="text-decoration-none ml-2"
                    href="https://www.instagram.com/sepri_group/?hl=en"
                  >
                    <img
                      src="assets/images/instagram.svg"
                      class="icon-image"
                      :alt="$t('footer.instagram_image_alt')"
                      :title="$t('footer.instagram_image_title')"
                    />
                  </a>
                </div>
              </div>

              <div class="col-8 d-none d-md-flex">
                <img
                  src="assets/images/footer/logos.svg"
                  class="img-fluid"
                  :alt="$t('footer.image_alt')"
                  :title="$t('footer.image_title')"
                />
              </div>
              <div class="d-flex d-md-none justify-content-center border-top border-md-0 pt-4 w-100">
                <img
                  src="assets/images/logos/norte2020-desktop.svg"
                  class="img-fluid object-fit-contain my-5 mx-auto"
                  :alt="$t('footer.image_alt')"
                  :title="$t('footer.image_title')"
                />
              </div>
              <div class="d-flex d-md-none justify-content-center border-md-0 border-bottom pb-4 w-100">
                <img
                  src="assets/images/footer/logos.svg"
                  class="img-fluid object-fit-contain mt-5 mx-auto h-75"
                  :alt="$t('footer.image_alt')"
                  :title="$t('footer.image_title')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-md-0">
          <copyright />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import copyright from '@/components/frontoffice/footer/copyright'
import newsletter from '@/components/frontoffice/footer/newsletter'
import pages from '@/components/frontoffice/footer/pages'
import mobile from '@/components/frontoffice/footer/mobile'

export default {
  components: {
    copyright,
    newsletter,
    pages,
    mobile
  },
  props: {
    pages: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.fs-md-14 {
  @include media-breakpoint-up(md) {
    font-size: 0.875rem !important;
  }
}

.border-md-0 {
  @include media-breakpoint-up(md) {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
}

.px-down-md-0 {
    @include media-breakpoint-down(md) {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}

.bg-image {
  background-image: url(/assets/images/footer/background.svg);

  @include media-breakpoint-down(sm) {
    background-image: url(/assets/images/footer/background-mobile.png);
  }
}

.follow {
  @include media-breakpoint-down(md) {
    color: rgba($secondary, 0.8);
  }
}

.line {
  border-top: 1px solid rgba($secondary, 0.7);
  width: 1.5rem;
}

.icon-image {
  height: 2rem;
  margin-top: -0.375rem;
}
</style>