<template>
  <div>
    <b-form v-if="!loading">
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Geral
            </b-card-header>
            <b-card-body>
              <b-tabs
                no-nav-style
                nav-class="border-right border-light h-100"
                nav-wrapper-class="p-0"
                class="mx-0"
                active-nav-item-class="font-weight-bolder border-right text-secondary border-secondary"
                content-class="py-5 w-100 bg-white"
                vertical
              >
                <b-tab
                    v-for="locale in locales"
                    :key="locale"
                    :title="$t(`locales.${locale}`)"
                >
                  <projects-form :locale="locale" />
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Adicional
            </b-card-header>
            <b-card-body>
              <b-form-group label="Imagem">
                <div
                  v-if="hasMedia"
                  class="w-100 mb-3"
                >
                  <img
                    class="img-thumbnail"
                    :src="item.file"
                  >
                </div>
                <image-uploader
                  :maxWidth="1920"
                  :quality="1"
                  :autoRotate=true
                  outputFormat="blob"
                  :preview=true
                  capture="environment"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  @input="setImage"
                  class="mb-3"
                />
              </b-form-group>
              <b-form-group label="Data publicação">
                <date-picker index="published_at" />
              </b-form-group>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          class="justify-content-end d-flex"
        >
          <b-button
            @click.prevent.stop="submit"
            variant="primary"
            class="mt-3"
          >
            Submeter
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <loading />
    </div>
  </div>
</template>

<script>
import loading from '@/components/backoffice/common/loading'
import datePicker from '@/components/backoffice/common/datepicker'
import projectsForm from '@/components/backoffice/modules/projects/form'
import model from '@/models/projects'
import { actions } from '@/mixins/edit'
import api from '@/api/projects'

export default {
  mixins: [actions],
  components: {
    loading,
    projectsForm,
    datePicker
  },
  data: () => ({
    api,
    model: JSON.parse(JSON.stringify(model.empty)),
    rules: model.rules,
  }),
  computed: {
    hasMedia () {
      return this.item && this.item.file && this.item.file != '' && !(this.item.file instanceof Blob)
    }
  },
  created () {
    this.init ()
  },
  methods: {
    async init () {
      if (this.$route.params.id) {
        await Promise.all([this.getItem()])
      } else {
        this.initForm(this.model)
      }
      this.loading = false
    },
    setImage (file) {
      this.item.file = file
    }
  }
}
</script>