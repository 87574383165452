<template>
  <div class="d-inline-block float-right">
    <b-button
      variant="muted"
      class="p-0 text-white"
      @click.stop="callEditItem()"
    >
      <font-awesome-icon :icon="icon.edit" />
    </b-button>
    <click-confirm
      class="btn btn-muted p-0"
      :messages="confirmationMessages"
    >
      <template slot="confirm-yes-icon">
        <font-awesome-icon :icon="icon.thumbsUp" />
      </template>
      <template slot="confirm-no-icon">
        <font-awesome-icon :icon="icon.thumbsDown" />
      </template>
      <b-button
        variant="muted"
        class="p-0 text-white"
        @click.stop="callRemoveItem()"
      >
        <font-awesome-icon :icon="icon.trash" />
      </b-button>
    </click-confirm>
  </div>
</template>

<script>
import ClickConfirm from 'click-confirm'
import {
  faEdit,
  faTrashAlt,
  faThumbsUp,
  faThumbsDown,
  faListAlt,
} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FontAwesomeIcon,
    ClickConfirm
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    icon: {
      edit: faEdit,
      trash: faTrashAlt,
      thumbsUp: faThumbsUp,
      thumbsDown: faThumbsDown,
      order: faListAlt,
    },
    confirmationMessages: {
      title: 'Tem a certeza?',
      yes: 'Sim',
      no: 'Não'
    }
  }),
  methods: {
    callEditItem () {
      this.$emit('editItem', this.id)
    },
    callRemoveItem () {
      this.$emit('removeItem', this.id)
    }
  }
}
</script>
