<template>
  <div>
    <div class="d-block mb-2">
      <div class="px-3 py-3">
        <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
          <div>
            <button
              class="btn fs-14"
              :class="isActive.heading({ level: 1 }) ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="commands.heading({level: 1})"
            >
              <b>H1</b>
            </button>
            <button
              class="btn fs-14"
              :class="isActive.heading({ level: 2 }) ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="commands.heading({level: 2})"
            >
              <b>H2</b>
            </button>
            <button
              class="btn fs-14"
              :class="isActive.heading({ level: 3 }) ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="commands.heading({level: 3})"
            >
              <b>H3</b>
            </button>
            <button
              class="btn fs-14"
              :class="isActive.bold() ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="commands.bold"
            >
              <b>B</b>
            </button>
            <button
              class="btn fs-14"
              :class="isActive.ordered_list() ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="commands.ordered_list"
            >
              <font-awesome-icon :icon="faListOl" />
            </button>
            <button
              class="btn fs-14"
              :class="isActive.bullet_list() ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="commands.bullet_list"
            >
              <font-awesome-icon :icon="faListUl" />
            </button>
            <button
              class="btn fs-14"
              :class="isActive.image() ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="showImageModal(commands.image)"
            >
              <font-awesome-icon :icon="faImage" />
            </button>
            <button
              class="btn fs-14"
              :class="isActive.link() ? 'btn-primary' : 'btn-outline-primary'"
              @click.stop.prevent="commands.link"
            >
              <font-awesome-icon :icon="faLink" />
            </button>
          </div>
        </editor-menu-bar>
        <editor-menu-bubble
          :editor="editor"
          @hide="hideLinkMenu"
          v-slot="{ commands, isActive, getMarkAttrs, menu }"
          class="mt-1"
        >
          <div
            :class="{ 'is-active': menu.isActive }"
          >
            <form v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
              <input
                type="text"
                v-model="linkUrl"
                placeholder="https://"
                ref="linkInput"
                @keydown.esc="hideLinkMenu"
                class="border-primary rounded input-link"
              />
              <button class="btn fs-14 btn-outline-primary btn-link" @click="setLinkUrl(commands.link, null)" type="button">
                <font-awesome-icon :icon="faTrash" />
              </button>
            </form>
            <template v-else>
              <button
                class="btn fs-14 btn-outline-primary"
                @click="showLinkMenu(getMarkAttrs('link'))"
                :class="{ 'is-active': isActive.link() }"
              >
                <span>{{ isActive.link() ? 'Atualizar Link' : 'Adicionar Link'}}</span>
                <font-awesome-icon :icon="faLink" />
              </button>
            </template>
          </div>
        </editor-menu-bubble>
      </div>
    </div>

    <div class="border rounded">
      <div class="d-block px-3 py-2">
        <editor-content :editor="editor" />
      </div>
    </div>
    <input
      v-model="item[index][locale]"
      class="form-control"
      name="description"
      type="hidden"
      required
      placeholder="Lorem ipsum set dolor"
    />

    <b-modal
      id="modal-image"
      ref="modal-image"
      scrollable
      centered
      title="Selecione uma imagem"
      ok-title="Submeter"
      cancel-title="Cancelar"
      @ok="submit"
    >
      <image-uploader
        :preview="true"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        outputFormat="string"
        @input="setImage"
      >
        <label for="fileInput"></label>
      </image-uploader>
    </b-modal>
  </div>
</template>


<script>
import { faListUl, faListOl, faImage, faLink, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble  } from 'tiptap'
import {
  Heading,
  Bold,
  Italic,
  OrderedList,
  BulletList,
  ListItem,
  Image,
  Link,
} from 'tiptap-extensions'
import ImageUploader from 'vue-image-upload-resize'
import { mapGetters } from 'vuex'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    FontAwesomeIcon,
    ImageUploader,
    EditorMenuBubble
  },
  props: {
    index: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    isRelationship: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    editor: null,
    value: null,
    faListOl,
    faListUl,
    faImage,
    faLink,
    faTrash,
    command: null,
    image: '',
    hasImage: false,
    linkUrl: null,
    linkMenuIsActive: false,
  }),
  computed: {
    ...mapGetters({
      formItem: 'form/fields',
      relationshipItem: 'form/relationshipFields'
    }),
    item () {
      return this.isRelationship
        ? this.relationshipItem
        : this.formItem
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new Heading({ levels: [1, 2, 3] }),
        new Bold(),
        new Italic(),
        new OrderedList(),
        new BulletList(),
        new ListItem(),
        new Image(),
        new Link()
      ],
      onUpdate: () =>
        (this.item[this.index][this.locale] = this.editor.getHTML()),
      content: this.item[this.index][this.locale]
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    showImageModal(command) {
      this.command = command
      this.$refs['modal-image'].show()
    },
    setImage(output) {
      this.hasImage = true;
      this.image = output;
    },
    submit() {
      const src = this.image
      this.command({ src })
    },
  },
}
</script>

<style lang="scss">

div.ProseMirror p img {
  max-width: 100%;
  height: auto;
}

</style>

<style lang="scss" scoped>
@import '@vars';

.input-link {
  border-width: 0.0625rem;
  padding: 0.25rem;
}

.btn-link {
  margin-top: -0.3125rem;
}

</style>