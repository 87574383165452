import projectsList from '@/views/backoffice/projects/list'
import projectsEdit from '@/views/backoffice/projects/edit'

export default [
  {
    path: 'projects/create',
    name: 'admin-projects-create',
    component: projectsEdit
  },
  {
    path: 'projects',
    name: 'admin-projects-list',
    component: projectsList
  },
  {
    path: 'projects/:id',
    name: 'admin-projects-edit',
    component: projectsEdit
  }
]