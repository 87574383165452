import testimonialsList from '@/views/backoffice/testimonials/list'
import testimonialsEdit from '@/views/backoffice/testimonials/edit'

export default [
  {
    path: 'testimonials/create',
    name: 'admin-testimonials-create',
    component: testimonialsEdit
  },
  {
    path: 'testimonials',
    name: 'admin-testimonials-list',
    component: testimonialsList
  },
  {
    path: 'testimonials/:id',
    name: 'admin-testimonials-edit',
    component: testimonialsEdit
  }
]