<template>
  <div class="row px-offset mt-10">
    <div class="col px-20">
      <div class="row py-10 justify-content-center">
        <div class="col-6 text-center align-self-center">
          <div
            class="text-grey-2 font-weight-bold fs-40 text-capitalize"
          >{{ $t('work_safety_health.featured.title') }}</div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <b-carousel
            ref="carouselWorkSafetyHealthNews"
            class="carousel-work-safety-health-news"
            :interval="3000"
            img-width="1024"
            :img-height="height"
          >
            <b-carousel-slide img-blank v-for="list in chunckList" :key="list.id">
              <div class="row h-100 justify-content-around align-items-center">
                <div class="col-1 p-0 cursor-pointer" @click.stop="prev">
                  <i class="anticon icon-left fs-40 arrow-color" />
                </div>
                <div v-for="news in list" :key="news.id" class="col-3 p-0 align-self-center h-100">
                    <div class="h-50 w-100 bg-cover" :style="background(news.file)">
                    </div>
                  <div class="text-left p-5 ml-10 bg-white margin-top-negative z-100">
                    <p class="text-primary fs-12 font-weight-bold mb-1">{{ news.title | localize }}</p>
                    <p class="text-grey-2 fs-16 mb-1 max-lines-3">{{ news.summary | localize }}</p>
                    <router-link
                      :to="getRoute(news)"
                      class="fs-12 text-primary font-weight-bold text-decoration-none"
                    >
                      {{ $t('work_safety_health.know_more') }}
                      <i
                        class="anticon icon-arrowright fs-12 ml-3"
                      ></i>
                    </router-link>
                  </div>
                </div>
                <div class="col-1 p-0 cursor-pointer" @click.stop="next">
                  <i class="anticon icon-right fs-40 arrow-color" />
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import chunckArrayMixin from '@/mixins/chunckArray'

export default {
  mixins: [
    chunckArrayMixin
  ],
  data: () => ({
    fullList: [],
    chunkSize: 3
  }),
  props: {
    news: {
      type: Array,
      required: true
    }
  },
  created() {
    this.fullList = this.news
  },
  methods: {
    vhToPixels(vh) {
      return Math.round(window.innerHeight / (100 / vh))
    },
    next() {
      this.$refs.carouselWorkSafetyHealthNews.next()
    },
    prev() {
      this.$refs.carouselWorkSafetyHealthNews.prev()
    },
    getRoute(news) {
      return {
        name: this.pageNews.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.pageNews.url), slug: this.translate(news.slug) }
      }
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
    background(file) {
      return `background-image: url(${file})`
    }
  },
  computed: {
    height() {
      return this.vhToPixels(50)
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    pageNews() {
      return this.pages.find(page => page.route === 'news')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.arrow-color {
  color: rgba($black, 0.25);
}

.margin-top-negative {
  margin-top: -2.5rem;
}

</style>

<style lang="scss">
@import "~@vars";

.carousel-work-safety-health-news .carousel-inner .carousel-item .carousel-caption {
  height: 100%;
  width: 100%;
  right: 0%;
  left: 0%;
  top:0%;

  padding: 0rem;
}
</style>