<template>
  <div class="row justify-content-center height py-lg-10">
    <div class="col-12 col-md-8">
      <div class="row h-100 justify-content-center">
        <div v-for="solution in solutions" :key="solution.id" class="col-12 col-md-6 col-xl-4 text-center">
          <info-card
            id="info-card"
            frontTitle
            :frontData="frontData(solution)"
            backTitle
            :backData="backData(solution)"
          ></info-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import localize from '@/filters/localize'
import InfoCard from 'vue-info-card'

export default {
  props: {
    solutions: {
      type: Array,
      required: true
    }
  },
  components: {
    InfoCard
  },
  data: () => ({
    isLoading: true
  }),
  filters: {
    localize
  },
  created() {},
  methods: {
    backData(solution) {
      const title = this.translate(solution.title)
      const summary = this.translate(solution.summary)
      const url = this.translate(solution.url)
      const route = solution.route
      let color
      switch (solution.route) {
        case 'safety-health':
          color = 'text-green-1'
          break
        case 'health-services-24':
          color = 'text-blue-3'
          break
        case 'accident-medicine':
          color = 'text-red-1'
          break
        case 'environmental-studies-laboratory':
          color = 'text-brown-1'
          break
        case 'healthcare-consulting':
          color = 'text-blue-4'
          break
        default:
          color = 'text-primary'
      }
      return  `  
                <div class="h-100" style="background: linear-gradient(rgba(255,255,255,0.95),rgba(255,255,255,0.95)), url(assets/images/solutions/${route}-grey.svg) no-repeat center; background-size: auto 100%; ">
                  <h2 class="fs-22 text-uppercase mb-5 font-weight-bold ${color} ">${title}</h2>
                  <p class="backData-fs-14 text-black mb-5 line-height-normal">${summary}</p>
                  <a
                      href="/${this.$i18n.locale}/${url}"
                      class="backData-fs-12 text-primary text-decoration-none"
                  >
                      Saiba mais
                      <i class="anticon icon-arrowright backData-fs-12 ml-3"></i>
                  </a>
                </div>
              `
    },
    frontData(solution) {
      const title = this.translate(solution.title)
      const route = solution.route
      return `
          <div class="h-60 d-flex flex-column justify-content-center align-items-center">
            <img
              src="assets/images/solutions/${route}-white.svg" 
              class="solution-image"
              :alt="solution.route"
              :title="solution.route"
            />
          </div>
          <h2 class="fs-22 text-center text-uppercase font-weight-bold text-white pt-2">${title}</h2>
        `
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.height { 
  height: 43.75rem;
  @include media-breakpoint-down(lg) {
    height: 55.625rem;
  }
  @include media-breakpoint-down(md) {
    height: 60.625rem;
  }
  @include media-breakpoint-down(sm) {
    height: 92.625rem;
  }
}

</style>

<style lang="scss">
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.solution-image {
  width: 4.375rem;
  height: 4.375rem;
}

.info-card {
  height: 100%;
  padding: 1rem 0.5rem !important;
  .body {
    padding: 0rem !important;
    height: 100%;
    >p {
      height: 100%;
    }
  }
}

.front .info-card {
  background-image: url(/assets/images/solutions/card-front-background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.front, .back {
    height: 17rem;
    @include media-breakpoint-down(lg) {
      height: 15rem;
    }
    @include media-breakpoint-down(md) {
      height: 17.625rem;
    }
}


.backData-fs-12 {
  font-size: $fs-12;
  @include media-breakpoint-down(md) {
    font-size: $fs-14;
  }
}

.backData-fs-14 {
  font-size: $fs-14;
  @include media-breakpoint-down(sm) {
    font-size: $fs-16;
  }
}
</style>