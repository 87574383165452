<template>
  <div class="container-fluid">
    <appHighlight :page="page"/>
    <div class="row">
      <div class="col-12 col-lg-6 p-0 position-relative">
        <div class="row">
          <div class="col-12 p-0">
            <iframe
              class="w-100 h-100 height"
              src="https://maps.google.com/maps?q=sepri%20group&t=&z=13&ie=UTF8&iwloc=&output=embed"
              width="600"
              height="450"
              frameborder="0"
              style="border:0;"
              allowfullscreen
            ></iframe>
            <div class="position-absolute card-position py-2 px-1 px-lg-3 m-0 ml-3 bg-white">
              <div class="fs-12">
                <span class="font-weight-bolder">{{ $t('contacts.email_label') }}</span>
                <span>{{ $t('contacts.email') }}</span>
                <div class="d-none d-lg-block mb-1"></div>
                <span class="font-weight-bolder ml-5 ml-lg-0">{{ $t('contacts.phone_label') }}</span>
                <span>{{ $t('contacts.phone') }}</span>
                <span>{{ $t('contacts.call_tax_disclosure.phone')}}</span>
                <div class="mb-1"></div>
                <span class="font-weight-bolder">{{ $t('contacts.phone_home_label') }}</span>
                <span>{{ $t('contacts.phone_home') }}</span>
                <span>{{ $t('contacts.call_tax_disclosure.mobile')}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 mt-2">
            <iframe
              class="w-100 h-100 height"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.4681139994727!2d-9.147474185368385!3d38.75296606305103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd193348cdf47cfb%3A0x9a47a1e378f68724!2sSEPRI%20-%20Medicina%20no%20Trabalho!5e0!3m2!1spt-PT!2spt!4v1638893014963!5m2!1spt-PT!2spt"
              width="600"
              height="450"
              frameborder="0"
              style="border:0;"
              allowfullscreen
            ></iframe>
            <div class="position-absolute card-position lisbon py-2 px-1 px-lg-3 m-0 ml-3 bg-white">
              <div class="fs-12">
                <span class="font-weight-bolder">{{ $t('contacts.email_label') }}</span>
                <span>{{ $t('contacts.email_lisbon') }}</span>
                <div class="mb-1"></div>
                <span class="font-weight-bolder">{{ $t('contacts.phone_label') }}</span>
                <span>{{ $t('contacts.phone_lisbon') }}</span>
                <span>{{ $t('contacts.call_tax_disclosure.phone')}}</span>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 mt-2">
            <iframe
              class="w-100 h-100 height"
              src="https://maps.google.com/maps?q=%20Maputo%20Avenida%20Friedrich%20Engels%2C%20207%2C%203%C2%BA%20andar&t=&z=13&ie=UTF8&iwloc=&output=embed"
              width="600"
              height="450"
              frameborder="0"
              style="border:0;"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 px-offset px-lg-20">
        <div v-if="formSuccessMessage">
          <b-alert show variant="success" class="fs-12 text-paragraph-grey d-flex">
            <span class="anticon icon-check d-flex align-items-center fs-22 mr-4" />
            {{ $t('contacts.form.success') }}
          </b-alert>
          <p class="fs-12 text-grey-2 font-weight-light" v-html="$t('contacts.form.rgpd')"></p>
        </div>
        <div v-if="formErrorMessage">
          <b-alert class="fs-12 d-flex" show variant="warning">
            <span class="anticon icon-warning d-flex align-items-center fs-22 mr-4" />
            {{ $t('contacts.form.unknown') }}
          </b-alert>
        </div>
        <div>
          <p
            class="text-primary fs-24 text-center py-5 font-weight-bold"
          >{{ $t('contacts.form.title') }}</p>
          <b-form>
            <div class="row">
              <div class="col">
                <b-form-group id="input-name" :description="veeErrors.first('name')">
                  <b-form-input
                    name="name"
                    v-model="form.name"
                    type="text"
                    :data-vv-as="$t('contacts.form.name')"
                    v-validate="validations.name"
                    :placeholder="`*${$t('contacts.form.name')}`"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group id="input-email" :description="veeErrors.first('email')">
                  <b-form-input
                    name="email"
                    v-model="form.email"
                    type="email"
                    :data-vv-as="$t('contacts.form.email')"
                    v-validate="validations.email"
                    :placeholder="`*${$t('contacts.form.email')}`"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <b-form-group id="input-subject" :description="veeErrors.first('subject')">
              <b-form-input
                name="subject"
                v-model="form.subject"
                type="text"
                :data-vv-as="$t('contacts.form.subject')"
                v-validate="validations.subject"
                :placeholder="`*${$t('contacts.form.subject')}`"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <input
                id="input-file"
                type="file"
                @change="fileUploaded"
                ref="fileInput"
                class="btn-choose-file"
                accept="image/x-png, image/gif, image/jpeg, application/pdf"
              />
              <label for="input-file" class="d-flex align-items-center justify-content-between">
                <span class="label fs-14 text-grey-2">
                  <span v-if="fileChosen">{{ form.file.name }}</span>
                  <span v-else>{{ $t('contacts.form.attach') }}</span>
                </span>
                <div
                  class="cursor-pointer border rounded px-5 py-2 fs-14 shadow-sm align-items-center justify-content-center"
                >
                  <span class>{{ $t('contacts.form.choose_file') }}</span>
                </div>
              </label>
            </b-form-group>
            <b-form-group id="input-message" :description="veeErrors.first('message')">
              <b-form-textarea
                name="message"
                v-model="form.message"
                type="text"
                :data-vv-as="$t('contacts.form.message')"
                v-validate="validations.message"
                :placeholder="`*${$t('contacts.form.message')}`"
                no-resize
                rows="4"
              ></b-form-textarea>
            </b-form-group>
            <p class="fs-10 text-grey-1 text-right">*{{ $t('contacts.form.required') }}</p>
            <b-form-group class="d-flex" id="input-agree">
              <input
                type="checkbox"
                class="fs-12 text-grey-2"
                name="agree"
                v-model="form.communications"
                v-validate="validations.agree"
                true-value="1" 
                false-value="0"
              />
              <span class="fs-14 text-grey-2">{{ $t('contacts.form.newsletter') }}</span>
            </b-form-group>
            <p v-if="veeErrors.first('agree')">
              <span
                class="text-primary fs-12 d-flex justify-content-start"
              >{{ $t('contacts.form.agree_missing') }}</span>
            </p>
          </b-form>
          <b-button
            @click.stop="submit"
            class="text-uppercase px-5 mt-3 text-grey-2 bg-white border border-grey-4 shadow-sm font-weight-normal"
          >{{ $t('contacts.form.submit') }}</b-button>
          <p class="text-grey-1 fs-10 mt-10 lh-privacy">{{ $t('contacts.form.privacy') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appHighlight from '@/components/frontoffice/common/highlight'
import api from '@/api/forms'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  components: {
    appHighlight,
  },
  data: () => ({
    api,
    fileChosen: false,
    formAgree: false,
    formSuccessMessage: false,
    formErrorMessage: false,
    isFormLoading: false,
    form: {
      name: null,
      email: null,
      subject: null,
      message: null,
      file: null,
    },
    validations: {
      name: {
        required: true,
        alpha_spaces: true
      },
      email: {
        required: true,
        email: true
      },
      subject: {
        required: true
      },
      message: {
        required: true
      },
      file: {
        required: false
      },
      agree: {
        required: true
      }
    }
  }),
  methods: {
    async submit() {
      const valid = await this.$validator.validate()
      if (valid) {
        this.isFormLoading = true
        const response = await this.api.contact(this.formData)
        if (response && response.data && response.data.success) {
          this.isFormLoading = false
          this.formSuccessMessage = true
        } else if (response && response.response) {
          this.isFormLoading = false
          this.formErrorMessage = true
        }
      }
    },
    fileUploaded() {
      this.form.file = this.$refs.fileInput.files[0]
      this.fileChosen = true
    }
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    formData() {
      var data = new FormData()
      Object.entries(this.form).forEach(([key, value]) =>
        data.append(key, value)
      )
      return data
    },
  }
}
</script>

<style lang="scss" scoped>

@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.btn-choose-file {
  display: none;
}

.lh-privacy {
  line-height: 1.2rem;
}

.height {
  min-height: 18.75rem;
}

.card-position {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  bottom: 23%;
  width: 18.75rem;
  margin: 0.625rem;

  &.lisbon {
    bottom: 43%;
  }

  @include media-breakpoint-down(md) {
    width: 65%;
    bottom: 1.25rem;
    left: 3.625rem;
    margin: 0;
    height: auto;

    &.lisbon {
      bottom: 1.4rem;
    }
  }
}

</style>
