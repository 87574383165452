const state = {
  sideMenu: false
}

const getters = {
  sideMenu: (state) => state.sideMenu
}

const actions = {
  openMenu: ({ commit }) => {
    commit('OPEN_MENU')
  },
  closeMenu: ({ commit }) => {
    commit('CLOSE_MENU')
  }
}

const mutations = {
  OPEN_MENU: (state) => {
    state.sideMenu = true
    document.documentElement.style.overflow = 'hidden'
  },
  CLOSE_MENU: (state) => {
    state.sideMenu = false
    document.documentElement.style.overflow = 'auto'
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}