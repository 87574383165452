<template>
    <div class="container-fluid" v-if="!isLoading">
        <div class="row px-offset justify-content-center">
            <div class="col-12 col-md-8">
                <p class="text-primary fs-24">{{ policy.title | localize }}</p>
                <p v-html="translate(this.policy.summary)" ></p>
            </div>
        </div>
    </div>
</template>

<script>
import policiesApi from '@/api/policies'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
    mixins: [
        metaMixin
    ],
    data: () => ({
        policiesApi,
        isLoading: true,
        policy: null
    }),
    created () {
        this.init()
    },
     beforeEnter(to, from, next) {
        next()
        this.init()
    },
    methods: {
        async init () {
            const response = await this.policiesApi.all(this.filters)
            if (response && response.data && response.data.list) {
                if (response.data.list.length > 0) {
                    this.policy = response.data.list[0]
                    this.isLoading = false
                }
            } else {
                this.$router.push({ name: 'notFound' })
            }
        },
        translate(value) {
            return this.$options.filters.localize(value)
        },
    },
    computed: {
        filters() {
            return  {
                page: this.page.id
            }
        },
        ...mapGetters({
            pages: 'pages/pages'
        }),
        page() {
            return this.pages.find(page => page.route === this.$route.name)
        },
    }
}
</script>
<style lang="scss">
@import '~@vars';

</style>