export default {
    locales: {
        pt: 'Português',
        en: 'Inglês'
    },
    backoffice: {
        edit: {
            created: 'Adicionado {com} sucesso',
            updated: 'Atualizado {title} com sucesso'
        },
        error: {
            unknown: 'Ocorreu um erro desconhecido'
        }
    },
    logo_sepri_image_alt: 'Grupo SEPRI',
    logo_sepri_image_title: 'Grupo SEPRI',
    logo_norte2020_image_alt: 'Norte 2020',
    logo_norte2020_image_title: 'Norte 2020',
    internet_explorer: {
        title: 'Browser não suportado',
        description: 'Por favor utilize o Google Chrome, Firefox ou Microsoft Edge para aceder ao website.'
    },
    mobile: {
        title: 'Brevemente',
        description: 'A versão mobile do nosso site encontra-se em manutenção, iremos lança-lo o mais rápido possível, fique atento.'
    },
    header: {
        search: 'Pesquisa',
        client_area: 'Área de Cliente',
        platforms: 'Plataformas',
        list: [
            {
                title: 'Área de Cliente',
                link: 'https://sepri.safemed.pt',
            },
            {
                title: 'Gestão Interna',
                link: 'https://www.sepri.pt/gestaointerna/'
            },
            {
                title: 'Training Login',
                link: 'https://www.training.sepri.pt/login/'
            }
        ]
    },
    footer: {
        all_rights_reserved: 'Todos os direitos reservados.',
        developed_by: 'Desenvolvido por',
        informations: 'Informações',
        follow_us: 'Siga-nos',
        image_title: 'Certificados',
        image_alt: 'Certificados',
        linkedin_image_title: 'Linkedin',
        linkedin_image_alt: 'Linkedin',
        facebook_image_title: 'Facebook',
        facebook_image_alt: 'Facebook',
        instagram_image_title: 'Instagram',
        instagram_image_alt: 'Instagram',
        subscribe: 'Subscrever',
        newsletter: 'Subscrever a Newsletter',
        email: 'Email',
        agree: 'Aceita receber comunicações do Grupo Sepri. Mais informações consulte a <a href="https://www.sepri.pt/pt/politica-privacidade">Política de Privacidade</a>.',
        newsletter_success: 'Subscrição feita com sucesso',
        newsletter_error: 'Ocorreu um erro ao processar a sua subscrição.'
    },
    home: {
        know_more: 'Saiba Mais',
        work_safety_health_know_more: 'Conheça aqui o que podemos fazer pela sua empresa',
        environmental_studies_laboratory_know_more: 'Conheça aqui os nossos serviços',
        accident_medicine_know_more: 'Conheça aqui os nossos serviços de assistência em caso de sinistro',
        health_services_24_know_more: 'Conheça aqui os nossos serviços de saúde',
        healthcare_consulting_know_more: 'Conheça aqui os nossos serviços internacionais',
        see_more: 'Ver Mais',
        see_all: 'Ver Todos',
        contact_us: 'Contacte-nos',
        navigation: {
            header: 'Home',
            sepri: 'Valores',
            solutions: 'Saúde e Bem-estar nas Empresas',
            testimonials: 'Testemunhos',
            featured: 'Destaques'
        },
        sepri: {
            title: 'Com Saúde, Higiene e Segurança',
            subtitle: 'criamos Empresas Saudáveis',
            know_more: 'Saiba mais sobre a nossa história',
            list: [
                {
                    title: 'serviço',
                    summary: 'Entendemos e concebemos o nosso serviço em função das necessidades dos clientes, com altos padrões de conhecimento e profissionalismo.',
                    image_title: 'Serviço',
                    image_alt: 'Serviço'
                },
                {
                    title: 'excelência',
                    summary: 'Esforçamo-nos diariamente em perseguir a excelência, procurando acrescentar valor ao nosso cliente e superar as suas expetativas.',
                    image_title: 'Excelência',
                    image_alt: 'Excelência'
                },
                {
                    title: 'paixão',
                    summary: 'Temos paixão pelo trabalho que desenvolvemos, porque acreditamos ser a chave do sucesso.',
                    image_title: 'Paixão',
                    image_alt: 'Paixão'
                },
                {
                    title: 'respeito',
                    summary: 'Acreditamos que o respeito e a confiança são valores essenciais nas relações com o cliente e equipa de trabalho.',
                    image_title: 'Respeito',
                    image_alt: 'Respeito'
                },
                {
                    title: 'inovação',
                    summary: 'Promovemos uma lógica de inovação e desenvolvimento, através do espírito de iniciativa para a criação de novos conceitos de negócio e melhores serviços.',
                    image_title: 'Inovação',
                    image_alt: 'Inovação'
                },
            ],
            numbers: [
                {
                    number: '30',
                    description: 'Anos de experiência'
                },
                {
                    number: '1200',
                    description: 'Parceiros globais'
                },
                {
                    number: '180',
                    description: 'Colaboradores a nível Mundial'
                }
            ]
        },
        featured: {
            title: 'Destaques',
            description: 'Acompanhe todas as novidades do Grupo SEPRI',
            news: 'Notícia'
        },
        about: {
            list : [
                {
                    title: 'Sinta a diferença de gerir uma Empresa Saudável',
                    summary: 'Porque devo implementar um Programa de Saúde e Bem-estar na minha Empresa?',
                    id: 0,
                },
                {
                    title: 'Produtividade',
                    summary: 'Sabia que trabalhadores fisicamente mais ativos são 12% mais produtivos? Ou que por cada € investido num programa de saúde e bem-estar terá um retorno, a longo prazo, de 2€ a 4€?',
                    id: 1,
                },
                {
                    title: 'Competitividade',
                    summary: 'Sabia que as doenças mentais são responsáveis por 500.000 faltas diárias em todo o mundo? Ou que a taxa de absentismo anual de um colaborador saudável é de 1.7 dias enquanto que a de um colaborador diabético é de 8.3 dias? Com a ajuda da SEPRI, assista a uma diminuição no número de baixas da sua Empresa.',
                    id: 2,
                },
                {
                    title: 'Satisfação',
                    summary: 'Aumentar os níveis de satisfação dos seus colaboradores é testemunhar como a segurança e bem-estar dos seus colaborares aliado à felicidade, conforto e realização pessoal, é fulcral para o bem-estar da sua empresa.',
                    id: 3,
                },
                {
                    title: 'Comunidade',
                    summary: 'Potencie a imagem da sua empresa demonstrando à sua comunidade, as melhorias contínuas que alcançou desde a adoção do Sistema Integrado de Gestão de Empresas Saudáveiss.',
                    id: 4,
                },
            ]
        }
    },
    testimonials: {
        title: 'As Empresas que Tornamos Saudáveis',
        summary_home: 'Os nossos clientes assumiram um papel proactivo para melhorar a saúde e o bem-estar dos seus colaboradores e hoje dirigem uma empresa saudável',
        summary_solutions: 'Os nossos clientes assumiram um papel proactivo para melhorar a saúde e o bem-estar dos seus colaboradores e hoje dirigem uma empresa saudável',
        summary_work_safety_health: 'Os níveis de stress, depressão e ansiedade nos colaboradores são reais. Sabia que a doença mental é a segunda maior causa de absentismo laboral? Faça como os nossos clientes, faça a prevenção e coloque a sua empresa num caminho saudável.',
        summary_health_services_24: 'A segurança e o bem-estar dos seus colaboradores, apoiam-se num serviço de saúde de excelência. Na SEPRI 24 contribuímos, diariamente, para o futuro da sua empresa saudável, veja os nossos casos de sucesso.',
        summary_accident_medicine: 'Assuma um papel proactivo para melhorar a saúde e o bem-estar dos seus colaboradores, evitando a ocorrência de acidentes de trabalho. Este é o momento de colocar a sua empresa num futuro saudável. Veja como os nossos clientes o fizeram.',
        summary_environmental_studies_laboratory: 'Assuma um papel proactivo para melhorar a saúde e o bem-estar dos seus colaboradores, evitando a ocorrência de acidentes de trabalho. Este é o momento de colocar a sua empresa num futuro saudável. Veja como os nossos clientes o fizeram!',
        summary_healthcare_consulting: 'Conheça os testemunhos dos nossos clientes e saiba como é possível caminhar globalmente para um futuro Saudável.',
    },
    careers: {
        title_1: 'Junte-se a uma equipa de sucesso,',
        title_2: 'junte-se à SEPRI',
        area: 'Área Profissional',
        country: 'País',
        clear_filters: 'Limpar Filtros',
        apply: 'Candidatar',
        apply_title: 'Seja um {job} da SEPRI',
        careers: 'Carreiras',
        careers_summary: 'Subscreva aqui para receber notificações das nossas últimas ofertas de emprego',
        spontaneous_application: 'Candidatura Espontânea',
        spontaneous_application_success: 'Subscrição efetuada com sucesso. Irá receber um e-mail quando publicarmos uma posição que lhe interesse.',
        spontaneous_application_summary: 'Caso não encontre uma posição com que se identifique, pode também submeter uma candidatura espontânea.',
        subscribe: 'Subscrever',
        area_required: 'É necessário selecionar pelo menos uma área',
        form: {
            name: 'Nome',
            phone: 'Telefone',
            email: 'Email',
            place: 'Localidade',
            message: 'Mensagem',
            cv: 'CV',
            choose_file: 'Escolha um Ficheiro',
            motivation_letter: 'Carta de Motivação (Sem Anexo Escolhido)',
            cv_file: 'CV (Sem Anexo Escolhido)',
            unknown: 'Não foi possível realizar o seu pedido, pedimos desculpa pelo incómodo.',
            success: 'Candidatura efetuada com sucesso. Entraremos em contacto consigo em breve.',
            newsletter: 'Aceita receber comunicações do Grupo Sepri para envio de informação sobre novas vagas segundo a sua área de interesse. Mais informações consulte a Politica de Privacidade',
            agree_missing: 'Aceitar receber notificações é obrigatório para prosseguir',
            rgpd: 'Por meio de apresentação da informação abaixo, o Grupo SEPRI (SEPRI Medicina no Trabalho, Lda. NIPC: 03786730, SEPRI 24-Serviços de Saúde, Lda. NIPC: 502592710, SEPRI MS - Medicina Em Sinistrados, Lda. NIPC 507134338), sede na Rua Dr. Loureiro de Amorim n.º 7, 4710-487 Braga, enquanto responsável pelo tratamento de dados, dá cumprimento ao dever de informação ao candidato, em matéria de tratamento dos dados pessoais, nos termos que segue:<br/> A recolha dos dados constantes neste documento abrange um processo de diligências pré-contratuais.<br/> Os dados serão enviado via ligação cifrada para a nossa infraestrutura digital e tratados pelo Departamento de Recursos Humanos, e eventualmente, por responsáveis de outros departamentos de interesse e serão mantidos durante um ano, findo este período os seus dados serão eliminados. Então, teremos todo o gosto em receber nova candidatura. Em caso de admissão os dados pessoais irão integrar o processo individual sendo mantidos durante o tempo legalmente exigido.<br/> O titular dos dados pode exercer os direitos de: acesso, de retificação, de apagamento, de limitação do tratamento, de portabilidade, de oposição, de reclamação para autoridade de controlo - Comissão Nacional de Proteção de Dados e de ser informado em caso de violação de dados, por meio de contacto direto com a Sepri, ou de contactar o Encarregado de Proteção de Dados através do email dpo@sepri.pt',
        },
        subscription: {
            title: 'Gerir Notificações de Carreiras',
            subheading: 'em baixo pode rever e editar as preferências dos emails com as últimas ofertas de emprego',
            hello: 'Olá',
            email: 'Email',
            edit_email: 'Editar Email',
            unsubscribe: 'Cancelar Subscrição',
            save: 'Guardar Alterações',
            unsubscribe_title: 'Subscrição cancelada',
            unsubscribe_subheading: 'O seu email foi removido da nossa lista de contactos com sucesso.',
            unknown: 'Não foi possível realizar o seu pedido, pedimos desculpa pelo incómodo.',
            success_edit: 'Informações editadas com sucesso.',
            delete_account: 'Subscrição cancelada com sucesso.',
        }
    },
    media: {
        share: 'partilhar',
    },
    sepri: {
        know_us: {
            title_1: 'Profissionais capazes e qualificados!',
            summary_1: 'A SEPRI é constituída pelos melhores especialistas, especialmente treinados em ambientes de trabalho saudáveis e absolutamente determinados e comprometidos em providenciar o serviço de excelência que a sua saúde merece.',
            image_title_1: 'Colaboradores SEPRI',
            image_alt_1: 'Colaboradores SEPRI',
            title_2: 'Venha trabalhar connosco',
            summary_2_1: 'Na SEPRI trabalhamos diariamente para providenciar ao nosso cliente o melhor serviço de mercado. Valorizamos o rigor técnico, a partilha de conhecimento e entreajuda nas equipas, a autonomia e responsabilidade de cada um, mas acima de tudo, valorizamos a felicidade dos nossos colaboradores, sabemos que só assim podemos criar Empresas Saudáveis.',
            summary_2_2: 'Se desejar fazer parte de uma equipa versátil, rigorosa e bastante determinada, submeta já a sua candidatura.',
            image_title_2: 'Colaboradores SEPRI',
            image_alt_2: 'Colaboradores SEPRI',
        },
        discover_us: {
            title: 'Descubra o que nos diferencia',
            summary: 'Trabalhar na SEPRI é saber que a nossa voz é ouvida, que cada um de nós tem algo único a acrescentar. É sentir felicidade naquilo que fazemos. Comprove o nosso dia-a-dia.',
            image_title: 'Colaboradores SEPRI',
            image_alt: 'Colaboradores SEPRI',
        },
        timeline: {
            title: 'A história do nosso sucesso',
            summary: 'Acompanhe a evolução do Grupo SEPRI desde a sua criação.',
        },
        world_map: {
            title: 'A SEPRI no Mundo',
            summary: 'Começamos a acompanhar os nossos clientes no mundo em 2008 e, desde então, não paramos! Investimos nas relações com os nossos clientes e, por isso, a contínua expansão internacional é e será um dos nossos principais objetivos. A SEPRI é o parceiro de saúde que a sua organização merece em qualquer parte do mundo.',
            image_title: 'SEPRI no Mundo',
            image_alt: 'SEPRI no Mundo',
        },
        about: {
            title_1: 'Com Saúde, Higiene e Segurança criamos',
            title_2: 'Empresas Saudáveis',
            summary: 'Ano após ano procuramos alcançar um crescimento sustentado, consolidando novas oportunidades de negócio e excelentes profissionais em diferentes áreas. Respondemos às necessidades de cada cliente, acrescentando valor pelo conjunto de serviços que disponibilizamos. Somos uma referência inconfundível no mercado.',
            mission: 'Missão',
            mission_summary: 'Partilhar o nosso conhecimento e manter uma relação de proximidade com o CLIENTE, promovendo de forma estruturada o desenvolvimento de um sistema de gestão de riscos, capaz de prevenir ou minimizar os efeitos resultantes dos riscos laborais.',
            mission_image_title: 'Missão',
            mission_image_alt: 'Missão',
            vision: 'Visão',
            vision_summary: 'Pretendemos ser reconhecidos como uma empresa de referência internacional, na criação de EMPRESAS SAUDÁVEIS. Neste sentido pretendemos acompanhar os nossos clientes, em todo o mundo, como o parceiro de saúde que merecem.',
            vision_image_title: 'Visão',
            vision_image_alt: 'Visão',
        },
        team: {
            title: 'Conheça os nossos líderes do presente que guiam os líderes do futuro'
        }
    },
    contacts: {
        form: {
            title: 'Formulário',
            name: 'Nome',
            email: 'Email',
            subject: 'Assunto',
            message: 'Mensagem',
            choose_file: 'Escolha um Ficheiro',
            attach: 'Anexo (Sem Anexo Escolhido)',
            unknown: 'Não foi possível realizar o seu pedido, pedimos desculpa pelo incómodo.',
            success: 'Mensagem enviada com sucesso. Entraremos em contacto consigo em breve.',
            submit: 'Submeter',
            required: 'Campos Obrigatórios',
            newsletter: 'Aceita receber comunicações do Grupo Sepri em resposta ao pedido efetuado?*',
            privacy: 'O Grupo Sepri assume um compromisso de privacidade e segurança das informações pessoais dos seus contactos. As nossas Bases de Dados são utilizadas exclusivamente pelo Grupo Sepri e não serão cedidas a quaisquer terceiros. A qualquer momento poderá fazer opt-out desta base de dados através do contacto geral@sepri.pt. Para mais informações consulte a nossa Política de Privacidade.',
            agree_missing: 'Aceitar receber notificações é obrigatório para prosseguir',
            rgpd: 'Os seus dados irão ser utilizados pelo Grupo Sepri, apenas para concretizar o seu pedido de contacto e serão conservados durante o tempo mínimo necessário para cumprir com esse objetivo. Para mais informações, consulte a nossa <a href="https://www.sepri.pt/pt/politica-privacidade">Política de Privacidade</a>',
        },
        email_label: 'Email: ',
        email: ' geral@sepri.pt',
        phone_label: 'Geral: ',
        phone: '253 271 525',
        phone_home_label: 'Domicílios: ',
        phone_home: '917 597 297',
        phone_lisbon: '212 693 999',
        email_lisbon: 'lisboa@sepri.pt',
        call_tax_disclosure: {
            'mobile': ' (custo rede móvel nacional)',
            'phone': ' (custo rede fixa nacional)',
        }
    },
    work_safety_health: {
        logo_image_title: 'Segurança e Saúde no Trabalho',
        logo_image_alt: 'Segurança e Saúde no Trabalho',
        header_list: [
            {
                title: 'O que fazemos?',
                summary: 'A SEPRI MT atua na área de saúde ocupacional, através da inclusão e promoção de ferramentas chave que lhe permitem oferecer maior qualidade de vida aos seus colaboradores no seu local de trabalho. Após um diagnóstico inicial às organizações, elaboramos e implementamos um plano de ação no âmbito da segurança e saúde, focado nas necessidades dos seus colaboradores e da sua empresa.',
                image_title: 'O que fazemos',
                image_alt: 'O que fazemos',
                image_id: 0,
            },
            {
                title: 'Como fazemos?',
                summary: 'Encaramos cada novo cliente como um desafio e, por isso, críamos uma relação de proximidade e compromisso com todos, oferecendo um serviço personalizado. Apoiamos a implementação do conceito de empresa saudável, estando em contacto constante com todos os nossos clientes.',
                image_title: 'Como fazemos',
                image_alt: 'Como fazemos',
                image_id: 1,
            },
            {
                title: 'O que nos distingue?',
                summary: 'A SEPRI MT conta com mais de 20 anos de experiencia e já mudámos o dia-a-dia de mais de 1000 empresas. Apoiamo-nos no know-how e multidisciplinariedade da nossa equipa que nos permite uma rápida adaptação a qualquer tipo de mercado ou área de trabalho. Estes fatores combinados com os valores do grupo e com os nossos sistemas de gestão de empresas saudáveis, originam uma atuação única na área da saúde ocupacional em Portugal.',
                image_title: 'O que nos distingue',
                image_alt: 'O que nos distingue',
                image_id: 2,
            }
        ],
        partners: {
            title: 'Parcerias',
            summary: 'Na SEPRI MT sabemos que relações de parceria com distintas entidades são importantes para a nossa evolução e consequente melhoria dos serviços oferecidos. Por isso, valorizamos parcerias sólidas que resultam em projetos inovadores no setor.',
        },
        featured: {
            title: 'Destaques',
            description: 'Acompanhe todas as novidades do Grupo SEPRI',
        },
        know_more: 'Saiba Mais',
    },
    environmental_studies_laboratory: {
        body: [
            {
                paragraph: 'Na SEPRI LAB monitorizamos a envolvente do local de trabalho, através de estudos técnicos ocupacionais e ambientais, que nos permitem determinar os níveis de exposição da sua empresa a determinados fatores de risco físicos. Trabalhamos diariamente na sua proteção e prevenção.',
            },
            {
                paragraph: 'O nosso foco é a identificação e prevenção de falhas e riscos contingenciais, assim a SEPRI LAB apoia a sua empresa na melhoria da segurança e do bem-estar dos seus colaboradores. Sabia que estes fatores refletem diretamente nos níveis de produtividade de uma empresa? Solicite um diagnóstico inicial e coloque a sua empresa num futuro saudável!',
            }
        ],
        logo_image_title: 'Laboratório de Estudos Ambientais',
        logo_image_alt: 'Laboratório de Estudos Ambientais',
        values: {
            title: 'O que nos distingue na SEPRI LAB?',
            image_title: 'Colaborador SEPRI',
            image_alt: 'Colaborador SEPRI',
            list: [
                {
                    item: '- A integração direta dos dados extraídos, entre a SEPRI LAB e a equipa de higiene e segurança no trabalho'
                },
                {
                    item: '- O apoio total da SEPRI LAB nos períodos de inspeções técnicas legais'
                },
                {
                    item: '- A célere adaptabilidade a todos os mercados de trabalho'
                },
                {
                    item: '- A inclusão de equipamentos tecnológicos vanguardistas na medição e quantificação de dados extraídos'
                },
                {
                    item: '- Uma equipa vasta que se diferencia pelo rigor e exigência técnica, incutida por analistas experientes e qualificados'
                }
            ],
        },
        contact: {
            title: 'Entre em contacto',
            summary_1: 'Para qualquer pedido de informações ou esclarecimento pode sempre entrar em contacto connosco através do e-mail indicado abaixo.',
            summary_2: 'Responderemos com a maior brevidade possível.'
        }
    },
    accident_medicine: {
        logo_image_title: 'Medicina Sinistrados',
        logo_image_alt: 'Medicina Sinistrados',
        body: [
            {
                paragraph: 'Surgimos com o objetivo primordial de garantir uma capacidade de ação eficiente, na sua relação de parceria com as empresas seguradoras.',
            },
            {
                paragraph: 'Na SEPRI MS distinguimo-nos pela qualidade do serviço prestado e garantimos que todos os sinistrados são tratados pelos melhores profissionais, dotados de anos de experiência no terreno, rápida adaptabilidade e resposta a qualquer situação de sinistro ou ocorrência.',
            }
        ],
        values: {
            title: 'Porque deve contactar a SEPRI MS em caso de sinistro?',
            list: [
                {
                    item: '- Agilização instantânea do processo pelo cross-selling de serviços entre a SEPRI MS e SEPRI MT'
                },
                {
                    item: '- Gestão e tratamento direto dos sinistrados com as seguradoras'
                },
                {
                    item: '- Caracterização do acidente e gestão dos dias de incapacidade'
                },
                {
                    item: '- Registo contínuo do índice de sinistralidade da sua empresa'
                },
                {
                    item: '- Otimização do serviço prestado numa via rápida e eficaz'
                },
                {
                    item: '- Parcerias sólidas com elevado nº de seguradoras'
                },
            ],
            image_title: 'Medicina Sinistrados',
            image_alt: 'Medicina Sinistrados',
        },
        insurers: {
            title: 'Seguradoras protocoladas',
            summary: 'Com o intuito de melhorar o serviço da SEPRI MS aos nossos clientes, distinguimo-nos pelos protocolos agilizados com as maiores seguradoras nacionais.',
        }
    },
    health_services_24: {
        logo_image_title: 'Serviços de Saúde 24',
        logo_image_alt: 'Serviços de Saúde 24',
        big_image_title: 'Serviços de Saúde 24',
        big_image_alt: 'Serviços de Saúde 24',
        small_image_title: 'Serviços de Saúde 24',
        small_image_alt: 'Serviços de Saúde 24',
        resume: 'Com a SEPRI 24 poderá contar sempre com um serviço médico diferenciador. Somos muito mais do que uma Clínica Médica. Comprove por si!',
        summary: [
            {
                paragraph: 'O que nos distingue na SEPRI 24? Não só garantimos um serviço de qualidade 24h, 365 dias por ano, como o cumprimos. Em qualquer circunstância, a SEPRI 24 estará lá para si. Saiba como!',
            },
        ],
        list: [
            {
                title: 'Serviço de Enfermagem 24h',
                summary: [
                    {
                        paragraph: 'Garantimos um serviço de enfermagem 24h, a qualquer dia ou hora, quando precisar, a SEPRI 24 terá sempre à sua disposição uma equipa de enfermagem à sua espera, com a qualidade de tratamento que nos é associada, após 30 anos de trabalho diferenciador.',
                    },
                ]
            },
            {
                title: 'Serviço de Domicílios Médicos',
                summary: [
                    {
                        paragraph: 'Com o nosso serviço de domicílios médicos, poderá contar com acompanhamento constante da nossa equipa médica, sem sair de casa! Basta uma chamada e a SEPRI 24 estará aí para o ajudar, sem necessidade deslocamento a clínicas e hospitais.',
                    },
                    {
                        paragraph: 'Poupe a sua saúde aos riscos de contaminação nestes locais.',
                    }
                ]
            },
            {
                title: 'Serviço de apoio a Sinistrados',
                summary: [
                    {
                        paragraph: 'Para as empresas temos a vantagem de agilizar e apoiar os processos de sinistrados com a nossa parceira SEPRI MS, para que os seus colaboradores, em caso de sinistro, tenham acesso a um tratamento eficaz sempre aliado ao profissionalismo e rigor da nossa equipa médica.',
                    }
                ]
            },
        ]
    },
    services: {
        title: 'Os nossos serviços',
        summary_work_safety_health: ' Conheça os serviços de excelência da SEPRI MT que irão potenciar o bem-estar dos seus colaboradores e a produtividade da sua empresa.',
        summary_accident_medicine: 'Na SEPRI MS apoiamos a sua empresa em caso de ocorrência de sinistros. Conheça os nossos serviços e saiba como podemos ajudar.',
        summary_environmental_studies_laboratory: 'Conheça os serviços de excelência da SEPRI LAB, focados na quantificação de dados extraídos de casos reais, para apoio aos relatórios de Segurança. A SEPRI LAB contribui, diariamente, para evitar que as ocorrências e os riscos de trabalho na sua empresa ocorram.',
        summary_healthcare_consulting: 'Apresentamos total capacidade para responder aos níveis de exigência dos serviços, em mercados internacionais bastante competitivos. Em Portugal ou no mundo os serviços de excelência da SEPRI estarão lá para si!',
        summary_health_services_24: 'A confiança que nos depositam transparece para a nossa Clínica Médica. Conheça aqui, todos os serviços de excelência que disponibilizamos nas nossas instalações.',
    },
    healthcare_consulting: {
        logo_image_title: 'Healthcare & Consulting',
        logo_image_alt: 'Healthcare & Consulting',
        title: 'Setores onde fazemos a diferença',
        list: [
            {
                item: 'Empresas de Construção',
            },
            {
                item: 'Empresas de Logística',
            },
            {
                item: 'Hospitais',
            },
            {
                item: 'Petrolíferas',
            },
            {
                item: 'Empresas de Energia',
            },
            {
                item: 'Empresas Mineiras',
            },
            {
                item: 'Empresas de Telecomunicações',
            },
            {
                item: 'Instituições Governamentais',
            },
        ],
        image_title: 'Healthcare & Consulting',
        image_alt: 'Healthcare & Consulting',
        image_subtitle: 'A SEPRI HCC é atualmente líder de mercado, crescemos continuamente e somos a maior empresa portuguesa a prestar serviços de consultoria, medicina ocupacional, formação e treino em emergência médica a nível internacional.',
        know_more: 'Saiba Mais',
    },
    solutions: {
        summary: 'Conheça o nosso conjunto de soluções integradas, que lhe permitem criar um ambiente de trabalho saudável, para si e para os seus colaboradores. Caminhe com a SEPRI para um futuro melhor.',
        featured: {
            title: 'Destaques',
            description: 'Acompanhe todas as novidades do Grupo SEPRI',
        },
    },

}
