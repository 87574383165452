<template>
  <div>
    <b-form v-if="!loading">
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Geral
            </b-card-header>
            <b-card-body>
              <b-tabs
                no-nav-style
                nav-class="border-right border-light h-100"
                nav-wrapper-class="p-0"
                class="mx-0"
                active-nav-item-class="font-weight-bolder border-right text-secondary border-secondary"
                content-class="py-5 w-100 bg-white"
                vertical
              >
                <b-tab
                    v-for="locale in locales"
                    :key="locale"
                    :title="$t(`locales.${locale}`)"
                >
                  <pages-form :locale="locale" />
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Adicional
            </b-card-header>
            <b-card-body>
              <b-form-group label="Menu superior">
                <b-checkbox v-model="item.is_header" />
              </b-form-group>
              <b-form-group label="Menu Inferior">
                <b-checkbox v-model="item.is_footer" />
              </b-form-group>
              <b-form-group label="Posição">
                <b-input
                  v-model="item.order"
                  type="number"
                />
              </b-form-group>
            </b-card-body>
          </b-card>
          <b-card v-if="isSolution">
            <b-card-header>
              Serviços
            </b-card-header>
            <b-card-body>
              <services />
            </b-card-body>
          </b-card>
          <b-card v-if="hasPolicy">
            <b-card-header>
              Conteúdo Dinâmico
            </b-card-header>
            <b-card-body>
              <policy />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          class="justify-content-end d-flex"
        >
          <b-button
            @click.prevent.stop="submit"
            variant="primary"
            class="mt-3"
          >
            Submeter
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <loading />
    </div>
  </div>
</template>

<script>
import loading from '@/components/backoffice/common/loading'
import pagesForm from '@/components/backoffice/modules/pages/form'
import services from '@/components/backoffice/modules/pages/services'
import policy from '@/components/backoffice/modules/pages/policy'
import model from '@/models/pages'
import { actions } from '@/mixins/edit'
import api from '@/api/pages'
import policyApi from '@/api/policies'

export default {
  mixins: [actions],
  components: {
    loading,
    pagesForm,
    services,
    policy
  },
  data: () => ({
    api,
    policyApi,
    model: JSON.parse(JSON.stringify(model.empty)),
    rules: model.rules,
  }),
  computed: {
    hasMedia () {
      return this.item && this.item.file && this.item.file !== '' && !(this.item.file instanceof Blob)
    },
    isSolution () {
      return this.item.is_solution
    },
    hasPolicy () {
      return this.item.route === 'privacy-policy'
    }
  },
  created () {
    this.init ()
  },
  methods: {
    async init () {
      if (this.$route.params.id) {
        await Promise.all([this.getItem()])
      } else {
        this.initForm(this.model)
      }
      this.loading = false
    },
    async submit () {
      if (this.isCreate) {
        await this.submitPageData()
        this.$router.push({ name: this.$route.name.replace('create', 'edit'), params: { id: response.data.id } })
      } else {
        await Promise.all([ this.submitPageData(), this.submitPolicyData() ])
      }
    },
    async submitPageData () {
      this.formData = await this.formatAsFormData(this.item)

      const response = await this.api[this.isCreate ? 'create' : 'update'](this.formData)

      if (response && response.data) {
        const message = this.isCreate ? 'backoffice.edit.created' : 'backoffice.edit.updated'
        this.$toasted.success(this.$t(message, { title: response.data.title ? this.translate(response.data.title) : response.data.name }))

      } else {
        if (response.response && response.response.data && response.response.data.errors) {
          this.$toasted.error(Object.values(response.response.data.errors).join(' '))
        } else {
          this.$toasted.error(this.$t('backoffice.error.unknown'));
        }
      }
    },
    async submitPolicyData () {
      const formData = await this.formatAsFormData(this.relationshipItem)
      const response = await this.policyApi[this.isCreate ? 'create' : 'update'](formData)
    }
  }
}
</script>