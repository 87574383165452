<template>
  <div class="container-fluid">
    <appHighlight :page="page"/>
    <appHeader :page="page" />
    <div class="row justify-content-center my-10">
      <div class="text-center">
        <img
          src="assets/images/logos/healthcare_consulting.svg"
          class="img-logo"
          :alt="$t('healthcare_consulting.logo_image_alt')"
          :title="$t('healthcare_consulting.logo_image_title')"
        />
      </div>
    </div>
    <appServices v-if="!isLoadingServices" :services="services" />
    <div class="d-none d-lg-block">
      <div class="row justify-content-center pb-20">
        <div class="col-4 px-10">
          <div class="row h-70">
            <div class="col">
              <img
                src="assets/images/healthcare-consulting/sectors.jpg"
                class="w-100 h-100"
                :alt="$t('healthcare_consulting.image_alt')"
                :title="$t('healthcare_consulting.image_title')"
              />
            </div>
          </div>
          <div class="row h-30 fs-22 text-secondary font-weight-bold pt-5">
            <div class="col">{{ $t('healthcare_consulting.image_subtitle') }}</div>
          </div>
        </div>
        <div class="col-4 py-10 px-10 shadow mr-10">
          <p
            class="fs-52 text-uppercase text-blue-4 font-weight-light line-height-normal"
          >{{ $t('healthcare_consulting.title') }}</p>
          <p
            v-for="(item, key) in $t('healthcare_consulting.list')"
            :key="`item ${key}`"
            class="fs-22 text-secondary"
          >
            <i class="anticon icon-right text-primary fs-22 font-weight-bold pr-3" />
            {{ item.item }}
          </p>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div class="row px-offset">
        <div class="col-12 p-0">
          <p
            class="fs-52 text-uppercase text-blue-4 font-weight-light line-height-normal text-center"
          >{{ $t('healthcare_consulting.title') }}</p>
          <p
            v-for="(item, key) in $t('healthcare_consulting.list')"
            :key="`item ${key}`"
            class="fs-22 text-secondary"
          >
            <i class="anticon icon-right text-primary fs-22 font-weight-bold pr-3" />
            {{ item.item }}
          </p>
        </div>
      </div>
      <div class="row px-offset">
        <div class="col-12 p-0">
          <img
            src="assets/images/healthcare-consulting/sectors.jpg"
            class="w-100 h-100"
            :alt="$t('healthcare_consulting.image_alt')"
            :title="$t('healthcare_consulting.image_title')"
          />
        </div>
        <div class="col-12 p-0">
          <p
            class="fs-22 text-secondary font-weight-bold pt-5 text-center"
          >{{ $t('healthcare_consulting.image_subtitle') }}</p>
        </div>
      </div>
    </div>
    <appTestimonials />
  </div>
</template>

<script>
import apiServices from '@/api/services'
import appServices from '@/components/frontoffice/common/services'
import appTestimonials from '@/components/frontoffice/common/testimonials'
import appHeader from '@/components/frontoffice/healthcare-consulting/header'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  data: () => ({
    apiServices,
    isLoadingServices: true,
    services: []
  }),
  components: {
    appHeader,
    appServices,
    appTestimonials,
    appHighlight,
  },
  created() {
    this.init()
  },
  methods: {
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    init() {
      this.getServices()
    },
    async getServices() {
      const response = await this.apiServices.all(this.filtersServices)
      if (response && response.data && response.data.list) {
        this.services = response.data.list
        this.isLoadingServices = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersServices() {
      return {
        skip: 0,
        take: 7,
        sort: 'order,asc',
        page: this.page.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.img-logo {
  height: 10rem;
}
</style>