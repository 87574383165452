<template>
  <div v-if="!isLoading" class="row px-offset justify-content-center m-0 mb-10">
    <div v-if="!unsubscribe" class="col-12 col-lg-6 py-10 px-10 border rounded">
      <h1
        class="text-primary text-center pb-5 fs-40 font-weight-bold"
      >{{ $t('careers.subscription.title') }}</h1>
      <p
        class="text-grey-2 fs-16 pb-5"
      >{{ $t('careers.subscription.hello') }} {{this.user.email}}, {{ $t('careers.subscription.subheading') }}</p>
      <div>
        <p class="text-grey-2 fs-14">{{ $t('careers.subscription.email') }}</p>
        <div class="border border-grey-6 d-flex">
          <input
            v-validate="{required: true, email: true}"
            name="email"
            v-model="email"
            :placeholder="user.email"
            type="email"
            class="fs-14 p-3 flex-grow-1 border-0"
          />
          <b-button
            @click.stop="editEmail"
            class="fs-14 px-10 shadow-none bg-grey-4 text-grey-1 rounded-0 border-0"
          >{{ $t('careers.subscription.edit_email') }}</b-button>
        </div>
        <span class="fs-12 text-primary">{{ veeErrors.first('email') }}</span>
      </div>
      <div class="pt-5 pb-10">
        <p class="text-grey-2 fs-14">{{ $t('careers.area') }}</p>
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="row">
              <div v-for="area in areasList" :key="area.id" class="col-6 text-left py-1">
                <b-checkbox
                  v-model="selected"
                  :value="area.id"
                  class="text-14 text-grey-2"
                >{{ area.title | localize }}</b-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <b-button
          @click.stop="removeSubscription"
          class="bg-grey-4 border border-grey-6 shadow-none text-grey-1"
        >{{ $t('careers.subscription.unsubscribe') }}</b-button>
        <b-button
          @click.stop="editInformation(false)"
          class="bg-primary shadow-none ml-10"
        >{{ $t('careers.subscription.save') }}</b-button>
      </div>
    </div>
    <div v-else class="col-12 col-lg-6 py-10 px-10 border rounded">
      <h1
        class="text-primary text-center pb-5 fs-40 font-weight-bold"
      >{{ $t('careers.subscription.unsubscribe_title') }}</h1>
      <p class="text-grey-2 fs-16 pb-10">{{ $t('careers.subscription.unsubscribe_subheading') }}</p>
    </div>
  </div>
</template>


<script>
import areasApi from '@/api/areas'
import subscribersApi from '@/api/subscribers'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  data: () => ({
      areasApi,
      subscribersApi,
      isLoading: true,
      selected: [],
      email: null,
      areasList: null,
      user: null,
      unsubscribe: false
  }),
  created() {
      this.init()
  },
  methods: {
      async init() {
          await Promise.all([this.getSubscriberInformation(),this.getAreas()])
          this.isLoading = false
      },
      async getAreas() {
          const response = await this.areasApi.all({ sort:`title->pt,asc` })
          if (response && response.data && response.data.list) {
              this.areasList = response.data.list
          }
      },
      async getSubscriberInformation() {
          const response = await this.subscribersApi.subscriber(this.$route.params.key)
          if (response && response.data) {
              this.user = response.data
              this.selected = response.data.areas.map(area => area.id)
              this.user.areas = this.selected.join(',')
          } else {
              this.$router.push({ name: 'home' })
          }
      },
      async editEmail() {
          const valid = await this.$validator.validate();

          if (valid) {
              this.editInformation(true)
          }
      },
      async editInformation(email) {
          this.isLoading = true
          const data = email ? this.userEmail : this.userData
          const response = await this.subscribersApi.userUpdate(data)

          if (response && response.data) {
              this.makeToast(this.$t('careers.subscription.success_edit'))
          } else {
              this.makeToast(this.$t('careers.subscription..unknown'), 'error')
          }
          this.isLoading = false
      },
      async removeSubscription() {
          this.isLoading = true
          const response = await this.subscribersApi.unsubscribe(this.user.key)
          if (response && response.data) {
              this.makeToast(this.$t('careers.subscription.delete_account'))
              this.unsubscribe = true
          } else {
              this.makeToast(this.$t('careers.subscription.unknown'), 'error')
          }
          this.isLoading = false
      },
      makeToast(message, state = 'success') {
          this.$toasted.show(message, { type : state })
      }
  },
  computed: {
      userData() {
          const email = this.email && this.email.length > 0 ? this.email : this.user.email
          return { key: this.user.key, email: email, areas: this.selected.join(',') }
      },
      userEmail() {
          return { key: this.user.key, email: this.email, areas: this.user.areas }
      },
  }
}
</script>