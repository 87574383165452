<template>
  <div v-if="!isLoading">
    <div class="d-none d-md-block">
      <div class="row px-offset vh-body">
        <div class="col position-relative">
          <div class="row justify-content-end">
            <div class="col-10">
              <b-carousel
                ref="carouselAbout"
                class="carousel-about"
                :interval="3000"
                indicators
                @sliding-start="setSlide"
              >
                <b-carousel-slide
                  v-for="banner in  banners"
                  :key="banner.id"
                  :img-src="banner.file"
                  :img-alt="banner.file | localize"
                  :img-title="banner.file | localize"
                />
              </b-carousel>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-6 d-flex justify-content-end fs-14 pt-3">
              <div
                v-for="(banner, index) in  banners"
                :key="index"
                @click.stop="setSlide(index)"
                class="d-flex slide-navigation px-5 cursor-pointer"
              >
                {{ banner.title | localize }}
                <div v-if="activeSlide(index)" class="line"></div>
              </div>
            </div>
          </div>

          <div class="row position-absolute position-footer w-100 height-resume">
            <div class="col-md-6 col-lg-4 bg-white text-right pt-10 pr-10">
              <p
                class="fs-34 font-weight-bold text-primary text-uppercase"
              >{{ getTitle | localize }}</p>
              <p class="fs-16 text-secondary font-weight-light pb-0">{{ getSummary | localize }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none">
      <div class="row px-offset">
        <div class="col-12 p-0">
          <div class="row">
            <div class="col-12">
              <b-carousel
                ref="carouselAboutMobile"
                class="carousel-about"
                :interval="3000"
                indicators
                @sliding-start="setSlide"
              >
                <b-carousel-slide
                  v-for="banner in  banners"
                  :key="banner.id"
                  :img-src="banner.file"
                  :img-alt="banner.file | localize"
                  :img-title="banner.file | localize"
                />
              </b-carousel>
            </div>
          </div>
          <div class="row px-offset m-0 z-100 position height">
            <div class="col-12 bg-white text-center">
              <div class="row justify-content-around align-items-center">
                <div class="col-12 py-5 text-center">
                  <p
                    class="fs-34 font-weight-bold text-primary text-uppercase mb-1"
                  >{{ getTitle | localize }}</p>
                  <p class="fs-16 text-secondary font-weight-light pb-0">{{ getSummary | localize }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import apiBanners from '@/api/banners'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    apiBanners,
    isLoading: true,
    index: 0,
    banners: [],
  }),
  created() {
    this.getBanners()
  },
  methods: {
    async getBanners() {
      const response = await this.apiBanners.all(this.filtersBanners)
      if (response && response.data && response.data.list) {
        if (response.data.list.length > 0) {
          this.banners = response.data.list
          this.isLoading = false
        }
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    setSlide(index) {
      this.index = index
      this.$refs.carouselAbout.setSlide(this.index)
    },
    activeSlide(index) {
      return this.index === index
    },
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersBanners() {
      return  {
        page: this.page.id
      }
    },
    getIndex() {
      return this.index
    },
    getTitle() {
      return this.banners[this.index].title
    },
    getSummary() {
      return this.banners[this.index].summary
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@vars';

.position-footer {
  bottom: 8rem;
}

.position {
  transform: translateY(-20px);
}

.height {
  height: 12rem;
} 

.height-resume {
   height: 16rem;
}

.line {
  border-right: 0.125rem solid $primary;
  border-bottom: 0.125rem solid $primary;
  height: 3.75rem;
  width: 0.625rem;
  margin-top: -2.8125rem;
  margin-left: 0.3125rem;
}

.slide-navigation {
  color: $grey-1;
  &.active {
    color: $primary;
    font-weight: $font-weight-bolder;
  }
}
</style>

<style lang="scss">
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.carousel-about .carousel-indicators {
  bottom: 0%;
}

.carousel-about .carousel-indicators li {
  background-color: $grey-1;
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;

  @include media-breakpoint-down(sm) {
      height: 0.4375rem;
      width: 0.4375rem;
  }
}

.carousel-about .carousel-indicators .active {
  background-color: $primary;
}

.carousel-about .carousel-inner .carousel-item > img{
  height: calc(80vh * 0.9);

  @include media-breakpoint-down(sm) {
      height: 18.75rem;
  }

  @include media-breakpoint-only(md) {
      height: $h-body-md;
  }
}
</style>