import { mapGetters } from 'vuex'

export default {
    data: () => ({
        metaTitle: null,
        metaDescription: null,
        metaKeywords: null
    }),
    created() {
        this.getMetaInformation()
    },
    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.metaKeywords },
                { property: 'og:title', content: this.metaTitle },
                { property: 'og:description', content: this.metaDescription },
                { property: 'og:url', content: window.location.origin + window.location.pathname },
                { property: 'twitter:title', content: this.metaTitle },
                { property: 'twitter:description', content: this.metaDescription },
            ]
        }
    },
    methods: {
        getMetaInformation() {
            this.metaTitle = this.translate(this.page.meta_title)
            this.metaDescription = this.translate(this.page.meta_description)
            this.metaKeywords = this.translate(this.page.meta_keywords)
        },
        translate(value) {
            return this.$options.filters.localize(value)
        },
    },
    computed: {
        ...mapGetters({
            pages: 'pages/pages'
        }),
        page() {
            return this.pages.find(page => page.route=== this.$route.name)
        },
    }
}