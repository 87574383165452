<template>
  <div class="row px-offset py-10" v-if="hasNews">
    <div class="col">
      <p class="text-grey-2 text-center fs-26 font-weight-bold">{{ $t(`${route}.featured.title`) }}</p>
      <p class="text-grey-1 text-center fs-16">{{ $t(`${route}.featured.description`) }}</p>

      <div class="row">
        <div class="col-12 py-5 px-0" v-for="item in newsList" :key="item.id">
          <img
            :src="item.file"
            class="img-size"
            :alt="item.title | localize"
            :title="item.title | localize"
          />
          <div class="px-4">
            <router-link
              :to="getRoute(item)"
              class="text-decoration-none fs-20 font-weight-bold text-grey-2 max-lines-2 my-3"
            >{{ item.title | localize }}</router-link>
            <p class="fs-14 text-grey-1 max-lines-3">{{ item.summary | localize }}</p>
            <router-link :to="getRoute(item)" class="fs-16 text-primary text-decoration-none font-weight-bold">
              {{ $t('home.know_more') }}
              <i class="anticon icon-arrowright fs-16 ml-3 font-weight-bold"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row justify-content-center pt-4">
        <div class="col-6 text-center">
          <router-link
            :to="getRouteMedia"
            class="fs-16 btn btn-outline-dark text-uppercase text-center text-decoration-none"
          >{{ $t('home.see_all') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiNews from '@/api/news'
import { mapGetters } from 'vuex'

export default {
   props: {
    news: {
      type: Array,
      required: true
    }
  },
  data: () => ({
  }),
  created() {
  },
  methods: {
    getRoute(news) {
      return {
        name: this.pageNews.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.pageNews.url), slug: this.translate(news.slug) }
      }
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    newsList() {
        return this.news.slice(0, 3)
    },
    hasNews() {
      return this.newsList.length > 0
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    pageNews() {
      return this.pages.find(page => page.route === 'news')
    },
    getRouteMedia() {
      return {
        name: this.pageMedia.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.pageMedia.url) }
      }
    },
    pageMedia() {
      return this.pages.find(page => page.route === 'media')
    },
    route() {
      const route = this.$route.name
      return route.replace(/-/g, '_')
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.img-size {
    width: 100%;
    height: 15.625rem;
}
</style>