<template>
  <div>
    <template v-if="loading">
      <loading />
    </template>
    <b-row v-else>
      <b-col>
        <b-card>
          <b-card-header>
            <list-actions :fields="fields" :per-page="perPage" :sort-by="sortBy" :sort-direction="sortDirection"
              :total="totalRows" create-route="admin-contact-create" @updatePerPage="updatePerPage"
              @updateVisibleFields="updateVisibleFields" @updateSortListBy="updateSortListBy"
              @removeSelectedItems="removeSelectedItems" />
          </b-card-header>
          <b-card-body>
            <b-table ref="table" :items="items" :fields="visibleFields" :per-page="perPage" :current-page="1" borderless
              striped responsive>
              <template slot="HEAD_checkbox">
                <b-checkbox @change="toggleAllSelectedItems" />
              </template>
              <template slot="checkbox" slot-scope="data">
                <b-checkbox v-model="data.item.selected" />
              </template>
              <template v-for="field in fields" :slot="field.field" slot-scope="data">
                <span v-if="field.translatable" :key="field.field">
                  {{ data.item[field.field] }}
                </span>
                <span v-else :key="field.field">
                  {{ data.item[field.field] }}
                </span>
              </template>
              <template slot="actions" slot-scope="data">
                <table-actions update-route="admin-contact-edit" :data="data" @removeItem="removeItem"
                  @recoverItem="recoverItem" :fields="fields" @updateVisibleFields="updateVisibleFields"/>
              </template>
            </b-table>
          </b-card-body>
          <b-card-footer>
            <p class="mt-3">Página: {{ currentPage }}</p>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="getList" />
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import loading from '@/components/backoffice/common/loading'
import listActions from '@/components/backoffice/common/listActions'
import tableActions from '@/components/backoffice/common/tableActions'
import model from '@/models/contact'
import { actions } from '@/mixins/list'
import api from '@/api/contact'


export default {
  mixins: [actions],
  data: () => ({
    items: null,
    api: api,
    fields: model.fields,
    visibleFields: [],
    sortBy: 'id',
    sortDirection: 'desc'
  }),
  created() {
    this.init()
  },
  components: {
    loading,
    listActions,
    tableActions
  },
  methods: {
    async init() {
      this.loading = true
      Promise.all([this.updateVisibleFields(), this.getList()])
    },
  }
}

</script>
  