<template>
    <div id="sib-form-container" class="sib-form-container">
        <div id="sib-container bg-transparent py-0">
            <form
                id="sib-form"
                ref="sib-form"
                method="POST"
                accept-charset="utf-8"
                :action="url"
                data-type="subscription"
                class="d-flex justify-content-center align-items-center"
            >
                <div tag="div" class="w-100">
                <label
                  class="fs-newsletter text-secondary text-uppercase font-weight-newsletter text-center text-lg-left pb-0 mb-5 w-100"
                  for="EMAIL"
                >
                  {{ $t('footer.newsletter') }}
                </label>
                  <div class="d-flex w-100">
                    <div class="form__entry px-0 w-100">
                      <input
                          v-validate="{required: true, email: true}"
                          v-model="form.email"
                          id="EMAIL"
                          name="EMAIL"
                          type="email"
                          class="newsletter_email input border border-grey-6 p-1 flex-grow-1 w-100"
                          autocomplete="off"
                          :placeholder="`*${$t('footer.email')}`"
                          :data-vv-as="$t('footer.email')"
                      />
                        <label class="entry__error entry__error--primary" />
                    </div>
                    <button
                        class="btn fs-14 text-white text-uppercase font-weight-light bg-primary px-5 px-md-10 rounded-0 border-0 shadow-none"
                        form="sib-form"
                        type="submit"
                        id="submit"
                        @click.stop="handleSubmitForm"
                    >
                        <svg
                            class="progress-indicator sib-hide-loader-icon mr-2 h-100 d-none"
                            viewBox="0 0 512 512"
                        >
                            <path
                                d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"
                            />
                        </svg>
                        <span class="w-100">
                            {{ $t("footer.subscribe") }}
                        </span>
                    </button>
                  </div>
                  <span v-if="veeErrors.first('EMAIL')" class="text-primary fs-12 d-flex justify-content-start">
                    {{ veeErrors.first("EMAIL") }}
                  </span>
                    <div class="text-left-cb mt-2">
                  <input
                    type="checkbox"
                    v-model="form.agree"
                    v-validate="{required: true}"
                    class="fs-12 text-secondary"
                    id="agree"
                    name="agree"
                  />
                  <span class="fs-12 text-secondary" v-html="$t('footer.agree')"></span>
                </div>
                <div v-if="veeErrors.first('agree')">
                  <span
                    class="text-primary fs-12 d-flex justify-content-start"
                  >{{ $t('careers.form.agree_missing') }}</span>
                </div>
              </div>
            </form>
        </div>
        <div
            id="error-message"
            class="sib-form-message-panel alert alert-dange mt-2"
        >
            <div class="sib-form-message-panel__text">
                <span class="sib-form-message-panel__inner-text" />
                {{ $t("footer.newsletter_error") }}
            </div>
        </div>
        <div
            id="success-message"
            class="sib-form-message-panel alert alert-success mt-2"
        >
            <div class="sib-form-message-panel__text">
                <span class="sib-form-message-panel__inner-text" />
                {{ $t("footer.newsletter_success") }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        scriptUrl: "https://sibforms.com/forms/end-form/build/main.js",
        form: {
            email: null,
            agree: false
        }
    }),
    computed: {
        url() {
            return "https://b90e996e.sibforms.com/serve/MUIEANatThxdaf51B5ampQttNepPR9_KecFWbwsxUZuELzQfPZcNRE7W64Cze6I0ALQhPziyi_qafFO44jL6xleOR3YbcT0w7YJAmQErwaMPDHv_r72KBnJCRODon54LrQ2xYP5ud9_nSjD8aD2fj1gfrAfbCvUFPsD7gIOWPz0icPyu_xRjdC8yJK0IPDiDu8Y3hy5shTVis9-2";
        },
        hasSendinblueScript() {
            return (
                document.querySelectorAll(`script[src="${this.scriptUrl}"]`).length > 0
            )
        },
        isFormInvalid() {
          console.log(this.veeErrors)
          return this.veeErrors.items.length > 0 || !(Object.keys(this.veeFields).some(key => this.veeFields[key].dirty))
        }
    },
    mounted() {
        if (!this.hasSendinblueScript) {
            const script = document.createElement("script")
            script.defer = true
            script.onload = this.loaded
            script.type = "text/javascript"
            script.src = this.scriptUrl
            document.body.appendChild(script)
        }
    },
    methods: {
        loaded() {
            this.window.GENERIC_INVALID_MESSAGE = "";
            this.window.EMAIL_INVALID_MESSAGE = "";
            this.window.REQUIRED_ERROR_MESSAGE = this.$t("newsletter.required");
        },
        async handleSubmitForm(e) {
            const valid = await this.$validator.validate()
            if (!valid) {
              e.preventDefault()     
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.submit {
    height: 2.72rem;
    letter-spacing: 1.45px;
}

.progress-indicator {
    display: inline-block;
    fill: white;
    height: 1.25rem;
    width: 1.25rem;
    animation: indicator-spin 1.3s cubic-bezier(0.46, 0.35, 0.39, 0.85) infinite;
}

.entry__error,
.input_replaced {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.sib-form-message-panel {
    display: none;

    &--active {
        display: block;
    }

    &__inner-text {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
}

.sib-hide-loader-icon,
input[name="email_address_check"] {
    display: none;
}

@keyframes indicator-spin {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}

.text-left-cb {
  text-align: left !important;
}

.fs-newsletter {
  font-size: $fs-14 !important;
  @include media-breakpoint-down(lg) {
    font-size: $fs-20 !important;
  }
}

.font-weight-newsletter {
  font-weight: $font-weight-bolder !important;
  @include media-breakpoint-down(lg) {
    font-weight: $font-weight-bold !important;
  }
}
</style>
