<template>
  <div class="row bg-image bg-cover px-offset py-10 py-md-30 justify-content-between">
    <div class="col-12 col-md-5">
      <div class="bg-white rounded text-center pt-10 pt-md-20 pb-10 px-2 px-md-10 h-100">
        <p class="text-primary fs-40 font-weight-bold">{{ $t('careers.careers') }}</p>
        <p class="text-grey-2 fs-16">{{ $t('careers.careers_summary') }}</p>

        <div v-if="formSuccessMessage">
          <p class="fs-14 text-center">{{ $t('careers.spontaneous_application_success') }}</p>
        </div>
        <div v-if="formErrorMessage">
          <b-alert class="fs-14 d-flex" show variant="warning">
            <span class="anticon icon-warning d-flex align-items-center fs-24 mr-4" />
            {{ errorMessage }}
          </b-alert>
        </div>
        <b-form v-if="!isFormSent" class="w-100">
          <div class="border border-grey-6 d-flex">
            <input
              type="text"
              class="fs-14 p-3 flex-grow-1 border-0 text-grey-2"
              name="email"
              id="email"
              :placeholder="`*${$t('careers.form.email')}`"
              :data-vv-as="$t('careers.form.email')"
              v-validate="validations.email"
              v-model="form.email"
            />
            <b-button
              @click.stop="submit"
              class="fs-14 px-5 px-md-10 shadow-none bg-grey-4 text-grey-1 rounded-0 border-0"
            >{{ $t('careers.subscribe') }}</b-button>
          </div>
          <span
            v-if="veeErrors.first('email')"
            class="text-primary fs-12 d-flex justify-content-start"
          >{{ veeErrors.first("email") }}</span>
          <div class="pt-5">
            <multiselect
              v-model="form.areas"
              :options="options"
              :multiple="true"
              :searchable="false"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="false"
              label="name"
              track-by="name"
              :preselect-first="false"
              :placeholder="$t('careers.area')"
            ></multiselect>
          </div>
          <span
            v-if="!isAreaSelected && trySubmit"
            class="text-primary fs-12 d-flex justify-content-start"
          >{{ $t('careers.area_required') }}</span>
        </b-form>
      </div>
    </div>
    <div class="col-12 col-md-5 pt-5 pt-md-0">
      <div class="bg-white rounded text-center pt-10 pt-md-20 pb-10 px-2 px-md-10 h-100">
        <p class="text-primary fs-40 font-weight-bold">{{ $t('careers.spontaneous_application') }}</p>
        <p class="text-grey-2 fs-16">{{ $t('careers.spontaneous_application_summary') }}</p>
        <b-button
          class="btn mt-5 shadow-none text-white bg-primary fs-22 border border-primary px-10"
          @click.stop="applySpontaneous()"
        >{{ $t('careers.apply') }}</b-button>
      </div>
    </div>
    <spontaneousModal ref="modal" />
  </div>
</template>

<script>
import apiAreas from '@/api/areas'
import spontaneousModal from '@/components/frontoffice/careers/spontaneousModal'
import subscribersApi from '@/api/subscribers'

export default {
  components: {
    spontaneousModal,
  },
  data: () => ({
    subscribersApi,
    areas: [],
    options: [],
    apiAreas,
    trySubmit: false,
    isFormLoading: false,
    formSuccessMessage: false,
    formErrorMessage: false,
    errorMessage: null,
    form: {
      email: null,
      areas: []
    },
    validations: {
      email: {
        required: true,
        email: true
      }
    }
  }),
  props: {
    job: {
      type: Object,
      required: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.apiAreas.all()
      if (response && response.data && response.data.list) {
        this.areas = response.data.list
        this.options = this.areas.map(area => ({...area, name: this.translate(area.title)}) )
        this.isLoading = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    async submit() {
      this.trySubmit = true;
      const valid = await this.$validator.validate()

      if (valid && this.isAreaSelected) {
        this.isFormLoading = true
        const response = await this.subscribersApi.create(this.formatForm)
        if(response && response.data) {
          this.formSuccessMessage = true
          this.isFormLoading = false
        } else if (response && response.response ){
          this.formErrorMessage = true
          this.isFormLoading = false
          this.errorMessage = response.response.data.errors.email[0] === 'validation.unique' ? this.$t('formMessage.EXISTING_EMAIL') : this.$t('formMessage.UNKNOWN')  
        }
      }
    },
    applySpontaneous() {
      this.$bvModal.show('modal-spontaneous')
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    isAreaSelected() {
        return this.form.areas.length > 0
    },
    formatForm() {
        return { email: this.form.email, areas: this.form.areas.map(area => area.id).toString() }
    },
    isFormSent() {
        return this.formSuccessMessage || this.formErrorMessage || this.isFormLoading
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.bg-image {
  background-image: url(/assets/images/common/testimonials/background.jpg);
}
</style>

<style lang="scss">
@import '~@vars';

.multiselect__placeholder {
  color: $grey-1;
}
</style>