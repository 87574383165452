<template>
  <div>
    <b-form v-if="!loading">
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Geral
            </b-card-header>
            <b-card-body>
              <b-tabs
                no-nav-style
                nav-class="border-right border-light h-100"
                nav-wrapper-class="p-0"
                class="mx-0"
                active-nav-item-class="font-weight-bolder border-right text-secondary border-secondary"
                content-class="py-5 w-100 bg-white"
                vertical
              >
                <b-tab
                    v-for="locale in locales"
                    :key="locale"
                    :title="$t(`locales.${locale}`)"
                >
                  <areas-form :locale="locale" />
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Adicional
            </b-card-header>
          </b-card>
        </b-col>
        <b-col
          class="justify-content-end d-flex"
        >
          <b-button
            @click.prevent.stop="submit"
            variant="primary"
            class="mt-3"
          >
            Submeter
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <loading />
    </div>
  </div>
</template>

<script>
import loading from '@/components/backoffice/common/loading'
import areasForm from '@/components/backoffice/modules/areas/form'
import model from '@/models/areas'
import { actions } from '@/mixins/edit'
import api from '@/api/areas'
import pagesApi from '@/api/pages'

export default {
  mixins: [actions],
  components: {
    loading,
    areasForm,
  },
  data: () => ({
    api,
    pagesApi,
    model: JSON.parse(JSON.stringify(model.empty)),
    rules: model.rules,
    pages: [],
  }),
  created () {
    this.init ()
  },
  methods: {
    async init () {
      if (this.$route.params.id) {
        await Promise.all([this.getItem(), this.getPages()])
      } else {
        this.initForm(this.model)
        await this.getPages()
      }
      this.loading = false
    },
    async getPages () {
      try {
        const response = await this.pagesApi.all({ isSolution: true })
        if (response.data) {
          this.pages = response.data.list.map(item => ({ text: item.title.pt, value: item.id }))
        } else {
          console.error('Response data not found', response)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    }
  }
}
</script>