<template>
  <div class="row px-offset mb-10 mb-lg-20">
    <div class="col px-lg-20">
      <div class="row pb-10 justify-content-center">
        <div class="col-12 col-lg-6 text-center">
          <p
            class="fs-40 text-grey-2 font-weight-bold text-capitalize"
          >{{ $t('accident_medicine.insurers.title') }}</p>
          <p class="fs-14 text-grey-1">{{ $t('accident_medicine.insurers.summary') }}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-xl-8 p-0">
          <div class="partners__squares">
            <div
              v-for="partner in partners"
              :key="partner.id"
              class="partners__square d-flex align-items-center justify-content-center shadow bg-white"
            >
              <img
                :src="partner.file"
                class="img-size"
                :alt="partner.title | localize"
                :title="partner.title | localize"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    partners: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.img-size {
  height: 4rem;
  @include media-breakpoint-down(lg) {
    height: 2rem;
  }
}

.partners {
  height: 100vh;
  min-height: 37.5rem;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    height: auto;
    min-height: unset;
  }

  &__squares {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__square {
    &::before {
      content: "";
      padding-bottom: 100%;
      display: inline-block;
      vertical-align: top;
    }
  }
}
</style>