export default {
    sections: [
        {
            icon: 'assets/images/home/navigation/eye.svg',
            name: 'header'
        },
        {
            icon: 'assets/images/home/navigation/nose.svg',
            name: 'sepri'
        },
        {
            icon: 'assets/images/home/navigation/hand.svg',
            name: 'solutions'
        },
        {
            icon: 'assets/images/home/navigation/ear.svg',
            name: 'testimonials'
        },
        {
            icon: 'assets/images/home/navigation/mouth.svg',
            name: 'featured'
        },
    ]
}