<template>
  <div id="sepri" class="vh-md-100 d-flex flex-column justify-content-between">
    <div class="row h-md-20 px-offset justify-content-center">
      <div class="col py-5 text-center">
        <h1 class="mb-0 fs-md-44 fs-26 text-primary text-uppercase">{{ $t('home.sepri.title') }}</h1>
        <p class="fs-md-44 fs-26 text-primary text-uppercase mb-0">{{ $t('home.sepri.subtitle') }}</p>
      </div>
    </div>
    <div class="row h-md-60 align-items-center justify-content-center px-offset">
      <div class="col-lg-12 col-xl-11 h-100 text-center">
        <div class="row h-75 align-items-center flex-nowrap flex-wrap-sm">
          <div class="col">
            <div class="row align-items-start">
              <div
                v-for="(item, key) in $t('home.sepri.list')"
                :key="`item ${key}`"
                class="col-md col-12 text-md-left text-center bg-character py-3 py-md-0"
                :style="backgroundCharacter(item.title)"
              >
                <img
                  :src="sourceSolutionImage(item)"
                  class="img-fluid img-size pb-5"
                  :alt="item.image_alt"
                  :title="item.image_title"
                />
                <p class="text-secondary fs-20 text-uppercase font-weight-bolder">{{ item.title }}</p>
                <div class="fs-16 text-grey-1 ">{{ item.summary }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row h-md-25 align-items-center my-12 my-md-0">
          <div class="col">
            <router-link
              :to="getRoute"
              class="p-2 text-white bg-primary fs-14 text-decoration-none rounded px-sm-6 px-md-2 py-4 py-md-2"
            >{{ $t('home.sepri.know_more') }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row h-md-20 bg-image justify-content-center py-5 py-md-8">
      <div
        v-for="(number, key) in $t('home.sepri.numbers')"
        :key="`number ${key}`"
        class="col-md-3 col-12 text-center align-self-center py-3 py-md-0"
      >
        <p class="text-white fs-52 font-weight-bolder m-0 line-height-numbers">{{ number.number }}</p>
        <p class="text-white fs-14 text-uppercase m-0 font-weight-bold">{{ number.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    firstCharacter(title) {
      return title.charAt(0).toUpperCase()
    },
    backgroundCharacter(title) {
      return (
        `background-image: url(/assets/images/home/sepri/${this.firstCharacter(title)}.svg)`
      )
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
    sourceSolutionImage(item) {
      return `assets/images/home/sepri/icon_${this.firstCharacter(item.title)}.svg`
    }
  },
  computed: {
    getRoute() {
      return {
        name: this.page.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.page.url) }
      }
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === 'sepri')
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@vars';
@import "~@bootstrap-functions";
@import "~@bootstrap-variables";
@import "~@bootstrap-mixins";

.bg-image {
  background-image: url(/assets/images/home/sepri/background.jpg);
  background-size: auto 100%;
}

.bg-blue {
  background-color: rgba($primary, 0.5);
}

.bg-character {
  background-repeat: no-repeat;
  background-position: 0rem 3.5rem;

  @include media-breakpoint-down(sm) {
    background-position: center;
  }
}

.line-height-numbers {
  line-height: $fs-58;
}

.img-size {
  height: 4.5rem;
}
</style>
