<template>
  <div class="d-none d-md-inline-block position-fixed border-secondary px-4 py-2 fs-16 font-weight-bolder text-uppercase border-style bg-white letter-spacing-3 z-100">
      <router-link
        :to="getRoute"
        class="text-decoration-none"
      >{{ $t('home.contact_us') }}</router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    getRoute() {
      return {
        name: this.pageContacts.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.pageContacts.url)}
      }
    },
    pageContacts() {
      return this.pages.find(page => page.route === 'contacts')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';
@import "~@bootstrap-functions";
@import "~@bootstrap-variables";
@import "~@bootstrap-mixins";

div {
  top: 5.725rem;
  right: 0rem;

  @include media-breakpoint-down(md) {
    top: 3.725rem;
  }
}

.border-style {
  border-width: 0.125rem;
  border-style: solid;
  border-right-style: hidden;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.letter-spacing-3 {
  letter-spacing: 0.1875rem;
}

a {
  color: $secondary;
  &:hover {
    color: $secondary;
  }
}

</style>
