import api from '@/api'

export default {
  all: (filter) => api('get', '/subscriber', filter),
  create: (data) => api('post', '/subscriber', data),
  show: (id) => api('get', `/subscriber/${id}`),
  delete: (id) => api('delete', `/subscriber/${id}`),
  update: (data) => api('put', `/subscriber/${data.get('id')}`, data),
  subscriber: (key) => api('get', `/subscriber/${key}/get`),
  userUpdate: (data) => api('post', `/subscriber/${data.key}/update`, data),
  unsubscribe: (key) => api('delete', `/subscriber/${key}/unsubscribe`)
}