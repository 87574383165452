import Vue from 'vue'

const state = {
  fields: {},
  relationshipFields: {},
  relations: []
}

const getters = {
  fields: state => state.fields,
  relationshipFields: state => state.relationshipFields,
  related: state => state.related,
}

const actions = {
  async init({ commit }, fields) {
    commit('INIT', fields)
  },
  async reset({ commit }) {
    commit('RESET')
  },
  async initRelationship({ commit }, relationshipFields) {
    commit('INIT_RELATIONSHIP', relationshipFields)
  },
  async resetRelationship({ commit }) {
    commit('RESET_RELATIONSHIP')
  },
  async initRelated({ commit }, related) {
    commit('INIT_RELATED', related)
  },
  async resetRelated({ commit }) {
    commit('RESET_RELATED')
  }

}

const mutations = {
  INIT: (state, fields) => {
    Vue.set(state, 'fields', fields)
  },
  RESET: state => {
    Vue.set(state, 'fields', {})
  },
  INIT_RELATIONSHIP: (state, relationshipFields) => {
    Vue.set(state, 'relationshipFields', relationshipFields)
  },
  RESET_RELATIONSHIP: state => {
    Vue.set(state, 'relationshipFields', {})
  },
  INIT_RELATED: (state, related) => {
    Vue.set(state, 'related', related)
  },
  RESET_RELATED: state => {
    Vue.set(state, 'related', [])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
