<template>
  <div
    v-if="!loading"
    class="w-100"
  >
    <b-row>
      <b-col
        class="d-flex justify-content-end"
      >
        <b-button
          v-b-modal.edit-modal
          class="mb-5"
        >
          Novo
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="edit-modal"
      ref="edit-modal"
      hide-header
      centered
      size="lg"
      body-class="p-0"
      ok-title="Submeter"
      cancel-title="Cancelar"
      @ok="addService"
      @hide="resetService"
    >
      <b-tabs
        no-nav-style
        nav-class="border-right border-light h-100"
        nav-wrapper-class="p-0"
        class="mx-0"
        active-nav-item-class="font-weight-bolder border-right text-secondary border-secondary"
        content-class="py-5 w-100 bg-white"
        vertical
      >
        <b-tab
          v-for="locale in locales"
          :key="locale"
          :title="$t(`locales.${locale}`)"
        >
          <service-form :locale="locale" />
        </b-tab>
      </b-tabs>
    </b-modal>
    <mapTree
      :api="api"
    >
      <template v-slot:identifier="{ node }">
        <span>{{ node.title.pt }}</span>
      </template>
      <template v-slot:actions="{ node }">
        <tab-table-actions
          :id="node.id"
          @editItem="openEditModal"
          @removeItem="removeService"
        />
      </template>
    </mapTree>
  </div>
</template>

<script>
import serviceForm from '@/components/backoffice/modules/pages/service'
import tabTableActions from '@/components/backoffice/common/tabTableActions'
import model from '@/models/services'
import api from '@/api/services'
import mapTree from '@/components/backoffice/common/map'
import { locales } from '@/constants/settings'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    serviceForm,
    tabTableActions,
    mapTree
  },
  data: () => ({
    api,
    locales,
    loading: true,
    fields: model.fields,
    empty: JSON.parse(JSON.stringify(model.empty)),
  }),
  computed: {
    ...mapGetters({
      page: 'form/fields',
      model: 'form/relationshipFields',
      services: 'form/related'
    }),
    isCreate () {
      return this.model && this.model.id
    }
  },
  created () {
    this.init()
  },
  beforeDestroy () {
    this.resetRelationship()
  },
  methods: {
    ...mapActions({
      initRelationship: 'form/initRelationship',
      resetRelationship: 'form/resetRelationship',
      initRelated: 'form/initRelated',
      resetRelated: 'form/resetRelated',
    }),
    async init () {
      this.resetService()
      await Promise.all([ this.loadServices() ])
      this.loading = false
    },
    async resetService () {
      this.initRelationship({ ...JSON.parse(JSON.stringify(this.empty)), ...{ page_id: this.page.id } })
    },
    async loadServices () {
      try {
        const response = await this.api.all({ page: this.page.id })

        if (response && response.data && response.data.list) {
          this.initRelated(response.data.list)
        } else {
          this.$toasted.error(this.$t('errors.failed'))
        }
      } catch (e) {
        console.error(e)
      }
    },
    async addService () {
      try {
        var data = new FormData()
        Object.entries(this.model).forEach(([key, value]) => data.append(key, typeof value === 'object' ? JSON.stringify(value) : value));

        const response = await this.api[this.isCreate ? 'update' : 'create'](data)

        if (response && response.data && response.data) {
          this.$toasted.success(this.$t(this.isUpdateForm ? 'backoffice.edited' : 'backoffice.created'))
          this.resetService()
          this.loadServices()
        } else if (response && response.response && response.response.data && response.response.data.errors) {
          this.$toasted.error(Object.values(response.response.data.errors).join(' '))
        } else {
          this.$toasted.error(this.$t('backoffice.errors.unknown'))
        }

      } catch (e) {
        console.error(e)
      }
    },
    async removeService (id) {
      try {
        const response = await this.api.delete(id)
        if (response.data) {
          this.$toasted.success(this.$t('backoffice.deleted'))
          this.loadServices()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async openEditModal (id) {
      this.initRelationship(this.services.find(item => item.id === id))
      this.$refs['edit-modal'].show()
    }
  }
}
</script>
