<template>
  <div class="container-fluid">
    <appHighlight :page="page"/>
    <appHeader :page="page" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-8 text-center px-lg-20">
        <p
          v-for="(paragraph, key) in $t('environmental_studies_laboratory.body')"
          :key="`paragraph ${key}`"
          class="fs-14 text-brown-2"
        >{{ paragraph.paragraph }}</p>
      </div>
    </div>
    <div class="row">
      <div clas="col">
        <div class="row px-offset my-10 mx-0 mx-lg-20">
          <div class="col-12 col-lg-6 p-0">
            <img
              src="assets/images/environmental-studies-laboratory/image.jpg"
              class="w-100 h-100"
              :alt="$t('environmental_studies_laboratory.values.image_alt')"
              :title="$t('environmental_studies_laboratory.values.image_title')"
            />
          </div>
          <div class="col-12 col-lg-6 p-0 bg-brown-1">
            <div class="row h-100">
              <div class="col pt-20 px-10 pb-15">
                <p
                  class="fs-40 text-white font-weight-bold"
                >{{ $t('environmental_studies_laboratory.values.title') }}</p>
                <p
                  v-for="(item, index) in $t('environmental_studies_laboratory.values.list')"
                  :key="index"
                  class="fs-14 text-white"
                >{{ item.item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appServices v-if="!isLoadingServices" :services="services" />
    <appContact />
    <appTestimonials />
  </div>
</template>

<script>
import apiServices from '@/api/services'
import appServices from '@/components/frontoffice/common/services'
import appTestimonials from '@/components/frontoffice/common/testimonials'
import appHeader from '@/components/frontoffice/environmental-studies-laboratory/header'
import appContact from '@/components/frontoffice/environmental-studies-laboratory/contact'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  data: () => ({
    apiServices,
    isLoadingServices: true,
    services: [],
    selected: []
  }),
  components: {
    appHeader,
    appServices,
    appTestimonials,
    appHighlight,
    appContact,
  },
  created() {
    this.init()
  },
  methods: {
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    init() {
      this.getServices()
    },
    async getServices() {
      const response = await this.apiServices.all(this.filtersServices)
      if (response && response.data && response.data.list) {
        this.services = response.data.list
        this.isLoadingServices = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersServices() {
      return {
        sort: 'order,asc',
        page: this.page.id
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.button-text {
  color: rgba($grey-2, 0.6);
}
</style>