import usersList from '@/views/backoffice/users/list'
import usersEdit from '@/views/backoffice/users/edit'

export default [
  {
    path: 'users/create',
    name: 'admin-users-create',
    component: usersEdit
  },
  {
    path: 'users',
    name: 'admin-users-list',
    component: usersList
  },
  {
    path: 'users/:id',
    name: 'admin-users-edit',
    component: usersEdit
  }
]