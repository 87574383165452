import Vue from 'vue'
import Vuex from 'vuex'

import news from '@/store/news'
import pages from '@/store/pages'
import form from '@/store/form'
import settings from '@/store/settings'
import project from '@/store/project'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    news,
    project,
    pages,
    form,
    settings
  }
})