<template>
  <div>
    <div class="d-none d-md-block">
      <div class="row px-offset height">
        <div class="col px-0 px-lg-20">
          <div class="row h-100 m-0">
            <div class="col-6 shadow-lg">
              <div class="row h-100">
                <div
                  v-for="partner in partners"
                  :key="partner.id"
                  class="col-6 h-50 d-flex align-items-center justify-content-center shadow-sm"
                >
                  <img
                    :src="partner.file"
                    class="img-fluid"
                    :alt="partner.title | localize"
                    :title="partner.title | localize"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 bg-image p-0">
              <div
                class="d-flex flex-column justify-content-end pb-10 px-20 h-100 w-100 bg-green-1-opacity"
              >
                <p
                  class="fs-40 text-white font-weight-bold"
                >{{ $t('work_safety_health.partners.title') }}</p>
                <p class="fs-14 text-white">{{ $t('work_safety_health.partners.summary') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none">
      <p
        class="text-grey-2 fs-26 font-weight-bold text-center"
      >{{ $t('work_safety_health.partners.title') }}</p>
      <p class="text-grey-1 fs-16 text-center">{{ $t('work_safety_health.partners.summary') }}</p>
      <div class="row px-offset">
        <div
          v-for="partner in partners"
          :key="partner.id"
          class="col-6 text-center align-items-center justify-content-center shadow bg-white"
        >
          <img
            :src="partner.file"
            class="img-size img-mobile"
            :alt="partner.title | localize"
            :title="partner.title | localize"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    partners: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.bg-image {
  background-image: url(/assets/images/work-safety-health/partnersBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-green-1-opacity {
  background-color: rgba($green-1, 0.7);
}

.img-size {
  height: 6.25rem;
  width: 6.25rem;
}

.img-mobile {
  object-fit: contain;
}

.height {
  height: 31.25rem;
}
</style>