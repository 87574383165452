<template>
  <div class="col-6 col-xl-8 text-center align-self-center">
    <router-link :to="getRoute" class="text-decoration-none">
      <img 
      src="assets/images/logos/sepri_group.svg"
      class="logo-size" 
      :alt="$t('logo_sepri_image_alt')"
      :title="$t('logo_sepri_image_title')"
    />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    getRoute() {
      return {
        name: this.page.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.page.url) }
      }
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === 'home')
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-size {
  width: 10.5rem;
  height: 4.3125rem;
}
</style>