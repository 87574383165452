<template>
  <footer class="app-footer bg-white text-right position-fixed bottom-0 left-0 right-0">
    <strong>
      Copyright © 2020
      <a href="https://www.sepri.pt/" target="_blank">
        Sepri
      </a>
    </strong>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  @import "~@vars";

  .app-footer {
    color: $bo-footer-text;
    z-index: 999;
    border-top: 1px solid rgba($bo-footer-text,.12);
    padding: 1rem 1.875rem;
    margin-top: 1.875rem;
  }
</style>