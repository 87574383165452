 <template>
  <div class="container-fluid" v-if="!isLoading">
    <appHighlight :page="page" />
    <div class="row px-offset justify-content-center">
      <div class="col-12 col-md-10 col-lg-8 p-0">
        <h1 class="fs-24 text-primary font-weight-bold m-0">
          {{ project.title | localize }}
        </h1>
        <div class="fs-14 text-grey-1 pt-5">
          {{ project.published_at }}
        </div>
        <div class="row">
          <div class="col-8 col-md-5">
            <img
              src="assets/images/logos/norte2020-desktop.svg"
              class="pt-10 w-100"
              :alt="$t('logo_norte2020_image_alt')"
              :title="$t('logo_norte2020_image_title')"
            />
          </div>
        </div>
        <img
          :src="project.file"
          class="w-100 img-size py-10"
          :alt="project.title | localize"
          :title="project.title | localize"
        />
        <div
          class="fs-16 text-grey-2 pb-10 project"
          v-html="translate(project.body)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import apiProjects from "@/api/projects";
import appHighlight from "@/components/frontoffice/common/highlight";
import store from "@/store";
import { mapGetters } from "vuex";
import metaMixin from "@/mixins/meta";

export default {
  mixins: [metaMixin],
  components: {
    appHighlight,
  },
  data: () => ({
    apiProjects,
    isLoading: true,
    project: null,
  }),
  created() {
    this.init();
  },
  methods: {
    async init() {
      const response = await this.apiProjects.all(this.filtersProjects);
      if (response && response.data && response.data.list) {
        if (response.data.list.length > 0) {
          this.project = response.data.list[0];
          store.dispatch("project/set", this.project);
          this.updateMetaInformation();
          this.isLoading = false;
        }
      } else {
        this.$router.push({ name: "notFound" });
      }
    },
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)));
    },
    translate(value) {
      return this.$options.filters.localize(value);
    },
    updateMetaInformation() {
      this.metaTitle = this.translate(this.project.meta_title) + " | Sepri";
      this.metaDescription = this.translate(this.project.meta_description);
      this.metaKeywords = this.translate(this.project.meta_keywords);
    },
  },
  beforeDestroy() {
    store.dispatch("project/reset");
  },
  computed: {
    ...mapGetters({
      pages: "pages/pages",
    }),
    page() {
      return this.pages.find((page) => page.route === this.$route.name);
    },
    filtersProjects() {
      return {
        take: 1,
        skip: 0,
        slug: this.encode(this.$route.params.slug),
      };
    },
  },
};
</script>

<style lang="scss">
.project p img {
  max-width: 100%;
}
</style>

<style lang="scss" scoped>
@import "~@bootstrap-functions";
@import "~@bootstrap-variables";
@import "~@bootstrap-mixins";
@import "~@vars";

.img-size {
  max-width: 100%;
  max-height: 28.125rem;
}
</style>