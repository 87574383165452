<template>
  <div id="header" class="vh-header h-md-auto container-fluid py-5 d-none d-md-block">
    <div class="row px-offset">
      <search />
      <logo />
      <locales />
    </div>
    <div class="row px-offset justify-content-center">
      <navbar :pages="pages"/>
    </div>
  </div>
</template>

<script>
import search from '@/components/frontoffice/header/search'
import logo from '@/components/frontoffice/header/logo'
import locales from '@/components/frontoffice/header/locales'
import navbar from '@/components/frontoffice/header/navbar'

export default {
  components: {
    search,
    logo,
    locales,
    navbar
  },
  props: {
    pages: {
      type: Array,
      required: true
    }
  },
};
</script>