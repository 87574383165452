const links = [
  { title: 'Home', route: 'admin-dashboard' },
  { title: 'Notícias', route: 'admin-news-list' },
  { title: 'Banners', route: 'admin-banners-list' },
  { title: 'Areas de Trabalho', route: 'admin-areas-list' },
  { title: 'Propostas de Trabalho', route: 'admin-careers-list' },
  { title: 'Histórico da Empresa', route: 'admin-histories-list' },
  { title: 'Parceiros', route: 'admin-partners-list' },
  { title: 'Testemunhos', route: 'admin-testimonials-list' },
  { title: 'Páginas', route: 'admin-pages-list' },
  { title: 'Destaques', route: 'admin-highlights-list' },
  { title: 'Projetos', route: 'admin-projects-list' },
  { title: 'Membros da Equipa', route: 'admin-teams-list' },
  { title: 'Subscritores', route: 'admin-subscribers-list' },
  { title: 'Utilizadores', route: 'admin-users-list' },
  { title: 'Pedidos de Contacto', route: 'admin-contact-list' },
]

const perPageAmounts = [
  10,
  50,
  100,
  1000
]

export default {
  links,
  perPageAmounts
}