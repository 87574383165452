<template>
  <div class="text-center form-wrapper login">
    <form
      @submit.prevent="login"
      class="form-signin"
    >
      <img
        src="assets/images/logos/sepri_group.svg"
        class="logo mb-2"
      />
      <h1 class="h3 mb-3 font-weight-normal">
        Backoffice
      </h1>
      <label
        for="inputEmail"
        class="sr-only"
      >
        Email
      </label>
      <input
        v-model="email"
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Email"
        required
        autofocus
      >
      <label
        for="inputPassword"
        class="sr-only"
      >
        Password
      </label>
      <input
        v-model="password"
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        required
      >
      <button
        type="submit"
        class="btn btn-lg btn-spot btn-block"
      >
        Entrar
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    email: '',
    password: '',
    loginError: false,
  }),
  methods: {
    login() {
      this.loginError = false
      this.$auth
      .login({
        fetchUser: true,
        staySignedIn: true,
        params: {
          email: this.email,
          password: this.password
        }
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          this.$toasted.success('Logged in successfully')
          this.$router.push({ name: 'admin' })
        } else {
          this.loginError = true
        }
      }, () => {
        this.loginError = true
        this.$toasted.error('Unable to log in')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@vars";

  .logo {
    height: 4rem;
  }

  .btn-spot {
    background: $primary;
    border-color: $primary;
    background: $primary;
    border-color: $primary;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $fs-14;
    color:$white
  }

  .login {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 1060;
  }

  .form-wrapper {
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  .form-signin {
    width: 100%;
    max-width: 500px;
    padding: 15px;
    margin: 0 auto;
    border-radius: 3px;
    background: $white;
    border-top: 3px solid $primary;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    padding: 40px;
    position: absolute;
    left: 0;
    right: 0;
    top: 25%;
  }

  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: $fs-16;
  }

  .form-signin .form-control:focus {
    z-index: 2;
  }

  .form-signin input[type="email"] {
    margin-bottom: 10px;
    margin-bottom: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
</style>