
/*
 * Routes where the user doesn't need to be authenticated
 *
 */

import login from '@/views/backoffice/login'

export default [
	{
    path: '/admin/login',
    name: 'login',
    component: login
  }
]