import careersList from '@/views/backoffice/careers/list'
import careersEdit from '@/views/backoffice/careers/edit'

export default [
  {
    path: 'careers/create',
    name: 'admin-careers-create',
    component: careersEdit
  },
  {
    path: 'careers',
    name: 'admin-careers-list',
    component: careersList
  },
  {
    path: 'careers/:id',
    name: 'admin-careers-edit',
    component: careersEdit
  }
]