<template>
  <div class="container-fluid" v-if="!isLoading">
    <appHighlight :page="page"/>
    <div class="row px-offset justify-content-around">
      <div v-for="project in projectsList" :key="project.id" class="col-12 col-md-6 col-lg-4 mb-6 px-0 px-md-4">
        <b-card no-body >
          <b-card-img :src="project.file" :alt="project.title | localize" :title="project.title | localize" top ></b-card-img>
          <b-card-body >
            <b-card-title>
              <router-link class="text-decoration-none max-lines-2" :to="getRoute(project)">
                <h2 class="fs-24 text-primary">{{ project.title | localize }}</h2>
              </router-link>
            </b-card-title>
            <b-card-text class="fs-16 max-lines-3">{{ project.summary | localize }}</b-card-text>
          </b-card-body>

          <b-card-footer class="bg-white">
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ project.published_at }}</span>
            </div>
          </b-card-footer>
        </b-card>
      </div>
    </div>
    <div class="row px-offset justify-content-end">
      <div class="col-3 d-flex justify-content-end px-0 px-md-4">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="init"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import apiProjects from '@/api/projects'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  components: {
    appHighlight,
  },
  data: () => ({
    apiProjects,
    isLoading: true,
    projectsList: [],
    totalRows: 0,
    perPage: 6,
    currentPage: 1,
  }),
  created() {
    this.init()
  },
  methods: {
    async init(currentPage = null) {
      if (currentPage) {
        this.currentPage = currentPage
      }
      const response = await this.apiProjects.all(this.filtersProjects)
      if (response && response.data && response.data.list) {
        this.projectsList = response.data.list
        this.totalRows = response.data.total
        this.isLoading = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    getRoute(project) {
      return {
        name: this.pageProject.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.pageProject.url), slug: this.translate(project.slug) }
      }
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
  },
  computed: {
    filtersProjects() {
      return  {
        sort: 'published_at,desc',
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage,
        title: this.title
      }
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    pageProject() {
      return this.pages.find(page => page.route === 'project')
    },
    title() {
      return this.$route.query.title ? this.encode(this.$route.query.title) : null
    }
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.query.title !== from.query.title) {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>

.card-img-top {
  height: 15.625rem;
}

.card-body {
  height: 12.5rem;
}
</style>