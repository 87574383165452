import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import frontofficeRoutes from "@/router/frontoffice/index";
import backofficeRoutes from "@/router/backoffice/index";

Vue.use(VueRouter);
Vue.use(Meta);

export default new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    ...frontofficeRoutes,
    ...backofficeRoutes,
    {
      path: "*",
      redirect: "404"
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});
