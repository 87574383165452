import contactList from '@/views/backoffice/contact/list'
import contactEdit from '@/views/backoffice/contact/edit'

export default [
  {
    path: 'contact/create',
    name: 'admin-contact-create',
    component: contactEdit
  },
  {
    path: 'contact',
    name: 'admin-contact-list',
    component: contactList
  },
  {
    path: 'contact/:id',
    name: 'admin-contact-edit',
    component: contactEdit
  }
]