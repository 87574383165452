<template>
  <div
    v-if="$auth.user()"
    class="nav-side-menu d-md-block d-flex flex-wrap align-items-center justify-content-between text-white fs-12 w-200 mb-3 mb-md-0 overflow-auto"
  >
    <div class="brand text-center lh-50 d-block pl-3 pl-md-0">
      Backoffice
    </div>
    <input
      id="slide-sidebar"
      type="checkbox"
      role="button"
      class="d-none"
    />
    <label
      for="slide-sidebar"
      class="nav-mobile d-md-none d-block pr-3 mb-0 cursor-pointer"
    >
      <div class="navbar-toggle collapsed btn-top position-relative float-right">
        <span class="sr-only position-absolute border-0 p-0 overflow-hidden">
          Toggle navigation
        </span>
        <span class="icon-bar d-block" />
        <span class="icon-bar d-block" />
        <span class="icon-bar d-block" />
      </div>
    </label>
    <div
      class="menu-list d-none d-md-block mt-6"
      role="tablist"
    >
      <template v-for="(link, index) in links">
        <li
          v-if="!hasChildren(link)"
          :key="index"
          class="nav-item list-unstyled lh-35"
        >
          <router-link
            :to="{ name: link.route }"
            class="nav-link"
          >
            {{ link.title }}
          </router-link>
        </li>
        <b-card
          v-else
          no-body
          class="rounded-0 bg-transparent"
          :key="index"
        >
          <b-card-header
            header-tag="header"
            class="p-0"
            role="tab"
          >
            <button
              v-b-toggle="`accordion-${index}`"
              href="#"
              class="btn collapse-header text-white lh-30 nav-link text-left w-100 h-100 fs-12"
            >
              {{ link.title }}
            </button>
          </b-card-header>
          <b-collapse
            :id="`accordion-${index}`"
            class="collapse-body shadow-none"
            accordion="my-accordion"
            role="tabpanel"
          >
            <ul
              v-for="(child, key) in link.children"
              :key="key"
              class="menu-content list-unstyled m-0"
            >
              <li class="nav-item lh-35">
                <router-link
                  :to="{ name: child.route }"
                  class="nav-link"
                >
                  {{ child.title }}
                </router-link>
              </li>
            </ul>
          </b-collapse>
        </b-card>
      </template>
      <li
        v-if="$auth.check()"
        class="nav-item nav-item list-unstyled lh-35 d-md-none"
      >
        <button
          @click.prevent="$auth.logout()"
          href="#"
          class="nav-link"
        >
          Sair
          <font-awesome-icon :icon="logout" />
        </button>
      </li>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import constants from '@/constants/backoffice'

export default {
  data: () => ({
    links: constants.links,
    logout: faSignOutAlt,
    user: faUserCircle
  }),
  components: {
    FontAwesomeIcon
  },
  methods: {
    hasChildren (object) {
      return object && object.children && object.children.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@vars";

.collapse-header {
  background: rgba($form-grey, 0.5);
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $bo-border;

  transition: all 0.3s;

  &:hover {
    background: $secondary;
  }

  &:not(.collapsed) {
    background: $secondary;
  }
}

.collapse-body > .menu-content {
  background: rgba($black, 0.4);
}

.nav-side-menu {
  width: 18.75rem;
  background-color: $sidebar;
  z-index: 1000;

  & .brand {
    font-size: $fs-14;
  }

  @media (max-width: 767px) {
    & {
      width: 100%;

      & .brand {
        font-size: $fs-22;
      }
    }
  }
}

.nav-item {
  cursor: pointer;

  & .active {
    border-left: 3px solid $primary;
    background-color: $bo-active;
  }

  &:hover {
    border-left: 3px solid $primary;
    background-color: $bo-active;
    transition: all 1s ease;
  }
}

.nav-link {
  color: $white;

  &.router-link-exact-active,
  &.router-link-active.drop {
    border-left: 3px solid $primary;
    background-color: $bo-active;
  }

  &.router-link-active.no-active {
    border-left: none;
    background-color: transparent;
  }
}

.icon-bar {
  background-color: $grey;
  width: 1.375rem;
  height: 2px;
  border-radius: 1px;

  &:not(:first-child) {
    margin-top: 4px;
  }
}

.nav-mobile {
  z-index: 200;
  transition: left 0.5s ease;
}

.menu-content {
  border-bottom: 1px solid $bo-border;
}

.menu-list {
  flex-basis: 100%;
}

input:checked ~ .menu-list {
  display: block !important;
}

.sr-only {
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0,0,0,0);
}
</style>