<template>
  <div id="featured" class="row vh-100 px-offset bg-image" v-if="hasNews">
    <div class="col h-100">
      <div class="row h-20 align-items-center">
        <div class="col h-100 pt-5">
          <p
            class="text-primary text-center fs-40 font-weight-bold"
          >{{ $t(`${route}.featured.title`) }}</p>
          <p class="text-grey-2 text-center fs-14">{{ $t(`${route}.featured.description`) }}</p>
        </div>
      </div>
      <div class="row h-70" v-if="newsLength(1)">
        <div class="col-12 h-100">
          <img 
            :src="news[0].file"
            class="h-75 w-100"
            :alt="news[0].title | localize"
            :title="news[0].title | localize"
          />
          <span class="fs-12 text-primary font-weight-bold">{{ $t('home.featured.news') }}</span>
          <span class="fs-12 text-grey-1">{{ news[0].published_at }}</span>
          <br />
          <router-link
            :to="getRoute(news[0])"
            class="text-decoration-none fs-20 font-weight-bold text-grey-2 max-lines-2"
          >{{ news[0].title | localize }}</router-link>
          <p class="fs-14 text-grey-1 max-lines-2">{{ news[0].summary | localize }}</p>
        </div>
      </div>
      <div class="row h-70" v-else-if="newsLength(2)">
        <div class="col-6 h-100">
          <img
            :src="news[0].file"
            class="h-75 w-100"
            :alt="news[0].title | localize"
            :title="news[0].title | localize"
          />
          <span class="fs-12 text-primary font-weight-bold">{{ $t('home.featured.news') }}</span>
          <span class="fs-12 text-grey-1">{{ news[0].published_at }}</span>
         
          <br />
          <router-link
            :to="getRoute(news[0])"
            class="text-decoration-none fs-20 font-weight-bold text-grey-2 max-lines-2"
          >{{ news[0].title | localize }}</router-link>
          <p class="fs-14 text-grey-1 max-lines-2">{{ news[0].summary | localize }}</p>
        </div>
        <div class="col-6 h-100">
          <img 
            :src="news[1].file"
            class="h-75 w-100"
            :alt="news[1].title | localize"
            :title="news[1].title | localize"
          />
          <span class="fs-12 text-primary font-weight-bold">{{ $t('home.featured.news') }}</span>
          <span class="fs-12 text-grey-1">{{ news[1].published_at }}</span>
          <br />
          <router-link
            :to="getRoute(news[1])"
            class="text-decoration-none fs-20 font-weight-bold text-grey-2 max-lines-2"
          >{{ news[1].title | localize }}</router-link>
          <p class="fs-14 text-grey-1 max-lines-2">{{ news[1].summary | localize }}</p>
        </div>
      </div>
      <div class="row h-70" v-else-if="newsLength(3)">
        <div class="col-8 h-100">
          <img
            :src="news[0].file"
            class="h-75 w-100"
            :alt="news[0].title | localize"
            :title="news[0].title | localize"
          />
          <span class="fs-12 text-primary font-weight-bold">{{ $t('home.featured.news') }}</span>
          <span class="fs-12 text-grey-1">{{ news[0].published_at }}</span>
          
          <br />
          <router-link
            :to="getRoute(news[0])"
            class="text-decoration-none fs-20 font-weight-bold text-grey-2 max-lines-2"
          >{{ news[0].title | localize }}</router-link>
          <p class="fs-14 text-grey-1 max-lines-2">{{ news[0].summary | localize }}</p>
        </div>
        <div class="col-4 h-100">
          <div class="row h-50">
            <div class="col h-100">
              <img
                :src="news[1].file"
                class="h-50 w-100"
                :alt="news[1].title | localize"
                :title="news[1].title | localize"
              />
              <span class="fs-12 text-primary font-weight-bold">{{ $t('home.featured.news') }}</span>
              <span class="fs-12 text-grey-1">{{ news[1].published_at }}</span>
              <br />
              <router-link
                :to="getRoute(news[1])"
                class="text-decoration-none fs-20 font-weight-bold text-grey-2 max-lines-2"
              >{{ news[1].title | localize }}</router-link>
              <p class="fs-14 text-grey-1 max-lines-2">{{ news[1].summary | localize }}</p>
            </div>
          </div>
          <div class="row h-50">
            <div class="col h-100">
              <img
                :src="news[2].file"
                class="h-50 w-100"
                :alt="news[2].title | localize"
                :title="news[2].title | localize"
              />
              <span class="fs-12 text-primary font-weight-bold">{{ $t('home.featured.news') }}</span>
              <span class="fs-12 text-grey-1">{{ news[2].published_at }}</span>
              <br />
              <router-link
                :to="getRoute(news[2])"
                class="text-decoration-none fs-20 font-weight-bold text-grey-2 max-lines-2"
              >{{ news[2].title | localize }}</router-link>
              <p class="fs-14 text-grey-1 max-lines-2">{{ news[2].summary | localize }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-10 align-items-center justify-content-end">
        <div class="col-2 text-right">
          <router-link
            :to="getRouteMedia"
            class="fs-12 text-primary text-decoration-none"
          >
            {{ $t('home.know_more') }}
            <i class="anticon icon-arrowright fs-12 ml-3"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
   props: {
    news: {
      type: Array,
      required: true
    }
  },
  methods: {
    newsLength(length) {
      return this.news.length === length
    },
    getRoute(news) {
      return {
        name: this.pageNews.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.pageNews.url), slug: this.translate(news.slug) }
      }
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    hasNews() {
      return this.news.length > 0
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    pageNews() {
      return this.pages.find(page => page.route === 'news')
    },
    getRouteMedia() {
      return {
        name: this.pageMedia.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.pageMedia.url) }
      }
    },
    pageMedia() {
      return this.pages.find(page => page.route === 'media')
    },
    route() {
      const route = this.$route.name
      return route.replace(/-/g, '_')
    },
  }
};
</script>