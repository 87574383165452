<template>
  <div>
    <div class="loader-box">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '~@vars';

  .loader-box {
      position: absolute;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $white;

    .loader {
      border: 10px solid $grey-1;
      border-top: 10px solid $grey-1;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: spin 2s linear infinite;
      position: absolute;
      margin: 0 auto;
      top: 50%;
      bottom: 0;
      left: calc(40% - 360px);
      right: 0;
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
