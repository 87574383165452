<template>
  <div class="w-100 py-5">
    <b-dropdown
      :text="perPageIndicator"
      variant="primary"
      :dropright="true"
      class="mr-5"
    >
      <b-dropdown-item-button
        v-for="amount in perPageAmounts"
        :key="amount"
        :active="isActive(amount)"
        :disabled="isDisabled(amount)"
        @click.stop="setPerPage(amount)"
      >
        {{ amount }}
      </b-dropdown-item-button>
    </b-dropdown>
    <b-dropdown
      text="Colunas"
      variant="primary"
      :dropright="true"
      class="mr-5"
    >
      <b-dropdown-form
        v-for="item in fields"
        :key="item.field"
        class="px-3"
        :active="item.visible"
      >
        <b-checkbox
          v-model="item.visible"
          @change="setVisibleFields(item)"
        >
          {{ item.name }}
        </b-checkbox>
      </b-dropdown-form>
    </b-dropdown>
    <b-dropdown
      text="Ordenar"
      variant="primary"
      :dropright="true"
      class="mr-5"
    >
      <template v-for="item in fields">
        <b-dropdown-form
          v-if="item.sortable"
          :key="item.field"
          class="px-3"
          @click.stop="setSortListBy(item.field)"
        >
          <span>
            {{ item.name }}
          </span>
          <span class="float-right">
            <font-awesome-icon
              v-if="isSorting(item.field, 'asc')"
              :icon="icon.arrowDown"
            />
            <font-awesome-icon
              v-if="isSorting(item.field, 'desc')"
              :icon="icon.arrowUp"
            />
          </span>
        </b-dropdown-form>
      </template>
    </b-dropdown>
    <b-dropdown
      text="Ferramentas"
      class="p-0"
      variant="primary"
    >
      <b-dropdown-item-button @click.stop="callRemoveSelectedItems()">
        Remover selecionados
      </b-dropdown-item-button>
      <slot name="tools" />
    </b-dropdown>
    <slot name="actions" />
    <router-link
      v-if="hasCreateRoute"
      :to="{ name: createRoute }"
      class="float-right"
    >
      <b-button variant="success">
        Novo
      </b-button>
    </router-link>
  </div>
</template>

<script>
import { faArrowAltCircleUp, faArrowAltCircleDown, faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import settings from '@/constants/backoffice'

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDirection: {
      type: String,
      required: true
    },
    createRoute: {
      type: String,
      required: false,
      default: null
    },
    total: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    perPageAmounts: settings.perPageAmounts,
    icon: {
      arrowUp: faArrowAltCircleUp,
      arrowDown: faArrowAltCircleDown,
      thumbsDown: faThumbsDown,
      thumbsUp: faThumbsUp
    }
  }),
  computed: {
    hasCreateRoute () {
      return this.createRoute !== null
    },
    perPageIndicator () {
      return `${this.perPage} / ${this.total}`
    }
  },
  methods: {
    isDisabled (value) {
      return this.isAboveTotal(value) || this.isActive(value)
    },
    isAboveTotal (value) {
      return value > this.total
    },
    isActive (value) {
      return this.perPage == value
    },
    isSorting (field, direction) {
      return field === this.sortBy && direction === this.sortDirection
    },
    setPerPage (perPage) {
      this.$emit('updatePerPage', perPage)
    },
    setVisibleFields (field) {
      this.$emit('updateVisibleFields', field)
    },
    setSortListBy (field) {
      this.$emit('updateSortListBy', field)
    },
    callRemoveSelectedItems () {
      this.$emit('removeSelectedItems')
    }
  }
}
</script>
