<template>
  <div class="w-100">
    <b-form-group label="Nome">
      <b-input
        v-model="item.name"
        name="name"
        type="text"
        required
        placeholder="Lorem"
      />
    </b-form-group>
    <b-form-group label="Email">
      <b-input
        v-model="item.email"
        name="email"
        type="email"
        required
        placeholder="lorem@ipsum.set"
        :disabled="isEdit"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      item: 'form/fields'
    }),
    isEdit () {
      return this.$route.params && !!this.$route.params.id
    }
  }
}
</script>