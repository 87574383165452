<template>
  <div v-if="!isLoading">
    <div class="d-none d-md-block">
      <div class="row height">
        <div class="col">
          <div class="row h-50 bg-image justify-content-center align-items-center">
            <p class="col-5 text-center text-white fs-24">{{ $t('sepri.team.title') }}</p>
          </div>
          <div class="row h-50 justify-content-center margin-top-negative">
            <div class="col-10 text-center">
              <b-carousel
                ref="carouselTeam"
                class="carousel-team"
                :interval="3000"
                indicators
                img-width="1024"
              >
                <b-carousel-slide img-blank v-for="team in chunckList" :key="team.id">
                  <div class="row h-100 justify-content-around align-items-center">
                    <div class="col-1 cursor-pointer" @click.stop="prev">
                      <i class="anticon icon-left fs-40 arrow-color" />
                    </div>
                    <div
                      v-for="person in team"
                      :key="person.id"
                      class="col-3 team p-0 h-75 bg-cover bg-center d-flex align-items-end justify-content-center"
                      :style="background(person.file)"
                    >
                      <div
                        class="bg-blue h-100 w-100 d-flex align-items-end justify-content-center"
                      >
                        <div class="display pb-5">
                          <p class="text-white font-weight-bold fs-14 mb-1">{{ person.name }}</p>
                          <p class="text-white fs-12 mb-1">{{ person.job | localize }}</p>
                          <a v-if="person.url" :href="person.url">
                            <img
                              src="assets/images/linkedin.svg"
                              class="linkedin-image"
                              :alt="person.name | localize"
                              :title="person.name | localize"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 cursor-pointer" @click.stop="next">
                      <i class="anticon icon-right fs-40 arrow-color" />
                    </div>
                  </div>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none">
      <div class="row px-offset bg-image height align-items-end">
        <div class="col-12 p-0">
          <p class="text-center text-white fs-22 pb-5">{{ $t('sepri.team.title') }}</p>
        </div>
      </div>
      <div class="row px-offset position">
        <div class="col-12 p-0">
          <b-carousel class="carousel-team" :interval="3000" indicators>
            <b-carousel-slide img-blank v-for="person in fullList" :key="person.id">
              <div class="row justify-content-around align-items-center h-100">
                <div
                  class="col-12 team p-0 bg-cover bg-center d-flex align-items-end justify-content-center h-100"
                  :style="background(person.file)"
                >
                  <div class="bg-blue h-100 w-100 d-flex align-items-end justify-content-center">
                    <div class="display pb-20">
                      <p class="text-white font-weight-bold fs-14 mb-1">{{ person.name }}</p>
                      <p class="text-white fs-12 mb-1">{{ person.job | localize }}</p>
                      <a v-if="person.url" :href="person.url">
                        <img
                          src="assets/images/linkedin.svg"
                          class="linkedin-image"
                          :alt="person.name | localize"
                          :title="person.name | localize"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import apiTeams from '@/api/teams'
import chunckArrayMixin from '@/mixins/chunckArray'

export default {
  mixins: [
    chunckArrayMixin
  ],
  data: () => ({
    apiTeams,
    isLoading: true,
    fullList: [],
    chunkSize: 3
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.apiTeams.all(this.filtersTeams)
      if (response && response.data && response.data.list) {
        this.fullList = response.data.list
        this.isLoading = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    next() {
      this.$refs.carouselTeam.next()
    },
    prev() {
      this.$refs.carouselTeam.prev()
    },
    background(file) {
      return `background-image: url(${file})`
    }
  },
  computed: {
    filtersTeams() {
      return {
        sort: 'order,asc'
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.bg-image {
  background-image: url(/assets/images/sepri/teamBackground.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @include media-breakpoint-down(sm) {
    background-image: url(/assets/images/sepri/teamBackground_mobile.png);
  }
}

.bg-center {
  background-position: center;
}

.arrow-color {
  color: rgba($black, 0.25);
}

.linkedin-image {
  height: 1.25rem;
  widows: 1.25rem;
}

.margin-top-negative {
  margin-top: -3.125rem;
}

.team {
  & .display {
    display: none;
  }
  &:hover {
    & .bg-blue {
      & .display {
        display: block;
      }
      background-color: rgba($primary, 0.7);
    }
    
  }
}

.height {
  height: 44rem;
  @include media-breakpoint-down(lg) {
    height: 36rem;
  }
  @include media-breakpoint-down(sm) {
    height: 18rem;
  }
}

.position {
  transform: translateY(-20px);
}
</style>

<style lang="scss">
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.carousel-team .carousel-inner .carousel-item .carousel-caption {
  height: 100%;
  width: 100%;
  bottom: 15%;
  right: 0%;
  left: 0%;

  @include media-breakpoint-down(sm) {
    bottom: 0%;
    padding: 0;
  }
}

.carousel-team .carousel-indicators {
  bottom: 15%;

  @include media-breakpoint-down(sm) {
    bottom: 0%;
  }
}

.carousel-team .carousel-indicators li {
  background-color: $grey-1;
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;

  @include media-breakpoint-down(sm) {
      height: 0.4375rem;
      width: 0.4375rem;
  }
}

.carousel-team .carousel-indicators .active {
  background-color: $primary;
}

.carousel-team .carousel-inner .carousel-item > img{
  height: 26rem;

  @include media-breakpoint-down(lg) {
      height: 22rem;
  }

  @include media-breakpoint-down(sm) {
    height: 20rem;
  }
}
</style>