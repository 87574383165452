<template>
  <button class="btn shadow-none" :id="`popover-${news.id}`">
    <i class="anticon icon-sharealt mr-2 fs-18"/>
    <span class="fs-18 text-capitalize">{{ $t('media.share') }}</span>
    <b-popover
      :target="`popover-${news.id}`"
      placement="bottom"
      triggers="focus">
      <social-sharing
        :url="getUrl"
        :title="translate(news.title)"
        :description="translate(news.summary)"
        hashtags="sepri"
        inline-template>
        <div class="d-flex">
          <network network="facebook" class="logo cursor-pointer bg-facebook d-flex align-items-center justify-content-center mr-2">
            <i class="anticon icon-facebook-square fs-18 text-white"/>
          </network>
          <network network="twitter" class="logo cursor-pointer bg-twitter d-flex align-items-center justify-content-center mr-2">
            <i class="anticon icon-twitter fs-18 text-white"/>
          </network>
          <network network="linkedin" class="logo cursor-pointer bg-linkedin d-flex align-items-center justify-content-center">
            <i class="anticon icon-linkedin-square fs-18 text-white"/>
          </network>
        </div>
      </social-sharing>
    </b-popover>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    news: {
      type: Object,
      required: true
    }
  },
  methods: {
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    pageNews() {
      return this.pages.find(page => page.route === 'news')
    },
    getUrl() {
      const url = window.location.origin
      const locale = this.$i18n.locale
      const name = this.translate(this.pageNews.url)
      const slug = this.translate(this.news.slug)
      return `${url}/${locale}/${name}/${slug}`
    }
  }
}
</script>

<style lang="scss">
@import '~@vars';

.logo {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.bg-twitter {
  background-color: $twitter;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($twitter, 10%)
  }
}

.bg-facebook {
  background-color: $facebook;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($facebook, 10%)
  }
}

.bg-linkedin {
  background-color: $linkedin;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($linkedin, 10%)
  }
}
</style>
