<template>
  <div id="solutionsHeader" class="row px-offset vh-body position-relative pb-5">
    <div class="w-100">
      <div class="row h-100 m-0 d-none d-md-flex position-relative overflow-hidden">
        <div class="fullwidth-video">
          <video autoplay muted loop>
            <source src="assets/images/home/header/video.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="col solution border-white-3 p-5">
          <router-link
            :to="{ name: solutions[0].route, params: { lang: $i18n.locale, path: translate(solutions[0].url) } }"
            class="text-decoration-none"
          >
            <h2 class="fs-30 font-weight-bold text-white">{{ solutions[0].title | localize }}</h2>
          </router-link>
          <div class="display">
            <router-link
              :to="{ name: solutions[0].route, params: { lang: $i18n.locale, path: translate(solutions[0].url) } }"
              class="fs-14 text-white text-decoration-none"
            >
              {{ $t('home.work_safety_health_know_more') }}
              <i
                class="anticon icon-arrowright fs-14 ml-3"
              ></i>
            </router-link>
          </div>
        </div>
        <div class="col">
          <div class="row h-50">
            <div class="col-6 solution border-white-3 p-5">
              <router-link
                :to="{ name: solutions[1].route, params: { lang: $i18n.locale, path: translate(solutions[1].url) } }"
                class="text-decoration-none"
              >
                <h2 class="fs-30 font-weight-bold text-white">{{ solutions[1].title | localize }}</h2>
              </router-link>
              <div class="display">
                <router-link
                  :to="{ name: solutions[1].route, params: { lang: $i18n.locale, path: translate(solutions[1].url) } }"
                  class="fs-14 text-white text-decoration-none"
                >
                  {{ $t('home.health_services_24_know_more') }}
                  <i
                    class="anticon icon-arrowright fs-14 ml-3"
                  ></i>
                </router-link>
              </div>
            </div>
            <div class="col-6 solution border-white-3 p-5">
              <router-link
                :to="{ name: solutions[2].route, params: { lang: $i18n.locale, path: translate(solutions[2].url) } }"
                class="text-decoration-none"
              >
                <h2 class="fs-30 font-weight-bold text-white">{{ solutions[2].title | localize }}</h2>
              </router-link>
              <div class="display">
                <router-link
                  :to="{ name: solutions[2].route, params: { lang: $i18n.locale, path: translate(solutions[2].url) } }"
                  class="fs-14 text-white text-decoration-none"
                >
                  {{ $t('home.accident_medicine_know_more') }}
                  <i
                    class="anticon icon-arrowright fs-14 ml-3"
                  ></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row h-50 text-white">
            <div class="col-6 solution border-white-3 p-5">
              <router-link
                :to="{ name: solutions[3].route, params: { lang: $i18n.locale, path: translate(solutions[3].url) } }"
                class="text-decoration-none"
              >
                <h2 class="fs-30 font-weight-bold text-white">{{ solutions[3].title | localize }}</h2>
              </router-link>
              <div class="display">
                <router-link
                  :to="{ name: solutions[3].route, params: { lang: $i18n.locale, path: translate(solutions[3].url) } }"
                  class="fs-14 text-white text-decoration-none"
                >
                  {{ $t('home.environmental_studies_laboratory_know_more') }}
                  <i
                    class="anticon icon-arrowright fs-14 ml-3"
                  ></i>
                </router-link>
              </div>
            </div>
            <div class="col-6 solution border-white-3 p-5">
              <router-link
                :to="{ name: solutions[4].route, params: { lang: $i18n.locale, path: translate(solutions[4].url) } }"
                class="text-decoration-none"
              >
                <h2 class="fs-30 font-weight-bold text-white">{{ solutions[4].title | localize }}</h2>
              </router-link>
              <div class="display">
                <router-link
                  :to="{ name: solutions[4].route, params: { lang: $i18n.locale, path: translate(solutions[4].url) } }"
                  class="fs-14 text-white text-decoration-none"
                >
                  {{ $t('home.healthcare_consulting_know_more') }}
                  <i
                    class="anticon icon-arrowright fs-14 ml-3"
                  ></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-100 m-0 d-md-none">
        <div
          v-for="(solution,index) in solutions"
          :key="index"
          class="col-12 col-md-6 position-relative solution my-1 p-0 d-flex flex-column justify-content-between text-center"
          
        >
          <img class="bg-mobile" :src="solution.file[0].url" />
          <div class="position-absolute p-8 align-self-center h-100 d-flex flex-column justify-content-between">
            <router-link
              :to="{ name: solution.route, params: { lang: $i18n.locale, path: translate(solution.url) } }"
              class="text-decoration-none"
            >
              <h2 class="fs-26 font-weight-bold text-white">{{ solution.title | localize }}</h2>
            </router-link>
            <router-link
              :to="{ name: solution.route, params: { lang: $i18n.locale, path: translate(solution.url) } }"
              class="fs-14 text-white text-decoration-none"
            >
              {{ $t('home.know_more') }}
              <i class="anticon icon-arrowright fs-14 ml-3"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="fs-14 text-uppercase text-secondary rotate position-absolute position-left d-none d-lg-flex"
    >
      <span class="font-weight-bold text-grey-1">{{ $t('footer.follow_us') }}</span>
      <div class="line mx-3 mt-3"></div>
      <a class="text-decoration-none" href="https://www.linkedin.com/company/sepri-group">
        <img
          src="assets/images/linkedin.svg"
          class="icon-image"
          :alt="$t('footer.linkedin_image_alt')"
          :title="$t('footer.linkedin_image_title')"
        />
      </a>
      <a class="text-decoration-none ml-2" href="https://www.facebook.com/grupo.sepri">
        <img
          src="assets/images/facebook.svg"
          class="icon-image"
          :alt="$t('footer.facebook_image_alt')"
          :title="$t('footer.facebook_image_title')"
        />
      </a>
      <a class="text-decoration-none ml-2" href="https://www.instagram.com/sepri_group/?hl=en">
        <img
          src="assets/images/instagram.svg"
          class="icon-image"
          :alt="$t('footer.instagram_image_alt')"
          :title="$t('footer.instagram_image_title')"
        />
      </a>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    solutions: {
      type: Array,
      required: true
    },
    page: {
      type: Object,
      required: true
    }
  },
  methods: {
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    filtersBanners() {
      return  {
        take: 1,
        skip: 0,
        page: this.page.id
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.solution {
  background-color: rgba($grey-1, 0.26);

  @include media-breakpoint-down(sm) {
    height: 20rem;
  }

  & .display {
    display: none;
  }
  &:hover {
    & .display {
      display: block;
    }
    background-color: rgba($primary, 0.7);
  }
}

.border-white-3 {
  border: 3px solid $white;
}

.position-left {
  left: -0.5rem;
  bottom: 17.3rem;
  @include media-breakpoint-down(lg) {
    left: -3.5rem;
  }
}

.rotate {
  transform: rotate(-90deg) translateX(-60%) translateY(calc(-50% - 15px));
  width: min-content;
  text-decoration: none;
  letter-spacing: 0.2rem;
  white-space: nowrap;
}

.line {
  border-top: 1px solid $grey-1;
  width: 1.5rem;
}

.icon-image {
  height: 2rem;
  margin-top: -3px;
  opacity: 0.8;
}

.fullwidth-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  min-height: 100%;
  min-width: 100%;
}

.fullwidth-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -10;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bg-mobile {
  z-index: -10;
  height: 20rem;
}
</style>