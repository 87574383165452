<template>
  <div
    v-if="!loading"
    class="w-100"
  >
    <b-tabs
      no-nav-style
      nav-class="border-right border-light h-100"
      nav-wrapper-class="p-0"
      class="mx-0"
      active-nav-item-class="font-weight-bolder border-right text-secondary border-secondary"
      content-class="py-5 w-100 bg-white"
      vertical
    >
      <b-tab
          v-for="locale in locales"
          :key="locale"
          :title="$t(`locales.${locale}`)"
      >
        <b-form-group label="Título">
          <b-input
            v-model="model.title[locale]"
            name="title"
            type="text"
            required
            placeholder="Lorem ipsum set dolor"
          />
        </b-form-group>
        <b-form-group label="Resumo">
          <editor
            index="summary"
            :locale="locale"
            :isRelationship="true"
          />
        </b-form-group>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import api from '@/api/policies'
import editor from '@/components/backoffice/common/editor'
import { locales } from '@/constants/settings'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    editor
  },
  data: () => ({
    api,
    locales,
    loading: true,
    fields: {
      title: { pt: null, en: null },
      summary: { pt: null, en: null }
    },
    empty: {
      title: { pt: null, en: null },
      summary: { pt: null, en: null }
    },
  }),
  computed: {
    ...mapGetters({
      page: 'form/fields',
      model: 'form/relationshipFields',
    }),
    isCreate () {
      return this.model && this.model.id
    }
  },
  created () {
    this.init()
  },
  beforeDestroy () {
    this.resetRelationship()
  },
  methods: {
    ...mapActions({
      initRelationship: 'form/initRelationship',
      resetRelationship: 'form/resetRelationship',
    }),
    async init () {
      this.resetPolicy()
      await Promise.all([ this.loadPolicy() ])
      this.loading = false
    },
    async resetPolicy () {
      this.initRelationship({ ...JSON.parse(JSON.stringify(this.empty)), ...{ page_id: this.page.id } })
    },
    async loadPolicy () {
      try {
        const response = await this.api.all({ page: this.page.id })

        if (response && response.data && response.data.list) {
          this.initRelationship(response.data.list.pop())
        } else {
          this.$toasted.error(this.$t('errors.failed'))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
