import pagesList from '@/views/backoffice/pages/list'
import pagesEdit from '@/views/backoffice/pages/edit'

export default [
  {
    path: 'pages',
    name: 'admin-pages-list',
    component: pagesList
  },
  {
    path: 'pages/:id',
    name: 'admin-pages-edit',
    component: pagesEdit
  }
]