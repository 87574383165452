<template>
  <div v-if="!isLoading">
    <div class="d-none d-md-block">
      <div class="row vh-body">
        <div class="col position-relative">
          <div
            class="row h-80 justify-content-center align-items-center bg-cover"
            :style="background(banner.file)"
          ></div>
          <div
            class="row h-40 w-100 position-absolute bottom-0 justify-content-center align-items-center"
          >
            <div class="col-md-10 col-lg-8 h-100 bg-white text-center">
              <div class="row h-100 align-items-center justify-content-center">
                <div class="col-8 align-self-center">
                  <h1 class="fs-40 text-grey-2 font-weight-bold mb-4">{{ banner.title | localize }}</h1>
                  <p class="fs-14 text-grey-1">{{ $t('solutions.summary') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none">
      <div class="row px-offset">
        <div class="col-12 p-0">
          <div class="row justify-content-center bg-mobile" :style="background(banner.file)">
            <div class="col py-15 text-center">
              <p class="text-white fs-30 font-weight-bold mb-8">{{ banner.title | localize }}</p>
            </div>
          </div>
          <div class="row px-offset z-100 position">
            <div class="col-12 bg-white text-center">
              <div class="row justify-content-around align-items-center">
                <div class="col-12 py-5 text-center">
                  <p class="fs-16 text-grey-1">{{ $t('solutions.summary') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiBanners from '@/api/banners'

export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    apiBanners,
    banner: {},
    isLoading: true,
  }),
  created() {
    this.getBanners()
  },
  methods: {
    async getBanners() {
      const response = await this.apiBanners.all(this.filtersBanners)
      if (response && response.data && response.data.list) {
        if (response.data.list.length > 0) {
          this.banner = response.data.list[0]
          this.isLoading = false
        }
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    background(file) {
      return `background-image: linear-gradient(rgba(15,46,74,0.5),rgba(15,46,74,0.5)), url(${file})`
    }
  },
  computed: {
    filtersBanners() {
      return  {
        take: 1,
        skip: 0,
        page: this.page.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.bottom-0 {
  bottom: 0%;
}

.img-position {
  transform: translateY(50%);
}

.position {
  transform: translateY(-20px);
}

.bg-mobile {
  background-position: center;
  background-size: cover;
}
</style>
