 <template>
  <div class="container-fluid">
    <appContactUs />
    <appHighlight :page="page"/>
    <appSlider />
    <appAbout />
    <appTimeline />
    <appWorldMap />
    <appDiscoverUs />
    <appKnowUs />
    <appTeam />
  </div>
</template>


<script>
import appContactUs from '@/views/frontoffice/contactUs'
import appTeam from '@/components/frontoffice/sepri/team'
import appKnowUs from '@/components/frontoffice/sepri/knowUs'
import appDiscoverUs from '@/components/frontoffice/sepri/discoverUs'
import appTimeline from '@/components/frontoffice/sepri/timeline'
import appWorldMap from '@/components/frontoffice/sepri/worldMap'
import appAbout from '@/components/frontoffice/sepri/about'
import appSlider from '@/components/frontoffice/sepri/slider'
import appHighlight from '@/components/frontoffice/common/highlight'
import metaMixin from '@/mixins/meta'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    metaMixin
  ],
  components: {
    appContactUs,
    appSlider,
    appAbout,
    appTimeline,
    appWorldMap,
    appDiscoverUs,
    appKnowUs,
    appTeam,
    appHighlight,
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
  }
}
</script>