 <template>
  <div id="testimonials" class="row vh-lg-100 bg-image" v-if="!isLoading">
    <div class="col text-center">
      <div class="row align-items-center justify-content-center">
        <div class="col-10 col-lg-8 py-5">
          <p class="text-primary fs-40 font-weight-bold">{{ $t('testimonials.title') }}</p>
          <p class="text-grey-2 fs-14">{{ $t(`testimonials.summary_${route}`) }}</p>
        </div>
      </div>
      <div class="row align-items-start justify-content-center">
        <div class="col-xl-8 col-md-9 col-sm-10">
          <b-carousel
            class="carousel-testimonials"
            :interval="5000"
            indicators
            background="white"
            img-width="1024"
          >
            <b-carousel-slide img-blank v-for="testimonial in testimonials" :key="testimonial.id">
              <div class="row h-100 justify-content-center">
                <div class="col-2 text-grey-1 position-relative">
                  <img
                    src="assets/images/common/testimonials/testimonials-top.png"
                    class="position-absolute position-top testimonial-quotes"
                  />
                </div>
                <div class="col-8 align-self-center p-0">
                  <p
                    class="mb-3 text-grey-1 fs-testimonials"
                  >{{ testimonial.message | localize }}</p>
                  <p
                    class="m-0 text-grey-2 fs-16 font-weight-bolder"
                  >{{ testimonial.name }}</p>
                  <p class="m-0 text-grey-1 fs-16">{{ testimonial.job | localize }}, {{ testimonial.company }}</p>
                </div>
                <div class="col-2 text-grey-1 position-relative text-left">
                  <img
                    src="assets/images/common/testimonials/testimonials-bottom.png"
                    class="position-absolute position-bottom testimonial-quotes"
                  />
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import apiTestimonials from '@/api/testimonials'

export default {
  data: () => ({
    apiTestimonials,
    testimonials: [],
    isLoading: true
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.apiTestimonials.all(this.filtersTestimonials)
      if (response && response.data && response.data.list) {
        this.testimonials = response.data.list
        this.isLoading = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
  },
  computed: {
    filtersTestimonials() {
      return {
        sort: 'order,asc'
      }
    },
    route() {
      const route = this.$route.name
      return route.replace(/-/g, '_')
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.position-top {
  top: 20%;
  @include media-breakpoint-down(md) {
    top: 10%;
    left: 0;
  }
}

.position-bottom {
  bottom: 20%;
  @include media-breakpoint-down(md) {
    bottom: 10%;
    right: 0;
  }
}

.bg-image {
  background-image: url(/assets/images/common/testimonials/background.jpg);
  background-size: auto 100%;
}

.fs-testimonials {
  font-size: $fs-18;
  @include media-breakpoint-down(lg) {
    font-size: $fs-16;
  }
}


</style>
<style lang="scss" >
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.testimonial-quotes {
  @include media-breakpoint-down(sm) {
    width: 1.8rem;
  }
}

.carousel-testimonials .carousel-inner .carousel-item .carousel-caption {
  left: 5%;
  right: 5%;
  top: 1%;
}

.carousel-testimonials .carousel-indicators li {
  background-color: $grey-1;
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;
  
  @include media-breakpoint-down(sm) {
      height: 0.4375rem;
      width: 0.4375rem;
  }
  
}
.carousel-testimonials .carousel-indicators .active {
  background-color: $primary;
}

.carousel-testimonials .carousel-inner .carousel-item > img{
  height: calc(100vh * 0.7);

  @include media-breakpoint-down(sm) {
      height: 36rem;
  }

  @include media-breakpoint-only(md) {
      height: $h-body-md;
  }
}
</style>