<template>
  <div>
    <div id="app" v-if="!isLoading && !isIE">
        <app-header
          v-if="notInBackoffice"
          :pages="pages"
        />
        <menu-mobile :pages="pages"/>
        <navbar-mobile />
        <router-view />
        <app-footer
          v-if="notInBackoffice"
          :pages="pages"
        />
    </div>
    <internetExplorer v-else-if="isIE" />
  </div>
</template>

<script>
import appHeader from '@/components/frontoffice/header/header'
import appFooter from '@/components/frontoffice/footer/footer'
import internetExplorer from '@/views/frontoffice/internetExplorer'
import mobile from '@/views/frontoffice/mobile'
import navbarMobile from '@/components/frontoffice/header/mobile/navbar'
import menuMobile from '@/components/frontoffice/header/mobile/menu'
import api from '@/api/pages'
import store from '@/store'

export default {
  data: () => ({
    api,
    pages: [],
    isLoading: true,
  }),
  components: {
      appHeader,
      appFooter,
      internetExplorer,
      mobile,
      navbarMobile,
      menuMobile
  },
  mounted() {
    if (!this.isIE) {
      this.init()
    }
  },
  methods: {
    async init() {
      const response = await this.api.all()
      if (response && response.data && response.data.list) {
        this.pages = response.data.list
        store.dispatch('pages/set', this.pages)
        this.isLoading = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    }
  },
  beforeDestroy (){
    store.dispatch('pages/reset')
  },
  computed: {
    notInBackoffice () {
      return this.$route.name && !this.$route.name.includes('admin')
    },
    isIE () {
      return (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof browser !== "undefined" && browser.msie == 1))
    },
  }
}
</script>
