<template>
  <ul class="p-0 pt-4 d-flex justify-content-between fs-14">
    <template v-for="page in headerPages">
      <router-link
        v-if="notSolution(page) && !isTraining(page)"
        :key="page.id"
        :to="{ name: page.route, params: { lang: $i18n.locale, path: translate(page.url)  } }"
        class="text-uppercase position-relative d-flex pages"
      >{{ page.title | localize }}</router-link>
      <a
        v-if="isTraining(page)"
        :key="page.id"
        target="_black"
        href="https://www.training.sepri.pt/"
        class="text-uppercase position-relative d-flex pages"
      >{{ page.title | localize }}</a>
      <div v-if="isSolution(page)" :key="page.id">
        <div class="dropdown">
          <router-link
            :key="page.id"
            :to="{ name: page.route, params: { lang: $i18n.locale, path: translate(page.url)  } }"
            class="dropbtn text-uppercase position-relative d-flex"
            :class="isSolutionActive"
          >
            {{ page.title | localize }}
            <i class="anticon icon-caretdown d-flex align-items-center fs-10 pl-2"></i>
          </router-link>
          <div class="dropdown-content px-5 pt-4 pb-2">
            <router-link
              class="pb-2"
              v-for="solution in solutions"
              :key="solution.id"
              :to="{ name: solution.route, params: { lang: $i18n.locale, path: translate(solution.url) } }"
            >{{ solution.title | localize }}</router-link>
          </div>
        </div>
      </div>
    </template>
  </ul>
</template>


<script>
export default {
  props: {
    pages: {
      type: Array,
      required: true
    }
  },
  methods: {
    notSolution(page) {
      return page.is_solution === false && page.route !== 'solutions'
    },
    isSolution(page) {
      return page.route === 'solutions'
    },
    isTraining(page) {
      return page.route === 'training'
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    headerPages() {
      return this.pages.filter(page => page.is_header).sort((a, b) => a.order - b.order)
    },
    solutions() {
      return this.pages.filter(page => page.is_solution)
    },
    isSolutionActive() {
      return this.solutions.some(e => e.route === this.$route.name)
        ? 'active'
        : ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@vars';

a.pages{
  font-weight: $font-weight-bold;
  color: $secondary;
  text-decoration: none;
  margin: 0 1rem;

  &:hover,
  &.active {
    font-weight: $font-weight-bolder;
    color: $primary;
    
    &::before {
      position: absolute;
      top: -5px;
      background-color: $primary;
      width: 100%;
      height: 2px;
      content: "";
    }
  }
}

.dropbtn {
  font-weight: 600;
  color: $secondary;
  text-decoration: none;
  margin: 0 1rem;

  &:hover,
  &.active {
    font-weight: 700;
    color: $primary;

    &::before {
      position: absolute;
      top: -5px;
      background-color: $primary;
      width: 80%;
      height: 2px;
      content: "";
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  &:hover .dropdown-content {
    display: block;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $white;
  z-index: 11;
  width: max-content;

    & a {
    display: block;
    font-weight: $font-weight-bolder;
    font-size: $fs-14;
    color: $secondary;
    text-decoration: none;

    &.active,
    &:hover {
      background-color: white;
      color: $primary;
    }
  }
}

</style>