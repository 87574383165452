import partnersList from '@/views/backoffice/partners/list'
import partnersEdit from '@/views/backoffice/partners/edit'

export default [
  {
    path: 'partners/create',
    name: 'admin-partners-create',
    component: partnersEdit
  },
  {
    path: 'partners',
    name: 'admin-partners-list',
    component: partnersList
  },
  {
    path: 'partners/:id',
    name: 'admin-partners-edit',
    component: partnersEdit
  }
]