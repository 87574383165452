require('./bootstrap');

import router from '@/router'
import messages from '@/i18n'
import app from '@/app.vue'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import VueAxios from 'vue-axios'
import VueAuth from '@websanova/vue-auth'
import Toasted from 'vue-toasted'
import ImageUploader from 'vue-image-upload-resize'
import VueScrollTo from 'vue-scrollto'
import store from '@/store'
import VeeValidate from 'vee-validate'
import validationMessages from 'vee-validate/dist/locale/pt_PT'
import SocialSharing from 'vue-social-sharing'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Multiselect from 'vue-multiselect'

import { locales } from '@/constants/settings'

library.add(faBars)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(VueScrollTo)
Vue.use(SocialSharing)

Vue.component('multiselect', Multiselect)

Vue.prototype.window = window;

Vue.router = router;

router.beforeEach(function (to, from, next) {

  if (to.path.includes('admin')) {
    return next()
  }

  if (locales.includes(to.params.lang)) {
    to.params.lang = i18n.locale = to.params && to.params.lang
      ? to.params.lang.toLowerCase()
      : 'pt'
    next()
  } else {
    const lang = (window.navigator.userLanguage || window.navigator.language).split('-').shift()
    if (locales.includes(lang)) {
      to.params.lang = i18n.locale = lang
      next({ name: 'home', params: { lang: lang } })
    }
    next({ name: 'home', params: { lang: 'pt' } })
  }

})


Vue.use(VueI18n)

import bearer from '@websanova/vue-auth/drivers/auth/bearer.js'
import http from '@websanova/vue-auth/drivers/http/axios.1.x.js'
import routerVueRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x.js'

Vue.use(VueAuth, {
  auth: bearer,
  http: http,
  router: routerVueRouter,
  authRedirect: { path: '/admin/login' },
  notFoundRedirect: { path: '/404' },
  forbiddenRedirect: { path: '/403' },
  loginData: {
    url: '../api/auth/login',
    method: 'POST',
    redirect: '/admin/dashboard',
    fetchUser: true
  },
  registerData: {
    url: '../api/auth/register',
    method: 'POST',
    redirect: { name: 'login' }
  },
  logoutData: {
    url: '../api/auth/logout',
    method: 'POST',
    redirect: { name: 'login' },
    makeRequest: false
  },
  fetchData: {
    url: '../api/auth/user',
    method: 'GET',
    enabled: true
  },
  refreshData: {
    url: '../api/auth/refresh',
    method: 'POST',
    enabled: true,
    interval: 30
  }
})

Vue.use(Toasted, {
  duration: 3000,
  singleton: true
})

Vue.use(ImageUploader)

const i18n = new VueI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  messages
})

Vue.use(VeeValidate, {
  events: 'change',
  classes: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'border-danger'
  },
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
  locale: 'pt',
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    pt: validationMessages
  }
});


Vue.filter('localize', function (value) {
  return value[i18n.locale]
    ? value[i18n.locale]
    : value['pt'];
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(app)
}).$mount('#app')

Vue.prototype.$axios = axios

axios.interceptors.request.use(config => {
  config.headers['X-CSRF-TOKEN'] = document.head.querySelector(
    "meta[name='csrf-token']"
  );
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers['Locale'] = i18n.locale
  return config
})

export default i18n;