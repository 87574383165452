
<template>
  <div>
    <b-form v-if="!loading">
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Geral
            </b-card-header>
            <b-card-body>
                <b-form-group label="Email">
                <b-input
                    v-model="item.email"
                    name="email"
                    type="email"
                    required
                    placeholder="lorem@ipsum.set"
                />
                </b-form-group>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Adicional
            </b-card-header>
            <b-card-body>
              <b-form-group label="Áreas">
                <b-select
                  v-model="selectedAreas"
                  :options="areas"
                  @change="updateAreas"
                  required
                  multiple
                />
              </b-form-group>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          class="justify-content-end d-flex"
        >
          <b-button
            @click.prevent.stop="submit"
            variant="primary"
            class="mt-3"
          >
            Submeter
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <loading />
    </div>
  </div>
</template>

<script>
import loading from '@/components/backoffice/common/loading'
import model from '@/models/subscribers'
import { actions } from '@/mixins/edit'
import api from '@/api/subscribers'
import areasApi from '@/api/areas'

export default {
  mixins: [actions],
  components: {
    loading,
  },
  data: () => ({
    api,
    areasApi,
    model: JSON.parse(JSON.stringify(model.empty)),
    rules: model.rules,
    areas: [],
    selectedAreas: []
  }),
  computed: {

  },
  created () {
    this.init ()
  },
  methods: {
    async init () {
      if (this.$route.params.id) {
        await Promise.all([this.getItem(), this.getAreas()])
        this.selectedAreas = this.item.areas.map(item => item.id)
      } else {
        this.initForm(this.model)
        await this.getAreas()
      }
      this.updateAreas(this.selectedAreas)
      this.loading = false
    },
    async getAreas () {
      try {
        const response = await this.areasApi.all()
        if (response.data) {
          this.areas = response.data.list.map(item => ({ text: item.title.pt, value: item.id }))
        } else {
          console.error('Response data not found', response)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    updateAreas (item) {
      this.item.areas = item.join(',')
    }
  }
}
</script>