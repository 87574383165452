<template>
  <div class="row pb-5 bg-color" v-if="!isLoading">
    <div class="col px-offset px-lg-20">
      <div class="row justify-content-center align-items-center py-5 pb-md-0 pt-md-15">
        <div class="col-12 col-md-10 text-center align-self-center">
          <p
            class="fs-40 text-primary text-uppercase font-weight-bold"
          >{{$t('sepri.timeline.title')}}</p>
          <p class="fs-16 text-grey-1">{{$t('sepri.timeline.summary')}}</p>
        </div>
      </div>
      <div class="row height align-items-center">
        <div class="col-2 col-md-1">
          <div  class="text-center cursor-pointer" v-if="this.hasPrev" @click.stop="prev">
            <i class="anticon icon-left fs-40 arrow-color" />
          </div>
        </div>
        <div class="col-8 col-md-10">
          <div class="row">
            <template v-for="(up, index) in upHistories">
              <div class="col-6 col-md-3 col-lg-2 border-primary border-bottom-right-width" :key="up.id">
                <p class="fs-timeline text-grey-2 font-weight-bold">{{ up.title | localize }}</p>
                <p class="fs-58 text-secondary font-weight-bold text-center m-0">{{ up.year }}</p>
              </div>
              <div class="col p-0 border-secondary border-bottom-width" :key="`up ${index}`">
                 <div class="triangle-top-left"></div>
              </div>
            </template>
          </div>
          <div class="row">
            <template v-for="(down, index) in downHistories">
              <div class="col p-0 align-self-end" :key="`down ${index}`">
              </div>
              <div class="col-6 col-md-3 col-lg-2 border-secondary border-right-width position-relative" :key="down.id">
                <p class="fs-58 text-secondary font-weight-bold text-center m-0">{{ down.year }}</p>
                <p class="fs-timeline text-grey-2 font-weight-bold">{{ down.title | localize }}</p>
                <div class="triangle-bottom-left position-absolute bottom-left"></div>
              </div>
            </template>
          </div>
        </div>
        <div class="col-2 col-md-1">
          <div class="col-2 col-md-1 text-center cursor-pointer position-relative" v-if="this.hasNext" @click.stop="next">
            <i class="anticon icon-right fs-40 arrow-color" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiHistories from '@/api/histories'

export default {
   data: () => ({
    apiHistories,
    list: [],
    histories: [],
    upHistories: [],
    downHistories: [],
    isLoading: true,
    start: 0,
    end: 6,
    show: 0,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.apiHistories.all(this.filtersHistories)
      if (response && response.data && response.data.list) {
        this.list = response.data.list
        this.end = this.takeItems
        this.histories = this.getHistories
        this.upHistories = this.getUpHistories
        this.downHistories = this.getDownHistories
        this.isLoading = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    next() {
      this.show = this.showItems
      if (this.hasNext) {
        this.start = this.start + this.show
        this.end = this.end + this.show
        this.histories = this.getHistories
        this.upHistories = this.getUpHistories
        this.downHistories = this.getDownHistories
      }
    },
    prev() {
      if (this.hasPrev) {
        this.start = this.start - this.show
        this.end = this.end - this.show
        this.histories = this.getHistories
        this.upHistories = this.getUpHistories
        this.downHistories = this.getDownHistories
      }
    },
    showTriangleDown(index){
      return index === 0 ? false : true 
    }
  },
  computed: {
     filtersHistories() {
      return {
        sort: 'order,asc'
      }
    },
    getHistories() {
      return this.list.slice(this.start, this.end)
    },
    getUpHistories() {
      return this.histories.filter((el, ind) => ind % 2 === 0)
    },
    getDownHistories() {
      return this.histories.filter((el, ind) => ind % 2 !== 0)
    },
    showItems() {
      return (this.list.length - this.end) < 2 ? 1 : 2
    },
    takeItems() {
      if (window.innerWidth < 768) {
        return 2
      } else if (window.innerWidth < 992) {
        return 4
      } else {
        return 6
      }
    },
    hasNext() {
      return this.end < this.list.length
    },
    hasPrev() {
      return this.start > 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.arrow-color {
  color: rgba($black, 0.25);
}

.bg-color {
    background-color: rgba($blue-2, 0.1)
}

.border-bottom-right-width {
  border-width: 0rem 0.1rem 0.5rem 0rem;
  border-style: solid;
}

.border-right-width {
  border-width: 0rem 0.1rem 0rem 0rem;
  border-style: solid;
}

.border-bottom-width {
  border-width: 0rem 0rem 0.5rem 0rem;
  border-style: solid;
}

.triangle-bottom-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.8em 0 0 0.8rem;
  border-color: transparent transparent transparent $secondary;
}

.triangle-top-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.8rem 0.8rem 0 0;
  border-color: $primary transparent transparent transparent;
}

.bottom-left {
  bottom:0%;
  left: 100%;
}

.height {
  height: 35rem;

  @include media-breakpoint-down(lg) {
    height: 26rem;
  }

  @include media-breakpoint-down(sm) {
    height: 28rem;
  }
}

.fs-timeline {
  font-size: $fs-14;
  @include media-breakpoint-down(md) {
    font-size: $fs-12;
  }
}
</style>