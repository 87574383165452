<template>
  <div class="row justify-content-center">
    <div class="col-6 col-xl-5">
      <p class="fs-14 text-secondary text-uppercase font-weight-bolder">SEPRI</p>
      <ul class="pages p-0">
        <router-link
          v-for="page in headerPages"
          :key="page.id"
          :to="{ name: page.route, params: { lang: $i18n.locale, path: translate(page.url) } }"
          class="d-flex fs-14 text-decoration-none py-1"
        >{{ page.title | localize }}</router-link>
      </ul>
    </div>
    <div class="col-6 col-xl-5">
      <p
        class="fs-14 text-secondary text-uppercase font-weight-bolder"
      >{{ $t('footer.informations') }}</p>
      <ul class="p-0">
        <template v-for="page in footerPages">
          <a
            :key="page.id"
            v-if="isClientArea(page)"
            class="d-flex fs-14 text-decoration-none py-1"
            href="https://sepri.safemed.pt"
          >{{ $t('header.client_area') }}</a>
          <p
            :key="page.id"
            v-else-if="isQualityPolicy(page)"
            @click.stop="getQualityPolicy(page)"
            class="d-flex fs-14 text-secondary py-1 m-0 cursor-pointer"
          >{{ page.title | localize }}</p>
          <router-link
            :key="page.id"
            v-else
            :to="{ name: page.route, params: { lang: $i18n.locale, path: translate(page.url) } }"
            class="d-flex fs-14 text-decoration-none py-1"
          >{{ page.title | localize }}</router-link>
        </template>
      </ul>
    </div>
    <div class="col-12 col-xl-10 position-absolute bottom-0">
      <img
        src="assets/images/logos/norte2020-desktop.svg"
        class="img-fluid object-fit-contain"
        :alt="$t('logo_norte2020_image_alt')"
        :title="$t('logo_norte2020_image_title')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      required: true
    }
  },
  methods: {
    translate(value) {
      return this.$options.filters.localize(value)
    },
    isQualityPolicy(page) {
      return page.route === 'quality-policy'
    },
    isClientArea(page) {
      return page.route === 'client-area'
    },
    getQualityPolicy(page) {
      const url =  page.file[0].url
      window.open(url, '_blank')
    }
  },
  computed: {
    headerPages() {
      return this.pages.filter(page => page.is_header && !page.is_solution).sort((a, b) => a.order - b.order)
    },
    footerPages() {
      return this.pages.filter(page => page.is_footer && !page.is_header)
    },
  }
}
</script>


<style lang="scss" scoped>
@import '~@vars';
a {
  color: $secondary;
  &:hover {
    color: $secondary;
  }
}

.pages {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.bottom-0 {
  bottom: 0rem;
}
</style>