import home from '@/views/frontoffice/home'
import careers from '@/views/frontoffice/careers'
import contacts from '@/views/frontoffice/contacts'
import media from '@/views/frontoffice/media'
import news from '@/views/frontoffice/news'
import workSafetyHealth from '@/views/frontoffice/workSafetyHealth'
import sepri from '@/views/frontoffice/sepri'
import accidentMedicine from '@/views/frontoffice/accidentMedicine'
import environmentalStudiesLaboratory from '@/views/frontoffice/environmentalStudiesLaboratory'
import healthcareConsulting from '@/views/frontoffice/healthcareConsulting'
import healthServices24 from '@/views/frontoffice/healthServices24'
import solutions from '@/views/frontoffice/solutions'
import privacyPolicy from '@/views/frontoffice/privacyPolicy'
import subscription from '@/views/frontoffice/subscription'
import projects from '@/views/frontoffice/projects'
import project from '@/views/frontoffice/project'

function validatePath (to, next, pt, en){
  if( (to.params.lang === 'pt' && to.params.path === pt) || ((to.params.lang === 'en' && to.params.path === en)) ) {
    next()
  }
  else {
    next({ name: 'notFound'})
  }
}

export default [
  {
    path: '/:lang?',
    name: 'home',
    component: home
  },
  {
    path: '/:lang/:path(sepri|sepri_en)',
    name: 'sepri',
    component: sepri,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'sepri', 'sepri_en')
    }
  },
  {
    path: '/:lang/:path(solucoes|solutions)',
    name: 'solutions',
    component: solutions,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'solucoes', 'solutions')
    }
  },
  {
    path: '/:lang/:path(projetos|projects)',
    name: 'projects',
    component: projects,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'projetos', 'projects')
    }
  },
  {
    path: '/:lang/:path(projeto|project)/:slug',
    name: 'project',
    component: project,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'projeto', 'project')
    }
  },
  {
    path: '/:lang/:path(media|media_en)',
    name: 'media',
    component: media,
    query: { title: ':title?' },
    beforeEnter(to, from, next) {
      validatePath(to, next, 'media', 'media_en')
    }
  },
  {
    path: '/:lang/:path(carreiras|careers)',
    name: 'careers',
    component: careers,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'carreiras', 'careers')
    }
  },
  {
    path: '/:lang/:path(contactos|contacts)',
    name: 'contacts',
    component: contacts,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'contactos', 'contacts')
    }
  },
  {
    path: '/:lang/:path(seguranca-saude-trabalho|work-safety-health)',
    name: 'work-safety-health',
    component: workSafetyHealth,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'seguranca-saude-trabalho', 'work-safety-health')
    }
  },
  {
    path: '/:lang/:path(servicos-saude-24|health-services-24)',
    name: 'health-services-24',
    component: healthServices24,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'servicos-saude-24', 'health-services-24')
    }
  },
  {
    path: '/:lang/:path(medicina-sinistrados|accident-medicine)',
    name: 'accident-medicine',
    component: accidentMedicine,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'medicina-sinistrados', 'accident-medicine')
    }
  },
  {
    path: '/:lang/:path(laboratorio-estudos-ambientais|environmental-studies-laboratory)',
    name: 'environmental-studies-laboratory',
    component: environmentalStudiesLaboratory,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'laboratorio-estudos-ambientais', 'environmental-studies-laboratory')
    }
  },
  {
    path: '/:lang/:path(healthcare-consulting|healthcare-consulting_en)',
    name: 'healthcare-consulting',
    component: healthcareConsulting,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'healthcare-consulting', 'healthcare-consulting_en')
    }
  },
  {
    path: '/:lang/:path(noticia|news)/:slug',
    name: 'news',
    component: news,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'noticia', 'news')
    }
  },
  {
    path: '/:lang/:path(subscricao|subscription)/:key',
    name: 'subscription',
    component: subscription,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'subscricao', 'subscription')
    }
  },
  {
    path: '/:lang/:path(area-cliente|client-area)',
    name: 'client-area',
    component: home,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'area-cliente', 'client-area')
    }
  },
  {
    path: '/:lang/:path(politica-privacidade|privacy-policy)',
    name: 'privacy-policy',
    component: privacyPolicy,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'politica-privacidade', 'privacy-policy')
    }
  },
  {
    path: '/:lang/:path(politica-qualidade|quality-policy)',
    name: 'quality-policy',
    component: home,
    beforeEnter(to, from, next) {
      validatePath(to, next, 'politica-qualidade', 'quality-policy')
    }
  },
  {
    path: '/404',
    name: 'notFound',
    component: home
  },

];
