import historiesList from '@/views/backoffice/histories/list'
import historiesEdit from '@/views/backoffice/histories/edit'

export default [
  {
    path: 'histories/create',
    name: 'admin-histories-create',
    component: historiesEdit
  },
  {
    path: 'histories',
    name: 'admin-histories-list',
    component: historiesList
  },
  {
    path: 'histories/:id',
    name: 'admin-histories-edit',
    component: historiesEdit
  }
]