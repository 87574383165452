<template>
  <div class="w-100">
    <b-form-group label="Título">
      <b-input
        v-model="item.title[locale]"
        name="title"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
    <b-form-group label="Subtítulo">
      <b-input
        v-model="item.subtitle[locale]"
        name="subtitle"
        type="text"
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
    <b-form-group label="Resumo">
      <editor
        index="summary"
        :isRelationship="true"
        :locale="locale"
      />
    </b-form-group>
    <b-form-group label="Posição">
      <b-input
        v-model="item.order"
        type="number"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import editor from '@/components/backoffice/common/editor'

export default {
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  components: {
    editor
  },
  computed: {
    ...mapGetters({
      item: 'form/relationshipFields'
    })
  },
}
</script>