import mutators from '@/models/index'

const fields = [
  {
    name: 'Id',
    field: 'id',
    visible: true,
    sortable: true,
    rules: {
      required: false
    }
  },
  {
    name: 'Título',
    field: 'title',
    visible: true,
    sortable: true,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Data Início',
    field: 'starts_at',
    visible: true,
    sortable: true,
    rules: {
      required: false
    }
  },
  {
    name: 'Data Fim',
    field: 'ends_at',
    visible: true,
    sortable: true,
    rules: {
      required: false
    }
  },
]

const rules = mutators.rules(fields)
const empty = mutators.empty(fields)

export default {
  fields,
  rules,
  empty,
}