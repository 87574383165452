<template>
  <div class="backoffice d-flex">
    <backoffice-sidebar />
    <main
      role="main"
      class="container"
    >
      <backoffice-header />
      <router-view />
    </main>
    <backoffice-footer />
  </div>
</template>

<script>
import backofficeHeader from '@/components/backoffice/layout/header'
import backofficeSidebar from '@/components/backoffice/layout/sidebar'
import backofficeFooter from '@/components/backoffice/layout/footer'

export default {
  components: {
    backofficeHeader,
    backofficeSidebar,
    backofficeFooter
  }
}
</script>

<style lang="scss">
  @import "~@vars";
  #fileInput {
    font-size: $fs-12;
    width: 100%;
  }

  .backoffice {
    min-height: 100vh;

    @media (max-width: 767px) {
      min-height: unset;
      flex-direction: column;
    }
  }

  main.container {
    padding-bottom: 5.625rem;

    @media (min-width: 1200px) {
        max-width: 100%;
        min-height: 43.75rem;
    }
  }
</style>
