import highlightsList from '@/views/backoffice/highlights/list'
import highlightsEdit from '@/views/backoffice/highlights/edit'

export default [
  {
    path: 'highlights/create',
    name: 'admin-highlights-create',
    component: highlightsEdit
  },
  {
    path: 'highlights',
    name: 'admin-highlights-list',
    component: highlightsList
  },
  {
    path: 'highlights/:id',
    name: 'admin-highlights-edit',
    component: highlightsEdit
  }
]