import newsRoutes from '@/router/backoffice/admin/news'
import bannersRoutes from '@/router/backoffice/admin/banners'
import areasRoutes from '@/router/backoffice/admin/areas'
import careersRoutes from '@/router/backoffice/admin/careers'
import historiesRoutes from '@/router/backoffice/admin/histories'
import pagesRoutes from '@/router/backoffice/admin/pages'
import partnersRoutes from '@/router/backoffice/admin/partners'
import testimonialsRoutes from '@/router/backoffice/admin/testimonials'
import teamsRoutes from '@/router/backoffice/admin/teams'
import usersRoutes from '@/router/backoffice/admin/users'
import subscribersRoutes from '@/router/backoffice/admin/subscribers'
import highlightsRoutes from '@/router/backoffice/admin/highlights'
import projectsRoutes from '@/router/backoffice/admin/projects'
import dashboard from '@/views/backoffice/dashboard'
import contactRoutes from '@/router/backoffice/admin/contact'

export default [
  ...newsRoutes,
  ...bannersRoutes,
  ...areasRoutes,
  ...careersRoutes,
  ...historiesRoutes,
  ...pagesRoutes,
  ...partnersRoutes,
  ...testimonialsRoutes,
  ...teamsRoutes,
  ...subscribersRoutes,
  ...highlightsRoutes,
  ...projectsRoutes,
  ...usersRoutes,
  ...contactRoutes,
  {
    path: 'dashboard',
    name: 'admin-dashboard',
    component: dashboard
  }
]
