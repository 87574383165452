 <template>
  <div class="container-fluid">
    <appContactUs />
    <appHighlight :page="page"/>
    <appNavigation />
    <appHeader :solutions="solutions" :page="page" />
    <appSepri />
    <appAbout />
    <appTestimonials />
    <appFeatured v-if="!isLoadingNews" :news="news" class="d-none d-lg-block"/>
    <appFeaturedMobile v-if="!isLoadingNews" :news="news" class="d-lg-none"/>
  </div>
</template>


<script>
import apiNews from '@/api/news'
import appHeader from '@/components/frontoffice/home/header'
import appSepri from '@/components/frontoffice/home/sepri'
import appAbout from '@/components/frontoffice/home/about'
import appTestimonials from '@/components/frontoffice/common/testimonials'
import appFeatured from '@/components/frontoffice/common/featured'
import appFeaturedMobile from '@/components/frontoffice/common/featuredMobile'
import appHighlight from '@/components/frontoffice/common/highlight'
import appNavigation from '@/components/frontoffice/home/navigation'
import appContactUs from '@/views/frontoffice/contactUs'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin,
  ],
  data: () => ({
    apiNews,
    isLoadingNews: true,
    news: []
  }),
  components: {
    appHeader,
    appSepri,
    appAbout,
    appTestimonials,
    appFeatured,
    appFeaturedMobile,
    appNavigation,
    appContactUs,
    appHighlight,
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const response = await this.apiNews.all(this.filtersNews)
      if (response && response.data && response.data.list) {
        this.news = response.data.list
        this.isLoadingNews = false && this.news.length > 0
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    filtersNews() {
      return {
        sort: 'published_at,desc',
        skip: '0',
        take: '3',
        isFeatured: 'true',
      }
    },
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    solutions() {
      return this.pages.filter(page => page.is_solution)
    }
  }
};
</script>