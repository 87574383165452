import mutators from '@/models/index'

const fields = [
  {
    name: 'Id',
    field: 'id',
    visible: true,
    sortable: true,
    rules: {
      required: false
    }
  },
  {
    name: 'Título',
    field: 'title',
    visible: true,
    sortable: true,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Descrição',
    field: 'summary',
    visible: false,
    sortable: true,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Título Motor Busca',
    field: 'meta_title',
    visible: false,
    sortable: false,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Descrição Motor Busca',
    field: 'meta_description',
    visible: false,
    sortable: false,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Palavras Chave Motor Busca',
    field: 'meta_keywords',
    visible: false,
    sortable: false,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Menu Superior',
    field: 'is_header',
    visible: true,
    sortable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Menu Inferior',
    field: 'is_footer',
    visible: true,
    sortable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Posição',
    field: 'position',
    visible: false,
    sortable: true,
    rules: {
      required: false
    }
  },
  {
    name: 'Data Criação',
    field: 'created_at',
    visible: false,
    sortable: true,
    rules: {
      required: false
    }
  }
]

const rules = mutators.rules(fields)
const empty = mutators.empty(fields)

export default {
  fields,
  rules,
  empty,
}