<template>
  <div class="mb-10">
    <div class="row px-offset pb-5 justify-content-center">
      <div class="col-12 col-lg-8 text-center">
        <p class="fs-40 text-grey-2 font-weight-bold">{{ $t('services.title') }}</p>
        <p class="fs-14 text-grey-1">{{ $t(`services.summary_${route}`) }}</p>
      </div>
    </div>
    <div class="row px-offset">
      <div class="col px-lg-20">
        <div class="row justify-content-center">
          <div v-for="service in services" :key="service.id" class="col-12 col-md-6 mb-5 p-0 px-lg-4">
            <div
              class="h-100 bg-white position-relative shadow-parent text-break"
            >
              <h2
                class="fs-22 text-grey-2 pt-3 pl-3 pr-3"
              >{{ service.title | localize }}</h2>
              <p v-if="service.subtitle"
                class="fs-14 text-grey-2 pt-1 pl-3 pr-3 m-0"
              >{{ service.subtitle | localize }}</p>
              <b-button
                @click.stop="toggle(service.id)"
                class="fs-12 shadow-none bg-white border-0 text-primary p-3 mb-0 text-decoration-none cursor-pointer"
              >
                {{ $t('work_safety_health.know_more') }}
                <i
                  class="anticon fs-12 ml-3"
                  :class="arrowDirection(service.id)"
                ></i>
              </b-button>
              <div
                class="bg-white position-absolute sepri-card shadow-child w-100"
                :class="display(service.id)"
              >
                <div>
                  <p
                    class="fs-14 text-grey-1 p-3 m-0 bg-white"
                    v-html="translate(service.summary)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isDisplay: 'invisible',
    arrow: 'icon-arrowright',
    currentService: null,
  }),
  props: {
    services: {
      type: Array,
      required: true
    },
  },
  methods: {
    toggle(id) {
      this.currentService = id
      this.isDisplay =
        this.isDisplay === 'invisible' ? 'visible z-100' : 'invisible'
      this.arrow =
        this.arrow === 'icon-arrowright' ? 'icon-arrowdown' : 'icon-arrowright'
    },
    display(id) {
      return id === this.currentService ? this.isDisplay : 'invisible'
    },
    arrowDirection(id) {
      return id === this.currentService ? this.arrow : 'icon-arrowright'
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    route() {
      const route = this.$route.name
      return route.replace(/-/g, '_')
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';
.sepri-card {
  transform: translateY(-0.5rem);
}

.shadow-parent {
  box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.15);
}

.shadow-child {
  box-shadow: 0rem 0.625rem 0.5rem rgba(0, 0, 0, 0.15);
}
</style>