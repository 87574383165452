export const actions = {
  data: () => ({
    loading: true,
    totalRows: 0,
    perPage: 10,
    currentPage: 1,
  }),
  computed: {
    count () {
      return his.items != null
       ?  this.items.length
       : 0
    },
    formatFilterData () {
      return {
        skip:  (this.currentPage - 1) * this.perPage,
        take: this.perPage,
        sort: `${this.sortBy},${this.sortDirection}`,
        trash: 0
      }
    },
    perPageIndicator () {
      return `Por Página: ${this.perPage}`
    }
  },
  methods: {
    async getList (currentPage = null) {
      this.currentPage = currentPage ?? this.currentPage

      try {
        const response = await this.api.all(this.formatFilterData)
        if (response.data) {
          this.items = response.data.list
          this.totalRows = response.data.total
        } else {
          console.error('Response data not found', response)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    async removeItem (item) {
      try {
        const response = await this.api.delete(item.id)
        if (response.data) {
          item.deleted_at = Date.now()
          this.items = response.data.permanent ? this.items.filter(part => part.id !== item.id) : this.items
          this.$toasted.success('Removido')
        } else {
          console.error('Response data not found', response)
        }
      } catch (e) {
        console.error(e)
      }
      this.getList()
      this.loading = false
    },
    async recoverItem (item) {
      try {
        const response = await this.api.restore(item.id)
        if (response.data) {
          item.deleted_at = ''
          this.$toasted.success('Recuperado')
        } else {
          console.error('Response data not found', response)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    updatePerPage(perPage) {
      this.perPage = perPage
      this.getList()
    },
    updateVisibleFields(field) {
      if (field) {
        field.visible = !field.visible
      }

      this.visibleFields = [
        { key: 'checkbox', label: '' },
        ...Object.values(this.fields)
          .filter(item => item.visible)
          .flatMap(item => ({ key: item.field, label: item.name })),
        { key: 'actions', label: 'Acções' }
      ]
    },
    updateSortListBy(field) {
      if (field == this.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = field
      }

      this.getList()
    },
    async removeSelectedItems() {
      this.items
        .filter(item => item.selected)
        .forEach(item => this.removeItem(item))
    },
    toggleAllSelectedItems() {
      this.items = this.items.map(item => ({
        ...item,
        ...{ selected: !item.selected }
      }))
    },
    translateField (field, locale = 'pt') {
      return field && field[locale]
        ? field[locale]
        : ''
    },
    isDeletedAtField (field) {
      return field === 'deleted_at'
    },
    isDeleted (value) {
      return value && value !== ''
    }
  }
}
