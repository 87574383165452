import bannersList from '@/views/backoffice/banners/list'
import bannersEdit from '@/views/backoffice/banners/edit'

export default [
  {
    path: 'banners/create',
    name: 'admin-banners-create',
    component: bannersEdit
  },
  {
    path: 'banners',
    name: 'admin-banners-list',
    component: bannersList
  },
  {
    path: 'banners/:id',
    name: 'admin-banners-edit',
    component: bannersEdit
  }
]