const state = {
  news: {}
}

const getters = {
  news: (state) => state.news
}

const actions = {
	set ({ commit }, data) {

        commit('SET', data)
    },
    reset ({ commit }) {

        commit('RESET')
    },
}

const mutations = {
	SET: (state, data) => {
        state.news = data
    },
    RESET: (state) => {
        state.news = {}
    }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}