import { render, staticRenderFns } from "./discoverUs.vue?vue&type=template&id=8d71de26&scoped=true&"
var script = {}
import style0 from "./discoverUs.vue?vue&type=style&index=0&id=8d71de26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d71de26",
  null
  
)

export default component.exports