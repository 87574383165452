import newsList from '@/views/backoffice/news/list'
import newsEdit from '@/views/backoffice/news/edit'

export default [
  {
    path: 'news/create',
    name: 'admin-news-create',
    component: newsEdit
  },
  {
    path: 'news',
    name: 'admin-news-list',
    component: newsList
  },
  {
    path: 'news/:id',
    name: 'admin-news-edit',
    component: newsEdit
  }
]