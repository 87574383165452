<template>
  <div class="navigation z-100 position-fixed d-none d-lg-block">
    <div
     v-for="(section, key) in sections.sections"
      :key="`section ${key}`" 
      class="my-2 size text-center cursor-pointer text-decoration-none"
      @click.stop="setActive(section.name)"
    >
      <a>
        <img
          :src="section.icon"
          :alt="$t(`home.navigation.${section.name}`)"
          :title="$t(`home.navigation.${section.name}`)"
          class="navigation"
          :class="isActive(section.name)"
        />
      </a>
    </div>
  </div>
</template>


<script>
import sections from '@/constants/navigationHome'
export default {
  data: () => ({
    active: 'header',
    sections
  }),
  methods: {
    isActive(element) {
      return {
        active: this.active === element
      };
    },
    setActive(element) {
      this.active = element
      this.$scrollTo(`#${element}`)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.navigation {
  top: 35%;
  right: 2rem;
  opacity: 60%;
  &.active {
    opacity: 100%;
  }
  @include media-breakpoint-down(lg) {
    right: 0.5rem;
  }
}

.size {
  height: 2rem;
  width: 2rem;
}

</style>
