import mutators from '@/models/index'

const fields = [
  {
    name: 'Id',
    field: 'id',
    visible: true,
    sortable: true,
    rules: {
      required: false
    }
  },
  {
    name: 'Título',
    field: 'title',
    visible: true,
    sortable: true,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Ano',
    field: 'year',
    visible: false,
    sortable: true,
    translatable: false,
    rules: {
      required: true
    }
  },
  {
    name: 'Data Criação',
    field: 'created_at',
    visible: false,
    sortable: true,
    rules: {
      required: false
    }
  },
  {
    name: 'Posição',
    field: 'order',
    visible: false,
    sortable: true,
    translatable: false,
    rules: {
      required: true
    }
  },
]

const rules = mutators.rules(fields)
const empty = mutators.empty(fields)

export default {
  fields,
  rules,
  empty,
}