<template>
  <div class="container-fluid" v-if="!isLoading">
    <appHighlight :page="page"/>
    <appContactUs />
    <div class="d-none d-md-block">
      <div class="row vh-body">
        <div class="col bg-cover" :style="background(banner.file)">
          <div class="row px-offset h-100 justify-content-center align-items-end">
            <div class="col text-grey-2 fs-42 font-weight-bold text-center bg-white">
              <p class="mb-1 mt-4">{{ $t('careers.title_1') }}</p>
              <p class="mb-4 mt-1">{{ $t('careers.title_2') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none">
      <div class="row px-offset bg-cover height bg-mobile" :style="background(banner.file)">
      </div>
      <div class="row px-offset z-100 position">
            <div class="col-12 bg-white text-center">
              <p class="text-grey-2 fs-26 font-weight-bold text-center mb-1 mt-4">{{ $t('careers.title_1') }}</p>
              <p class="text-grey-2 fs-26 font-weight-bold text-center mb-4 mt-1">{{ $t('careers.title_2') }}</p>
            </div>
          </div>
    </div>
    
    <div class="row px-offset justify-content-center mt-0 mt-md-25">
      <div class="col-12 col-md-3 mt-4 text-center text-md-left">
        <hr />
        <div class="row my-4">
          <div class="col text-center">
            <b-button
              class="text-uppercase px-5 text-grey-2 bg-white border border-grey-4 shadow-sm font-weight-normal"
              @click.stop="clearFilters()"
            >{{ $t('careers.clear_filters') }}</b-button>
          </div>
        </div>
        <hr />
        <div class="text-primary fs-22 font-weight-bolder my-5">{{ $t('careers.area') }}</div>
        <b-checkbox
          v-for="(area) in areas"
          :key="area.id"
          v-model="area.selected"
          @input="updateFilter"
          :value="area.id"
          class="text-grey-2 fs-14 font-weight-light my-2 text-capitalize">
          {{ area.title | localize }}
        </b-checkbox>
        <hr />
        <div class="text-primary fs-22 font-weight-bolder my-5">{{ $t('careers.country') }}</div>
        <b-checkbox
          v-for="(country, key) in countries"
          :key="`country ${key}`"
          v-model="countries[key].selected"
          @input="updateFilter"
          :value="country | localize"
          class="text-grey-2 fs-14 font-weight-light my-2 text-capitalize">
          {{ country |localize }}</b-checkbox>
        <hr class="d-md-none"/>
      </div>
      <div class="col-md-1"></div>
      <div class="col-12 col-md-6 mt-10 mt-md-4 text-center text-md-left">
        <div v-for="career in careers" :key="career.id" class="mb-15">
          <h2 class="fs-20 text-grey-2 font-weight-normal pb-0 mb-0">{{ career.title | localize }}</h2>
          <p
            class="fs-14 text-primary font-weight-normal"
          >{{ career.city | localize }}, {{ career.country | localize }}</p>
          <p class="fs-12 text-grey-2 font-weight-light" v-html="translate(career.description)"></p>
          <b-button
            class="text-uppercase px-5 text-grey-2 bg-white border border-grey-4 shadow-sm font-weight-normal"
            @click.stop="apply(career.title)"
          >{{ $t('careers.apply') }}</b-button>
        </div>
      </div>
    </div>
    <div class="row px-offset justify-content-end">
      <div class="col-3 d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="getCareers"
        ></b-pagination>
      </div>
    </div>
    <jobModal ref="modal" :job="job" />
    <appFooter :job="job" />
  </div>
</template>


<script>
import apiCareers from '@/api/careers'
import apiBanners from '@/api/banners'
import apiAreas from '@/api/areas'
import localize from '@/filters/localize'
import appContactUs from '@/views/frontoffice/contactUs'
import jobModal from '@/components/frontoffice/careers/jobModal'
import appFooter from '@/components/frontoffice/careers/footer'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  components: {
    appContactUs,
    jobModal,
    appFooter,
    appHighlight,
  },
  filters: { localize },
  data: () => ({
    apiCareers,
    apiBanners,
    apiAreas,
    banner: {},
    careers: [],
    areas: [],
    countries: [],
    selectedAreas: [],
    isLoading: true,
    totalRows: 0,
    perPage: 6,
    currentPage: 1,
    job: null
  }),
  created() {
    this.getCareers()
    this.getBanners()
    this.getAreas()
  },
  methods: {
    clearFilters() {
      this.countries = this.countries.map(item => ({...item, selected: false}) )
      this.areas = this.areas.map(item => ({...item, selected: false}) )
      this.getCareers(1)
    },
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    async getCareers(currentPage = null) {
      if (currentPage) {
        this.currentPage = currentPage
      }
      const response = await this.apiCareers.all(this.filtersCareer)
      if (response && response.data && response.data.list) {
        this.careers = response.data.list
        this.totalRows = response.data.total
        if (this.countries.length === 0) {
          this.countries = response.data.countries
            .map(country => ({...country.country, selected: false}))
            .sort()
        }
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    async getBanners() {
      const response = await this.apiBanners.all(this.filtersBanners)
      if (response && response.data && response.data.list) {
        if (response.data.list.length > 0) {
          this.banner = response.data.list[0]
          this.isLoading = false
        }
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    async getAreas() {
      const response = await this.apiAreas.all()
      if (response && response.data && response.data.list) {
        this.areas = response.data.list.map(item => ({...item, selected: false}) )
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    apply(job) {
      this.job = job
      this.$bvModal.show('modal-job')
    },
    updateFilter() {
      this.getCareers(1)
    },
    background(file) {
      return `background-image: linear-gradient(rgba(15,46,74,0.5),rgba(15,46,74,0.5)), url(${file})`
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  computed: {
    areaList() {
      return this.areas.some(item => item.selected)
        ? this.areas.filter(item => item.selected).map(area => area.id).toString()
        : null
    },
    countryList() {
      return this.countries.some(item => item.selected)
        ? this.countries.filter(item => item.selected).map(country => this.translate(country)).toString()
        : null
    },
    filtersCareer() {
      return {
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage,
        sort: 'created_at,desc',
        areas: this.encode(this.areaList),
        countries: this.countryList ? this.encode(`${this.$i18n.locale},${this.countryList}`) : null,
      }
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersBanners() {
      return  {
        take: 1,
        skip: 0,
        page: this.page.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.border-left-white {
  border-left-color: $white;
}

.height {
  height: 18rem;
}

.position {
    transform: translateY(-20px);
}

.bg-mobile {
  background-position: center;
  background-size: cover;
}
</style>