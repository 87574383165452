import teamsList from '@/views/backoffice/teams/list'
import teamsEdit from '@/views/backoffice/teams/edit'

export default [
  {
    path: 'teams/create',
    name: 'admin-teams-create',
    component: teamsEdit
  },
  {
    path: 'teams',
    name: 'admin-teams-list',
    component: teamsList
  },
  {
    path: 'teams/:id',
    name: 'admin-teams-edit',
    component: teamsEdit
  }
]