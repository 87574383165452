import mutators from '@/models/index'

const fields = [
  {
    name: 'Id',
    field: 'id',
    visible: true,
    sortable: true,
    rules: {
      required: false
    }
  },
  {
    name: 'Nome Autor',
    field: 'name',
    visible: true,
    sortable: true,
    translatable: false,
    rules: {
      required: true
    }
  },
  {
    name: 'Cargo Autor',
    field: 'job',
    visible: true,
    sortable: true,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Empresa Autor',
    field: 'company',
    visible: true,
    sortable: true,
    translatable: false,
    rules: {
      required: true
    }
  },
  {
    name: 'Mensagem',
    field: 'message',
    visible: true,
    sortable: true,
    translatable: true,
    rules: {
      required: true
    }
  },
  {
    name: 'Posição',
    field: 'order',
    visible: false,
    sortable: false,
    translatable: false,
    rules: {
      required: true
    }
  },
  {
    name: 'Data Criação',
    field: 'created_at',
    visible: false,
    sortable: true,
    rules: {
      required: false
    }
  }
]

const rules = mutators.rules(fields)
const empty = mutators.empty(fields)

export default {
  fields,
  rules,
  empty,
}