const state = {
    pages: {}
  }
  
  const getters = {
    pages: (state) => state.pages
  }
  
  const actions = {
      set ({ commit }, data) {
  
          commit('SET', data)
      },
      reset ({ commit }) {
  
          commit('RESET')
      },
  }
  
  const mutations = {
      SET: (state, data) => {
          state.pages = data
      },
      RESET: (state) => {
          state.pages = {}
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }