<template>
  <div class="container-fluid">
    <appHighlight :page="page"/>
    <appHeader :page="page"/>
    <div class="row mb-10 mb-lg-20 justify-content-center">
      <div class="col-12 col-lg-8 text-center px-offset px-md-20">
        <p   
          v-for="(paragraph, key) in $t('accident_medicine.body')"
          :key="`paragraph ${key}`" 
          class="fs-16 font-weight-bolder text-brown-2">
          {{ paragraph.paragraph }}
        </p>
      </div>
    </div>
    <div class="row mb-10 mb-lg-20">
      <div clas="col">
        <div class="row px-offset mx-0 mx-lg-20">
          <div class="col-12 col-lg-6 p-0">
            <img
              src="assets/images/accident-medicine/image.jpg"
              class="w-100 h-100"
              :alt="$t('accident_medicine.values.image_alt')"
              :title="$t('accident_medicine.values.image_title')"
            />
          </div>
          <div class="col-12 col-lg-6 p-0 bg-red-1">
            <div class="row h-100">
              <div class="col pt-20 px-10 pb-15">
                <p
                  class="fs-40 text-white font-weight-bold"
                >{{ $t('accident_medicine.values.title') }}</p>
                <p
                  v-for="(item, index) in $t('accident_medicine.values.list')"
                  :key="index"
                  class="fs-14 text-white"
                >{{ item.item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appPartners v-if="!isLoadingPartners" :partners="partners" />
    <appServices v-if="!isLoadingServices" :services="services" />
    <appTestimonials />
  </div>
</template>

<script>
import apiServices from '@/api/services'
import apiPartners from '@/api/partners'
import appServices from '@/components/frontoffice/common/services'
import appTestimonials from '@/components/frontoffice/common/testimonials'
import appHeader from '@/components/frontoffice/accident-medicine/header'
import appPartners from '@/components/frontoffice/accident-medicine/partners'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  data: () => ({
    apiServices,
    apiPartners,
    isLoadingServices: true,
    isLoadingPartners: true,
    services: []
  }),
  components: {
    appHeader,
    appPartners,
    appServices,
    appTestimonials,
    appHighlight,
  },
  created() {
    this.init()
  },
  methods: {
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    init() {
      this.getServices()
      this.getPartners()
    },
    async getServices() {
      const response = await this.apiServices.all(this.filtersServices)
      if (response && response.data && response.data.list) {
        this.services = response.data.list
        this.isLoadingServices = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    async getPartners() {
      const response = await this.apiPartners.all(this.filtersPartners)
      if (response && response.data && response.data.list) {
        this.partners = response.data.list
        this.isLoadingPartners = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    }
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersServices() {
      return {
        skip: 0,
        take: 6,
        sort: 'order,asc',
        page: this.page.id
      }
    },
    filtersPartners() {
      return {
        skip: 0,
        take: 5,
        sort: 'order,asc',
        page: this.page.id
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.button-text {
  color: rgba($grey-2, 0.6);
}
</style>