import axios from 'axios'

/**
 * Generic API handler. All axios call should go through here
 *
 * @param {String}   method  HTTP method (ie: GET, POST, PUT, DELETE)
 * @param {String}   url     API endpoint url
 * @param {Object}   data    Form data to submit
 *
 */

async function API (method, url, data) {
  const baseURL = location.protocol + '//' + location.hostname + ':' + location.port
  const prefix = '/api'

  let axiosObject = {
    method: method,
    baseURL: baseURL,
    timeout: 5000,
    url: prefix + url
  }

  if (axiosObject.method === 'get') {
    axiosObject = Object.assign(axiosObject, { params: data })
  } else if (axiosObject.method === 'post') {
    axiosObject = Object.assign(axiosObject, { data: data })
  } else if (axiosObject.method === 'put') {
    axiosObject.method = 'post'
    data.append("_method", "PUT")
    axiosObject = Object.assign(axiosObject, { data: data })
  }
  try {
    const response = await axios(axiosObject)
    return response
  } catch (e) {
    return e
  }
}
export default API