<template>
  <div class="row">
    <div class="col-md-6 text-grey-3 text-center text-md-left pl-0 py-md-4 pt-5 border-down-sm-none border-top border-grey-4 border-top-width">
      <div class="fs-12 d-none d-md-inline-block">
        <span>{{year}} SEPRI.</span>
        {{ $t('footer.all_rights_reserved') }}
      </div>
      <div class="fs-14 d-md-none">
        <span>{{year}} SEPRI.</span>
        {{ $t('footer.all_rights_reserved') }}
      </div>
    </div>
    <div class="col-md-6 text-grey-3 text-center text-md-right px-0 py-md-4 pb-5 border-down-sm-none border-top border-grey-4">
      <span class="text-center fs-12 text-md-right d-none d-md-inline-block">
        {{ $t('footer.developed_by') }}
        <a
          class="text-grey-3"
        >YOMP</a>
      </span>
      <span class="text-center fs-14 text-md-right d-md-none">
        {{ $t('footer.developed_by') }}
        <a
          class="text-grey-3"
        >YOMP</a>
      </span>
    </div>
  </div>
</template>


<script>
export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
}
</script>