<template>
  <div class="d-flex justify-content-end">
    <router-link
      class="btn btn-muted p-0"
      :to="{ name: updateRoute, params: { id: data.item.id } }"
    >
      <font-awesome-icon :icon="icon.edit" />
    </router-link>
    <click-confirm
      class="btn btn-muted p-0"
      :messages="confirmationMessages"
    >
      <template slot="confirm-yes-icon">
        <font-awesome-icon :icon="icon.thumbsUp" />
      </template>
      <template slot="confirm-no-icon">
        <font-awesome-icon :icon="icon.thumbsDown" />
      </template>
      <button
        class="btn btn-muted p-0"
        @click.stop="callRemoveItem(data.item)"
      >
        <font-awesome-icon :icon="icon.trash" />
      </button>
    </click-confirm>
  </div>
</template>

<script>
import ClickConfirm from 'click-confirm'
import {
  faEdit,
  faTrashAlt,
  faThumbsUp,
  faThumbsDown,
  faListAlt,
} from '@fortawesome/free-regular-svg-icons'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FontAwesomeIcon,
    ClickConfirm
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    updateRoute: {
      type: String,
      required: true
    }
  },
  data: () => ({
    icon: {
      edit: faEdit,
      trash: faTrashAlt,
      thumbsUp: faThumbsUp,
      thumbsDown: faThumbsDown,
      order: faListAlt,
      undo: faUndo
    },
    confirmationMessages: {
      title: 'Tem a certeza?',
      yes: 'Sim',
      no: 'Não'
    }
  }),
  methods: {
    callRemoveItem (item) {
      this.$emit('removeItem', item)
    }
  }
}
</script>
