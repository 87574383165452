import areasList from '@/views/backoffice/areas/list'
import areasEdit from '@/views/backoffice/areas/edit'

export default [
  {
    path: 'areas/create',
    name: 'admin-areas-create',
    component: areasEdit
  },
  {
    path: 'areas',
    name: 'admin-areas-list',
    component: areasList
  },
  {
    path: 'areas/:id',
    name: 'admin-areas-edit',
    component: areasEdit
  }
]