<template>
  <div class="col-3 col-xl-2 align-self-center p-0">
    <div class="input-group mb-3 shadow-sm">
      <input
        type="text"
        class="form-control border-right-0 shadow-none"
        :placeholder="$t('header.search')"
        name="title"
        v-model="title"
        v-on:keyup.enter="goToNews"
      />
        <div @click.stop="goToNews()" class="input-group-append cursor-pointer">
          <span class="input-group-text bg-white border-left-white">
            <i class="anticon icon-search1" />
          </span>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    title: '',
  }),
  methods: {
    translate(value) {
      return this.$options.filters.localize(value)
    },
    goToNews() {
      this.$router.push(this.getRoute)
      this.title = ''
    },
  },
  computed: {
    getRoute() {
      return {
        name: this.page.route,
        params: { lang: this.$i18n.locale, path: this.translate(this.page.url) },
        query: { title: this.title }
      }
    },
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === 'media')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.border-left-white {
  border-left-color: $white;
}
</style>