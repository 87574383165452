<template>
  <div>
    <tree
      :value="items"
      @drop="updatePosition"
    >
      <div
        slot-scope="{ node, path }"
        class="text-white w-100 d-block p-2 small"
        :class="getClassName(path)"
        :eachDroppable="false"
      >
        <slot
          name="identifier"
          :node="node"
        />
        <slot
          name="actions"
          :node="node"
        />
      </div>
    </tree>
  </div>
</template>

<script>
import { Tree, Draggable } from 'he-tree-vue'
import 'he-tree-vue/dist/he-tree-vue.css'

import { mapGetters } from 'vuex'

export default {
  components: {
    Tree: Tree.mixPlugins([Draggable])
  },
  props: {
    api: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      items: 'form/related',
    }),
  },
  methods: {
    getClassName(path) {
      return `level-${path.length}`
    },
    areArraysEqual (...array) {
      return array.every((item, index, array) => JSON.stringify(item) === JSON.stringify(array[0]))
    },
    async updatePosition (event) {
      if (this.areArraysEqual(event.startPath, event.targetPath)) {
        return
      }

      try {
        this.items.forEach((item, key) => this.updateItem({ ...item, position: key }))
      } catch (e) {
        console.error(e)
      }
    },
    async updateItem (item) {
      try {
        var data = new FormData()
        Object.entries(item).forEach(([key, value]) => data.append(key, typeof value === 'object' ? JSON.stringify(value) : value));
        const response = await this.api.update(data)
      } catch (e) {
        console.error(e)
      }

    }
  }
}
</script>
<style lang="scss">

@import '@vars';
.he-tree .tree-node {
  padding: 0;
}

@for $i from 0 through 20 {
  .level-#{$i} {
    background-color: lighten($secondary, $i * 5%);
  }
}
</style>
