 <template>
  <div class="container-fluid">
    <appHighlight :page="page"/>
    <appHeader :page="page" />
    <appSolutions :solutions="solutions" />
    <appContactUs />
    <appTestimonials />
    <appFeatured v-if="!isLoadingNews" :news="news" class="d-none d-lg-block"/>
    <appFeaturedMobile v-if="!isLoadingNews" :news="news" class="d-lg-none"/>
  </div>
</template>


<script>
import apiNews from '@/api/news'
import appContactUs from '@/views/frontoffice/contactUs'
import appHeader from '@/components/frontoffice/solutions/header'
import appSolutions from '@/components/frontoffice/solutions/solutions'
import appTestimonials from '@/components/frontoffice/common/testimonials'
import appFeatured from '@/components/frontoffice/common/featured'
import appFeaturedMobile from '@/components/frontoffice/common/featuredMobile'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  data: () => ({
    apiNews,
    isLoadingNews: true,
    news: []
  }),
  components: {
    appContactUs,
    appHeader,
    appSolutions,
    appTestimonials,
    appFeatured,
    appFeaturedMobile,
    appHighlight,
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const response = await this.apiNews.all(this.filtersNews)
      if (response && response.data && response.data.list) {
        this.news = response.data.list
        this.isLoadingNews = false && this.news.length > 0
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    solutions() {
      return this.pages.filter(page => page.is_solution)
    },
    filtersNews() {
      return {
        sort: 'published_at,desc',
        skip: '0',
        take: '3',
        isFeatured: 'true',
      }
    },
  }
}
</script>