<template>
  <b-modal id="modal-highlight" ref="modal-highlight" hide-footer hide-header body-class="p-1" centered>
    <div class="d-block text-center position-relative">
      <button
        class="btn btn-muted position-absolute z-2 btn-position m-1 bg-white line-height-normal"
        @click.stop="closeModal"
      >
        x
      </button>
      <img
        v-if="hasHighlight"
        :src="highlight.file"
        class="img-size"
        :alt="highlight.title | localize"
        :title="highlight.title | localize"
      />
    </div>
  </b-modal>
</template>

<script>
import apiHighlights from "@/api/highlights"

export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    apiHighlights,
    hasHighlight: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.apiHighlights.all(this.filtersHighlights)
      if (response && response.data && response.data.list) {
          if (response.data.list.length > 0) {
              this.highlight = response.data.list[0]
              this.hasHighlight = true;
              setTimeout(this.$refs["modal-highlight"].show(), 2000)
          }
      } else {
        this.$router.push({ name: "notFound" })
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-highlight")
    },
  },
  computed: {
    filtersHighlights() {
      return {
        sort: "created_at,desc",
        skip: "0",
        take: "1",
        today: "yes",
        page: this.page.id,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@bootstrap-functions";
@import "~@bootstrap-variables";
@import "~@bootstrap-mixins";
@import "~@vars";

.img-size {
  height: 30rem;
  width: 100%;
  @include media-breakpoint-down(sm) {
    height: 20rem;
  }
}

.btn-position {
  right: 0px;
  top: 0px;
}

</style>