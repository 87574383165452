<template>
  <div>
    <template v-if="loading">
      <app-loading/>
    </template>
    <div class="row" v-else>
      <div class="backend w-100" v-if="$auth.user()">
        <div class="dashboard mx-3">
        <!--
          <div class="stats-square shadow fs-14 px-1 py-3">
            <span class="text-primary font-weight-bold fs-22">{{ this.projects.length }}</span>
            projetos
          </div>
          <div class="stats-square shadow fs-14 px-1 py-3">
            <span class="text-primary font-weight-bold fs-22">{{ this.subscribers.length }}</span>
            subscritores
          </div>
          <div class="stats-square shadow fs-14 px-1 py-3">
            <span class="text-primary font-weight-bold fs-22">{{ this.partners.length }}</span>
            parceiros e clientes
          </div>
          <div class="stats-square shadow fs-14 px-1 py-3">
            <span class="text-primary font-weight-bold fs-22">{{ this.areas.length }}</span>
            áreas de negócios
          </div>
          <div class="stats-chart text-center shadow d-flex flex-column p-3">
            <h2 class="fs-18">Ofertas de Emprego por Especialidade</h2>
            <doughnutChart reference="projects" :data="specialtyPercentages" />
          </div>
          <div class="stats-chart text-center shadow d-flex flex-column p-3">
            <h2 class="fs-18">Ofertas de Emprego por País</h2>
            <doughnutChart reference="jobs" :data="countryPercentages" />
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faTrashAlt, faEye, faImage, faImages,faCaretSquareRight, faComments, faFile} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import doughnutChart from '@/components/backoffice/doughnutChart'
import appLoading from '@/components/backoffice/common/loading'

// import areasApi from '@/api/areas'
// import projectsApi from '@/api/projects'
// import subscribersApi from '@/api/subscribers'
// import partnersApi from '@/api/associates'
// import specialtiesApi from '@/api/specialties'
// import jobsApi from '@/api/jobs'


export default {
  data: () => ({
    loading: true,
    // areasApi,
    // projectsApi,
    // subscribersApi,
    // partnersApi,
    // specialtiesApi,
    // jobsApi,
    // jobs: null,
    // specialties: null,
    // projects: null,
    // subscribers: null,
    // areas: null,
    // partners: null,
    // countriesList: null,
    // posts: [],
    edit: faEdit,
    home: faEye,
    image: faImage,
    images: faImages,
    video: faCaretSquareRight,
    comments: faComments,
    file: faFile
  }),
  components: {
    FontAwesomeIcon,
    // doughnutChart,
    appLoading
  },
  created () {
    this.init()
  },
  computed: {
    // specialtyPercentages () {
    //   return this.specialties.map(specialty => ({ 'specialty': specialty.title.pt, 'percentage': this.jobs.filter(job => job.specialty_id === specialty.id).length * 100 / this.jobs.length }))
    // },
    // countryPercentages () {
    //   return this.countriesList.map(country => ({ 'specialty': country.country[0].pt, 'percentage': this.jobs.filter(job => job.country.pt === country.country[0].pt).length * 100 / this.jobs.length }))
    // }
  },
  methods: {
    async init() {
    //   await Promise.all([this.getAreas(), this.getSubscribers(), this.getProjects(), this.getPartners(), this.getSpecialties(), this.getJobs()])
      this.loading = false
    },
    // async getSpecialties() {
    //   const response = await this.specialtiesApi.all()
    //   if (response && response.data && response.data.list) {
    //     this.specialties = response.data.list
    //   }
    // },
    // async getAreas () {
    //   const response = await this.areasApi.all()
    //   if (response && response.data && response.data.list) {
    //     this.areas = response.data.list
    //   }
    // },
    // async getSubscribers () {
    //   const response = await this.subscribersApi.all()
    //   if (response && response.data && response.data.list) {
    //     this.subscribers = response.data.list
    //   }
    // },
    // async getProjects () {
    //   const response = await this.projectsApi.all()
    //   if (response && response.data && response.data.list) {
    //     this.projects = response.data.list
    //   }
    // },
    // async getPartners () {
    //   const response = await this.partnersApi.all({ sort:'position,asc' })
    //   if (response && response.data && response.data.list) {
    //     this.partners = response.data.list
    //   }
    // },
    // async getJobs() {
    //   const response = await this.jobsApi.all()
    //   if (response && response.data && response.data.list) {
    //     this.jobs = response.data.list
    //     this.countriesList = this.sortAlphabetically(response.data.countries, 'country')
    //   }
    // },
    // sortAlphabetically(obj, property, local = true) {
    //   let arr = Object.keys(obj).map((key) => obj[key])
    //   return local
    //     ? arr.sort((a, b) => a[property][0][this.$i18n.locale].localeCompare(b[property][0][this.$i18n.locale]))
    //     : arr.sort((a, b) => a[property][0].localeCompare(b[property][0]))
    // },
  }
}
</script>


<style lang="scss" scoped>
  @import "~@vars";
  @import "~@bootstrap-functions";
  @import "~@bootstrap-variables";
  @import "~@bootstrap-mixins";

  .dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 4fr;

    grid-gap: 2rem;

    min-height: 80vh;

    @include media-breakpoint-down(md) {
      grid-template-rows: 1fr 6fr 6fr;
      grid-gap: 1rem;
    }

    @include media-breakpoint-down(sm) {
      grid-template-rows: 1fr 1fr 6fr 6fr;
      grid-gap: 1rem;
    }

    @include media-breakpoint-down(xs) {
      grid-template-rows: 1fr 1fr 5fr 5fr;
      grid-gap: 1rem;
    }
  }

  .stats-square {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $white;
    height: 100%;
    width: 100%;

    @include media-breakpoint-down(sm) {
      grid-column-start: span 2;
    }
  }

  .stats-chart {
    background: $white;
    width: 100%;
    height: 100%;
    grid-column-start: span 2;

    @include media-breakpoint-down(md) {
      grid-column-start: span 4;
    }
  }
</style>
