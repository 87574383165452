<template>
  <div class="container-fluid">
    <appHighlight :page="page"/>
    <appHeader :page="page" />
    <div class="d-none d-lg-block">
      <div class="row justify-content-start pb-10">
        <div class="col-5">
          <div class="row h-100 pt-40 justify-content-between position-relative">
            <div class="col-8 h-75 p-0">
              <img
                src="assets/images/health-services-24/vertical.jpg"
                class="w-100 h-100"
                :alt="$t('health_services_24.big_image_alt')"
                :title="$t('health_services_24.big_image_title')"
              />
            </div>
            <div class="col-8 p-0 position-absolute position border-white border-width">
              <img
                src="assets/images/health-services-24/horizontal.jpg"
                class="w-100 h-100"
                :alt="$t('health_services_24.small_image_alt')"
                :title="$t('health_services_24.small_image_title')"
              />
            </div>
          </div>
        </div>
        <div class="col-5">
          <img
            src="assets/images/logos/health_services_24.svg"
            class="img-logo my-5"
            :alt="$t('health_services_24.logo_image_alt')"
            :title="$t('health_services_24.logo_image_title')"
          />
          <div class="pb-10">
            <p
              v-for="(paragraph, key) in $t('health_services_24.summary')"
              :key="`paragraph ${key}`"
              class="fs-16 font-weight-bolder text-grey-7 mt-5 mb-0"
            >{{ paragraph.paragraph }}</p>
          </div>

          <hr />
          <div v-for="(item, key) in $t('health_services_24.list')" :key="`item ${key}`">
            <div class="py-10">
              <h3 class="fs-24 text-blue-3 font-weight-bold mb-4">{{ item.title }}</h3>
              <p
                v-for="(paragraph, key) in item.summary"
                :key="`paragraph ${key}`"
                class="fs-14 text-grey-7 my-0"
              >{{ paragraph.paragraph }}</p>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="d-lg-none">
      <div class="row px-offset">
        <div class="col-12 p-0">
          <p
            v-for="(paragraph, key) in $t('health_services_24.summary')"
            :key="`paragraph ${key}`"
            class="fs-16 font-weight-bolder text-grey-7 text-center mb-10"
          >{{ paragraph.paragraph }}</p>
        </div>
        <div class="col-12 p-0">
          <img
            src="assets/images/health-services-24/vertical.jpg"
            class="img-size"
            :alt="$t('health_services_24.big_image_alt')"
            :title="$t('health_services_24.big_image_title')"
          />
        </div>
        <div class="col-12 p-0">
          <div v-for="(item, key) in $t('health_services_24.list')" :key="`item ${key}`">
            <div class="py-10">
              <h3 class="fs-24 text-blue-3 font-weight-bold mb-4 text-center">{{ item.title }}</h3>
              <p
                v-for="(paragraph, key) in item.summary"
                :key="`paragraph ${key}`"
                class="fs-14 text-grey-7 my-0 text-center"
              >{{ paragraph.paragraph }}</p>
            </div>
            <hr />
          </div>
        </div>
        <div class="col-12 px-0 py-10">
          <img
            src="assets/images/health-services-24/horizontal.jpg"
            class="img-size"
            :alt="$t('health_services_24.small_image_alt')"
            :title="$t('health_services_24.small_image_title')"
          />
        </div>
      </div>
    </div>
    <appServices v-if="!isLoadingServices" :services="services" />
    <appTestimonials />
  </div>
</template>

<script>
import apiServices from '@/api/services'
import appServices from '@/components/frontoffice/common/services'
import appTestimonials from '@/components/frontoffice/common/testimonials'
import appHeader from '@/components/frontoffice/health-services-24/header'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  data: () => ({
    apiServices,
    isLoadingServices: true,
    services: []
  }),
  components: {
    appHeader,
    appServices,
    appTestimonials,
    appHighlight,
  },
  created() {
    this.init()
  },
  methods: {
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    init() {
      this.getServices()
    },
    async getServices() {
      const response = await this.apiServices.all(this.filtersServices)
      if (response && response.data && response.data.list) {
        this.services = response.data.list
        this.isLoadingServices = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersServices() {
      return {
        skip: 0,
        take: 8,
        sort: 'order,asc',
        page: this.page.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.position {
  top: 65%;
  right: 1%;
}

.border-width {
  border-style: solid;
  border-width: 1.25rem;
}

.img-logo {
  height: 10rem;
}

.img-size {
  width: 100%;
  height: 26rem;

  @include media-breakpoint-down(sm) {
    height: 18rem;
  }
}
</style>