 <template>
  <div class="container-fluid" v-if="!isLoading">
    <appHighlight :page="page"/>
     <div class="row px-offset justify-content-center">
         <div class="col-12 col-md-10 col-lg-8 p-0">
            <h1 class="fs-24 text-primary font-weight-bold m-0">
                {{ news.title | localize }}
            </h1>
            <div class="fs-14 text-grey-1 pt-5">
                {{ news.published_at }}
            </div>
            <img
              :src="news.file"
              class="w-100 img-size py-10"
              :alt="news.title | localize"
              :title="news.title | localize"
            />
            <div class="fs-16 text-grey-2 pb-10 news" v-html="translate(news.body)">
            </div>
         </div>
     </div>
  </div>
</template>

<script>
import apiNews from '@/api/news'
import appHighlight from '@/components/frontoffice/common/highlight'
import store from '@/store'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  components: {
    appHighlight,
  },
  data: () => ({
    apiNews,
    isLoading: true,
    news: null
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const response = await this.apiNews.all(this.filtersNews)
      if (response && response.data && response.data.list) {
        if (response.data.list.length > 0) {
          this.news = response.data.list[0]
          store.dispatch('news/set', this.news)
          this.updateMetaInformation()
          this.isLoading = false
        }
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
    updateMetaInformation() {
      this.metaTitle = this.translate(this.news.meta_title) + ' | Sepri'
      this.metaDescription = this.translate(this.news.meta_description)
      this.metaKeywords = this.translate(this.news.meta_keywords)
    }
  },
  beforeDestroy (){
    store.dispatch('news/reset')
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersNews() {
      return  {
        take: 1,
        skip: 0,
        slug: this.encode(this.$route.params.slug)
      }
    },
  }
}
</script>

<style lang="scss">

.news p img {
  max-width: 100%;
}

</style>

<style lang="scss" scoped>
@import "~@bootstrap-functions";
@import "~@bootstrap-variables";
@import "~@bootstrap-mixins";
@import "~@vars";

.img-size {
  max-width: 100%;
  max-height: 28.125rem;
}

</style>