import admin from '@/views/backoffice/index'

import guestRoutes from '@/router/backoffice/guest'
import adminRoutes from '@/router/backoffice/admin/index'

export default [
	...guestRoutes,
  {
    path: '/admin',
    name: 'admin',
    meta: {
      auth: true
    },
    component: admin,
    children: [
      ...adminRoutes
    ]
  }
]

