<template>
  <div :class="{ active: sideMenu }">
    <div class="sideMenu-wrapper" v-if="sideMenu" @click.stop="closeMenu"></div>
    <div class="sideMenu h-100 overflow-scroll bg-grey-8 shadow-lg" ref="sideMenu" :class="{ active: sideMenu }">
      <div class="h-100 w-100 d-flex flex-column ">
        <div class="w-100 d-flex align-items-center border-bottom py-4 px-4">
          <button
            class="btn m-0 p-0 anticon icon-close text-secondary fs-22 mr-4"
            @click.stop="closeMenu"
          />
        </div>
        <div class="mx-5">
          <template v-for="page in headerPages">
            <div :key="page.id">
              <ul v-if="notSolution(page)" class="my-0 p-5 border-bottom">
                <router-link
                  :to="getRoute(page)"
                  @click.native.stop="closeMenu"
                  class="text-uppercase fs-14 text-secondary pages"
                >{{ page.title | localize }}</router-link>
                <i
                  v-if="isSolution(page)"
                  class="px-10 anticon fs-14 font-weight-bolder"
                  :class="arrowDirection"
                  v-b-toggle.collapse-solutions
                />
              </ul>
              <b-collapse
                v-if="isSolution(page)"
                is-nav
                id="collapse-solutions"
                @show="show"
                @hide="hide"
                class="bg-grey-9"
              >
                <template v-for="solution in solutions">
                  <ul :key="solution.id" class="my-0 p-5 border-bottom">
                    <router-link
                      :to="getRoute(solution)"
                      @click.native.stop="closeMenu"
                      class="fs-14 text-secondary pages"
                    >{{ solution.title | localize }}</router-link>
                  </ul>
                </template>
              </b-collapse>
            </div>
          </template>
          <locales />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import handleOutsideClick from '@/directives/handleOutsideClick'
import locales from '@/components/frontoffice/header/locales'

export default {
  components: {
    locales,
  },
   props: {
    pages: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    arrowDirection: 'icon-right'
  }),
  directives: {
    handleOutsideClick
  },
  computed: {
    ...mapGetters({
      sideMenu: 'settings/sideMenu',
    }),
    headerPages() {
      return this.pages.filter(page => page.is_header).sort(page => page.order).sort((a, b) => a.order - b.order)
    },
    solutions() {
      return this.pages.filter(page => page.is_solution)
    },
  },
  methods: {
    ...mapActions({
      closeMenu: 'settings/closeMenu'
    }),
    notSolution(page) {
      return page.is_solution === false
    },
    isSolution(page) {
      return page.route === 'solutions'
    }, 
    translate(value) {
      return this.$options.filters.localize(value)
    },
    getRoute(page) {
      return {
        name: page.route,
        params: { lang: this.$i18n.locale, path: this.translate(page.url) }
      }
    },
    show() {
      this.arrowDirection = 'icon-down'
    },
    hide() {
      this.arrowDirection = 'icon-right'
    },
  }
}
</script>
<style lang="scss" scoped>

.icon-down {
  transition: all 0.3s
}

.collapsed > .icon-down {
  transform: rotate(-90deg)
}
</style>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.sideMenu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 85%;
  z-index: 300;
  display: none;

  transition: all 0.7s ease-in-out;
  transform: translateX(-100%);

  @include media-breakpoint-down(sm) {
    display: initial;
  }

  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 300;
  }

  &.active {
    transform: translateX(0);
  }
}

a.pages{
  text-decoration: none;
  font-weight: $font-weight-bold;

  &:hover,
  &.active {
    font-weight: $font-weight-bolder;
  }
}

.overflow-scroll {
  overflow: scroll;
  overflow-x: hidden;
}
</style>
