<template>
  <div
    id="solutions"
    class="row height bg-cover d-none d-md-flex"
  >
    <div class="col p-0">
      <b-carousel
        ref="carouselHomeAbout"
        class="carousel-homeAbout h-100"
        :interval="3000"
        indicators
      >
        <b-carousel-slide
        class="h-100"
          v-for="item in $t('home.about.list')"
          :key="item.id"
          :img-src="background(item.id)"
        >
           <div class="text-left w-35">
            <span class="fs-40 font-weight-bold text-primary text-decoration-none"
            >{{ $t(`home.about.list[${item.id}].title`) }}</span>
            <p class="my-3 fs-18 text-secondary">{{ $t(`home.about.list[${item.id}].summary`) }}</p>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    background(id) {
      return `/assets/images/home/about/${id}-background.jpg`   
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.item {
  color: $grey-1;
  font-weight: $font-weight-bold;
  &.active {
    color: $primary;
    font-weight: $font-weight-bolder;
  }
}

.height { 
  height: 100vh;
  @include media-breakpoint-only(md) {
    height: 31.25rem;
  }
}

</style>

<style lang="scss">
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';
@import '~@vars';

.carousel-homeAbout .carousel-inner {
  height: 100%;
}

.carousel-homeAbout .carousel-inner .carousel-item > img{
  height: 100vh;
  @include media-breakpoint-only(md) {
    height: 31.25rem;
  }
}

.carousel-homeAbout .carousel-inner .carousel-item .carousel-caption {
  height: 100%;
  width: 100%;
  top: 15%;
  left: 5%;
}

.carousel-homeAbout .carousel-indicators {
  bottom: 5%;
}

.carousel-homeAbout .carousel-indicators li {
  background-color: $grey-1;
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;
}

.carousel-homeAbout .carousel-indicators .active {
  background-color: $primary;
}
</style>