<template>
  <div class="col-12 col-md-3 col-xl-2 align-self-start">
    <div class="row align-items-center justify-content-md-end p-5 p-md-0">
      <i class="fs-14 locale anticon icon-user" />
      <b-dropdown :text="$t('header.platforms')" variant="platforms-menu">
        <b-dropdown-item
          class="fs-14"
          v-for="(item, key) in $t('header.list')"
          :key="`item ${key}`"
          :href="item.link"
          >{{ item.title }}</b-dropdown-item
        >
      </b-dropdown>
      <div class="d-md-none vertical-line mx-5"></div>
      <b-dropdown :text="$i18n.locale" variant="locales-menu">
        <b-dropdown-item
          class="fs-14 text-uppercase"
          v-for="locale in locales"
          :key="locale"
          @click.stop="setLocale(locale)"
          >{{ locale }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { locales } from '@/constants/settings'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    locales: locales,
    page: {},
  }),
  methods: {
    setLocale(lang) {
      this.page = this.pages.find((page) => page.route === this.$route.name)
      this.$router.push({
        name: this.page.route,
        params: this.params(lang),
      })
    },
    params(lang) {
      if (this.isOpenNews)
        return {
          lang: lang,
          path: this.page.url[lang],
          slug: this.news.slug[lang],
        }
      else if (this.isOpenProject)
        return {
          lang: lang,
          path: this.page.url[lang],
          slug: this.project.slug[lang],
        }
      else return { lang: lang, path: this.page.url[lang] }
    },
  },
  computed: {
    ...mapGetters({
      news: 'news/news',
      pages: 'pages/pages',
      project: 'project/project'
    }),
    isOpenNews() {
      return this.news && Object.keys(this.news).length > 0
    },
    isOpenProject() {
      return this.project && Object.keys(this.project).length > 0
    },
  },
};
</script>

<style lang="scss">
@import '~@vars';
@import '~@bootstrap-functions';
@import '~@bootstrap-variables';
@import '~@bootstrap-mixins';

.btn-locales-menu {
  text-transform: uppercase;
  font-size: $fs-14;
  color: rgba($black, 0.55);
  padding: 0rem;
  @include media-breakpoint-up(md) {
    padding-left: 1.25rem;
  }
}

.btn-platforms-menu {
  text-transform: capitalize;
  font-size: $fs-14;
  color: rgba($black, 0.55);
  padding: 0rem;
  @include media-breakpoint-up(md) {
    padding-left: 0.5rem;
  }
}

.dropdown-menu {
  min-width: 100%;
}
</style>

<style lang="scss" scoped>
@import '~@vars';

.locale {
  color: rgba($black, 0.55);
}

a {
  color: rgba($black, 0.55);
  &:hover {
    color: rgba($black, 0.55);
  }
}

.vertical-line {
  border-left: 1px solid $grey-1;
  height: 2.5rem;
}
</style>