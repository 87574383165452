<template>
  <div class="container-fluid">
    <appHighlight :page="page"/>
    <appHeader :page="page"/>
    <div class="row justify-content-center my-10">
      <div class="col text-center">
        <img
          src="assets/images/logos/work_safety_health.svg"
          class="img-logo" 
          :alt="$t('work_safety_health.logo_image_alt')"
          :title="$t('work_safety_health.logo_image_title')"
        />
      </div>
    </div>
    <appServices v-if="!isLoadingServices" :services="services" />
    <appPartners v-if="!isLoadingPartners" :partners="partners" />
    <appFeatured v-if="!isLoadingNews" :news="news" class="d-none d-lg-block"/>
    <appFeaturedMobile v-if="!isLoadingNews" :news="news" class="d-lg-none"/>
    <appTestimonials />
  </div>
</template>


<script>
import apiServices from '@/api/services'
import apiPartners from '@/api/partners'
import apiNews from '@/api/news'
import appServices from '@/components/frontoffice/common/services'
import appPartners from '@/components/frontoffice/work-safety-health/partners'
import appFeatured from '@/components/frontoffice/work-safety-health/featured'
import appFeaturedMobile from '@/components/frontoffice/common/featuredMobile'
import appTestimonials from '@/components/frontoffice/common/testimonials'
import appHeader from '@/components/frontoffice/work-safety-health/header'
import appHighlight from '@/components/frontoffice/common/highlight'
import { mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

export default {
  mixins: [
    metaMixin
  ],
  data: () => ({
    apiServices,
    apiPartners,
    apiNews,
    isLoadingServices: true,
    isLoadingPartners: true,
    isLoadingNews: true,
    services: [],
    partners: [],
    news: []
  }),
  components: {
    appHeader,
    appServices,
    appPartners,
    appFeatured,
    appTestimonials,
    appFeaturedMobile,
    appHighlight,
  },
  created() {
    this.init()
  },
  methods: {
    encode(string) {
      return btoa(unescape(encodeURIComponent(string)))
    },
    init() {
      this.getServices()
      this.getPartners()
      this.getNews()
    },
    async getServices() {
      const response = await this.apiServices.all(this.filtersServices)
      if (response && response.data && response.data.list) {
        this.services = response.data.list
        this.isLoadingServices = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    async getPartners() {
      const response = await this.apiPartners.all(this.filtersPartners)
      if (response && response.data && response.data.list) {
        this.partners = response.data.list
        this.isLoadingPartners = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
    async getNews() {
      const response = await this.apiNews.all(this.filtersNews)
      if (response && response.data && response.data.list) {
        this.news = response.data.list
        this.isLoadingNews = false
      } else {
        this.$router.push({ name: 'notFound' })
      }
    },
  },
  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    page() {
      return this.pages.find(page => page.route === this.$route.name)
    },
    filtersServices() {
      return {
        skip: 0,
        take: 8,
        sort: 'order,asc',
        page: this.page.id
      }
    },
    filtersPartners() {
      return {
        skip: 0,
        take: 4,
        sort: 'order,asc',
        page: this.page.id
      }
    },
    filtersNews() {
      return {
        sort: 'published_at,desc',
        is_featured: 'true',
        page: this.page.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@vars';

.img-logo {
  height: 10rem;
}
</style>