<template>
  <div>
    <b-form v-if="!loading">
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Geral
            </b-card-header>
            <b-card-body>
              <b-tabs
                no-nav-style
                nav-class="border-right border-light h-100"
                nav-wrapper-class="p-0"
                class="mx-0"
                active-nav-item-class="font-weight-bolder border-right text-secondary border-secondary"
                content-class="py-5 w-100 bg-white"
                vertical
              >
                <b-tab
                    v-for="locale in locales"
                    :key="locale"
                    :title="$t(`locales.${locale}`)"
                >
                  <banners-form :locale="locale" />
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-card>
            <b-card-header>
              Informação Adicional
            </b-card-header>
            <b-card-body>
              <b-form-group label="Páginas">
                <b-select
                  v-model="item.page_id"
                  :options="pages"
                  required
                />
              </b-form-group>
              <b-form-group label="Imagem">
                <div
                  v-if="hasMedia"
                  class="w-100 mb-3"
                >
                  <img
                    class="img-thumbnail"
                    :src="item.file"
                  >
                </div>
                <image-uploader
                  :maxWidth="1920"
                  :quality="1"
                  :autoRotate=true
                  outputFormat="blob"
                  :preview=true
                  capture="environment"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  @input="setImage"
                  class="mb-3"
                />
              </b-form-group>
              <b-form-group label="Link">
                <b-input
                  v-model="item.url"
                  name="url"
                  type="text"
                  placeholder="https://google.pt"
                />
              </b-form-group>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          class="justify-content-end d-flex"
        >
          <b-button
            @click.prevent.stop="submit"
            variant="primary"
            class="mt-3"
          >
            Submeter
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <loading />
    </div>
  </div>
</template>

<script>
import loading from '@/components/backoffice/common/loading'
import bannersForm from '@/components/backoffice/modules/banners/form'
import model from '@/models/banners'
import { actions } from '@/mixins/edit'
import api from '@/api/banners'
import pagesApi from '@/api/pages'

export default {
  mixins: [actions],
  components: {
    loading,
    bannersForm,
  },
  data: () => ({
    api,
    pagesApi,
    model: JSON.parse(JSON.stringify(model.empty)),
    rules: model.rules,
    pages: [],
    validPages: [
      1, // home
      2, // sepri
      3, // soluções
      6, // carreiras
      8, // segurança e saúde no trabalho
      9, // serviços de saúde 24
      10, // medicina sinistrados
      11, // laboratório de estudos ambientais
      12 // healthcare & consulting
    ]
  }),
  computed: {
    hasMedia () {
      return this.item && this.item.file && this.item.file != '' && !(this.item.file instanceof Blob)
    }
  },
  created () {
    this.init ()
  },
  methods: {
    async init () {
      if (this.$route.params.id) {
        await Promise.all([this.getItem(), this.getPages()])
      } else {
        this.initForm(this.model)
        await this.getPages()
      }
      this.loading = false
    },
    setImage (file) {
      this.item.file = file
    },
    async getPages () {
      try {
        const response = await this.pagesApi.all()
        if (response.data) {
          this.pages = response.data.list.filter(item => this.validPages.includes(item.id)).map(item => ({ text: item.title.pt, value: item.id }))
        } else {
          console.error('Response data not found', response)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    }
  }
}
</script>