<template>
  <div class="w-100">
    <b-form-group label="Título">
      <b-input
        v-model="item.title[locale]"
        name="title"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
    <b-form-group label="Resumo">
      <b-input
        v-model="item.summary[locale]"
        name="summary"
        type="text"
        required
        placeholder="Lorem ipsum set dolor"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      item: 'form/fields'
    })
  }
}
</script>