<template>
    <date-picker
      v-model="item[index]"
      valueType="format"
    />
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapGetters } from 'vuex'

export default {
  props: {
    index: {
      type: String,
      required: true
    }
  },
    computed: {
    ...mapGetters({
      item: 'form/fields'
    })
  },
  components: {
    DatePicker
  }
}
</script>
