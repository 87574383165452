import ClickConfirm from 'click-confirm'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faTrashAlt, faImages } from '@fortawesome/free-regular-svg-icons'
import { faUndo, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { locales } from '@/constants/settings'
import { mapGetters, mapActions } from 'vuex'

export const actions = {
  data: () => ({
    locales,
    loading: true,
    formData: null,
    edit: faEdit,
    trash: faTrashAlt,
    gallery: faImages,
    undo: faUndo,
    thumbsUp: faThumbsUp,
    thumbsDown: faThumbsDown,
    confirmationMessages: {
      title: 'Tem a certeza?',
      yes: 'Sim',
      no: 'Não'
    }
  }),
  components: {
    ClickConfirm,
    FontAwesomeIcon
  },
  computed: {
    isCreate () {
      return this.$route.params.id === undefined || this.$route.params.id === null
    },
    ...mapGetters ({
      item: 'form/fields',
      relationshipItem: 'form/relationshipFields'
    }),
  },
  methods: {
    ...mapActions({
      initForm: 'form/init',
      resetForm: 'form/reset'
    }),
    async getItem () {
      try {
        const response = await this.api.show(this.$route.params.id);
        this.loading = false;

        if (response.data) {
          this.model = response.data
          this.initForm(this.model)
        } else {
          this.$toasted.error(this.$t('veeErrors.FETCH_FAILED'));
        }
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    async submit () {
      this.formData = await this.formatAsFormData(this.item)

      const response = await this.api[this.isCreate ? 'create' : 'update'](this.formData)

      if (response && response.data) {
        const message = this.isCreate ? 'backoffice.edit.created' : 'backoffice.edit.updated'
        this.$toasted.success(this.$t(message, { title: response.data.title ? this.translate(response.data.title) : response.data.name }))

        if (this.isCreate) {
          this.$router.push({ name: this.$route.name.replace('create', 'edit'), params: { id: response.data.id } })
        }

      } else {
        if (response.response && response.response.data && response.response.data.errors) {
          this.$toasted.error(Object.values(response.response.data.errors).join(' '))
        } else {
          this.$toasted.error(this.$t('backoffice.error.unknown'));
        }
      }
    },
    setImage (file) {
      this.model.media = file
    },
    async formatAsFormData (item) {
      var data = new FormData()
      Object.entries(item).forEach(([key, value]) => data.append(key, typeof value === 'object' && key !== 'media' && key !== 'file' ? JSON.stringify(value) : value));
      return data
    },
    translate(value) {
      return this.$options.filters.localize(value)
    },
  },
  beforeDestroy () {
    this.resetForm()
  }
}
